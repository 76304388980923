.nav-tabs{
  .nav-link{
    //color: $accent;
    color: $inactive;
    &.active{
      //color: $inactive;
      color: $accent;
    }
  }
}

.toolbar-dictionaries{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .actions-dictionary-toolbar{
    .add-button-dictionary{
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }
  }
  +div{
    table{
      thead{
        tr{
          th{
            padding-top: 0;
          }
        }
      }
    }

  }
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}
.delete-relations-form {
  .warning-form-item {
    margin: 0;
    color: orange;
    font-size: $subtitle_size;
  }
  .error-select-bootstrap-style {
    display: block;
  }
}
