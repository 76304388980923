.list-privilege{
  .form-group{
    margin-bottom: 0 !important;

    .form-check{
      display: flex;
      align-items: center;

      .form-check-input{
        margin-top: 0;
      }

      .form-check-label{
        text-align: left;

      }
    }
  }

  .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color: #228B22 !important;
    background-color: #228B22 !important;
  }

  .custom-control-input:focus~.custom-control-label::before{
    box-shadow: 0 0 0 0.1rem rgba(34,139,34, 0.25);
  }

  .custom-control-label{
    text-align: left;
    padding-top: 3px;
    &:before, &:after{
      width: 14px !important;
      height: 14px !important;
      //border-color: green !important;
      //background-color: green !important;
    }
  }
}

.wrapper-partly-privileges{
  .centered-content{
    text-align: center;
  }
  table{
    table-layout: fixed;
  }
}
