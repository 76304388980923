@import "variable";

.btn.standard{
  font-family: 'Roboto-light';
  font-size: $normal_text_size;
  color: $primary;
  border-radius: 5px;

  svg{
    width: 16px;
    height: 16px;
    margin-right: 5px;
    path {
      fill: $inactive;
    }
  }

  &:hover{
    color: white;
    background-color: $primary;
    border: 1px solid $inactive;
    svg{
      path{
        fill: white;
      }
    }
  }

  &.btn-primary{
    border: 1px solid $inactive;
  }

  &.btn-secondary{
    border: 1px solid transparent;
  }

  &.with-margin{
    margin-right: 5px;
  }
}