.suppliers-details {
  padding: 15px;
  // min-height: 500px;
  height: 100%;
  .close-details-block {
    position: absolute;
  }
  .content-details {
    width: 100%;
    //margin: 0 15px;
    padding: 15px;
    background-color: $secondary_one;
    overflow: auto;
    height: 100%;
  }
  .history-item {
    padding: 10px 0px;
  }
  .date-history-action {
    font-size: 10px;
    color: #b0bec5;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }
  .user-name-action {
    color: #3498db;
  }
  .action-name {
    margin-left: 10px;
    word-break: break-all;
    &.with-diff {
      cursor: pointer;
    }
  }
  .time-action {
    font-size: 10px;
    color: #b0bec5;
    position: absolute;
    right: 0;
    top: 0;
  }
  .row-action {
    position: relative;
    padding-right: 30px;
  }
  .list-history-title h6 {
    font-size: 12px;
    border-bottom: 1px solid #b0bec5;
    color: #b0bec5;
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding-bottom: 5px;
  }
  .list-history-title svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .preloader-wrap {
    max-height: 100vh;
  }

  .wrap-quality-camouflage {
    color: $primary;
    margin-bottom: 5px;
    margin-top: 5px;
    .quality-native {
      display: inline-block;
      min-width: 100px;
    }
    .supplier-element-form-wrapper {
      width: auto;
    }
  }
}
.suppliers-table {
  height: 100%;
}
.suppliers-group {
  display: flex;
  flex-direction: column;
  .form-label {
    margin-bottom: 0.4rem;
  }
}
.supplier-form {
  .bootstrap-class {
    width: 100%;
    //& > div[class*="control"] {
    //  border: 1px solid #ced4da;
    //}

    &:hover {
      & > div[class*='control'] {
        border-color: #80bdff;
      }
    }

    //&.invalid-select{
    //  & > div[class*="control"],&:hover > div[class*="control"]  {
    //    border: 1px solid #dc3545;
    //  }
    //
    //}
  }

  .bootstrap-class + .error-span {
    color: #dc3545;
    font-size: 80%;
  }

  .form-control.is-invalid {
    background-image: none;
    //border-color: #ced4da;
  }

  .row {
    &:nth-child(3),
    &:nth-child(4) {
      margin: 10px -15px;
    }
  }
  .action-supp-row {
    text-align: right;
    b {
      color: $primary;
      //font-weight: normal;
    }
  }
  .add-supplier-contact,
  .add-supplier-warehouse-address {
    margin-top: 10px;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: $inactive;
      }
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    svg {
      margin-right: 10px;
      width: 16px;
      height: 16px;
      path {
        fill: $inactive;
      }
    }
  }

  .address-row {
    margin-top: 10px;
    display: flex;
    padding-right: 16px;
    position: relative;
    width: 50%;
    &:hover {
      .delete-supplier-icon {
        visibility: visible !important;
        //margin-top: - 18px;
      }
    }
    .supplier-element-form-wrapper {
      width: 100%;
      flex-direction: column;
      .input-item {
        max-width: 100%;
      }

      textarea.auto-resize-wrapper {
        max-width: 100%;
      }
      .error-span {
        margin-left: 5px;
        width: auto;
        align-self: flex-start;
      }
    }
    .delete-supplier-icon {
      visibility: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 5px));
      right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
      path {
        fill: $inactive;
      }
    }
  }

  .contacts-title,
  .warehouse-title,
  .camouflage-title {
    font-size: $normal_text_size;
    border-bottom: 1px solid $inactive;
    color: $inactive;
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding-bottom: 5px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      path {
        fill: $inactive;
      }
    }
  }

  .contacts-title {
    margin-top: 5px;
  }

  .contact-supplier-block {
    border: 1px solid $inactive;
    position: relative;
    padding: 10px 5px 10px 5px;
    margin-top: 10px;
    border-radius: $border-radius;
    display: flex;
    flex-wrap: wrap;
    &:hover {
      .delete-supplier-icon {
        visibility: visible;
      }
    }
    .error-span {
      margin-left: 5px;
    }
    .supplier-element-form-wrapper {
      width: 50%;
      //display: inline-flex;
      //align-items: center;
      //flex-wrap: wrap;
      overflow: visible;
      //margin-right: 15px;
      margin-bottom: 5px;
      &:nth-of-type(odd) {
        padding-right: 15px;
      }
      &:nth-of-type(even) {
        padding-left: 15px;
      }
      &:nth-of-type(even) {
        margin-right: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

      .auto-resize-wrapper {
        overflow: visible;
        input {
          max-width: 130px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .delete-supplier-icon {
      visibility: hidden;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: $inactive;
      }
    }
  }

  .supplier-element-form-wrapper {
    width: 100%;
    //display: inline-flex;
    //align-items: center;
    //flex-wrap: wrap;
    overflow: hidden;
    .auto-resize-wrapper {
      overflow: visible;
    }
    &.buyer {
      overflow: visible;
    }
    .error-span {
      display: block;
      font-size: $accompanying_text_size;
      color: #dc3545;
      width: 100%;
    }
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      path {
        fill: $inactive;
      }
    }
    .auto-resize-wrapper {
      max-width: 100%;
      overflow-x: hidden;
      &:hover {
        .auto-resize-input {
          border: 1px solid $accent;
        }
      }
      .auto-resize-input {
        &::placeholder {
          color: $inactive;
        }
        border: 1px solid transparent;
        padding: 0 0.25rem;
        height: auto;
        font-size: $normal_text_size;
      }
    }
    &:hover {
      .auto-resize-textarea {
        border: 1px solid $accent;
      }
    }
    .auto-resize-textarea {
      border: 1px solid $accent;
      &::placeholder {
        color: $inactive;
      }
      border: 1px solid transparent;
      padding: 0 0.25rem;
      height: auto;
      font-size: $normal_text_size;
    }
  }
  .footer-form-custom.form-group {
    margin-bottom: 10px;
  }
}
