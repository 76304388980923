@import "../../adminStyle/variable";
.create-po-form{
  background-color: $secondary_one;
  padding: 0px;
  .row-po-form{
    display: flex;
    .wrapper-calendar-asana{
      min-width: 180px;
      .react-bootstrap-daterangepicker-container{
        width: 100%;
        display: flex!important;
      }
    }
    .form-group{
      //display: flex;
      //align-items: center;
      position: relative;
      .with-icon{
        top: 2px;
        font-size: 10px;
        right: 2px;
        position: absolute;
        z-index: 10;
        svg{
          width: 16px;
          height: 16px;
          path{
            fill: $inactive;
          }
        }
      }
      input[name=offerNumber]{
        border: 1px solid transparent;
        &:hover{
          border: 1px solid $border-hover;
        }

        &::placeholder{
          color: $inactive;
        }
      }

      &:first-of-type{
        margin-right: 5px;
      }
      &:last-of-type{
        margin-left: 5px;
      }
      flex: 1;

      .wrapper-calendar-asana{
        width: 100%;
          &:hover{
            border: 1px solid $border-hover;
          }

        input{
          color: $primary;
          font-size: 12px;
          font-family: 'Roboto-light';

          &::placeholder{
            color: $inactive
          }
        }
      }
    }
  }
  .footer-form {
    justify-content: flex-end;
  }
}
.wrap-quality-camouflage { 
  color: #616161;
  margin-bottom: 5px;
  margin-top: 5px;
  .quality-native {
    display: inline-block;
    min-width: 100px;
  }
  .supplier-element-form-wrapper {
    width: auto;
  }
}