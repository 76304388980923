.wrapper-undo-list{
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 25px;
  left: 47.5px;
  z-index: 10;
  .wrapper-undo-item{
    display: flex;
    flex-direction: column;
    background-color: $secondary_one;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    padding: 10px;
    margin-top: 10px;
    max-width: 200px;
    .body-undo{
      display: flex;
      .name-po{
        flex: 1;
        .order-number{
          color: $accent;
        }
      }
      svg{
        margin-left: 10px;
        width: $action_icon_size;
        height: $action_icon_size;
        cursor: pointer;
        path{
          fill: $inactive;
        }
      }
    }
    .footer-undo{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: red;
        }
      }
    }
  }
}