@import '../../adminStyle/variable.scss';

.history-block {
  .list-history {
    .comment {
      padding: 10px !important;
      .comment-content {
        margin-left: 10px;
      }
      .date-history-action {
        display: block !important;
      }
    }
    .service-desk-item {
      &.file {
        padding: 10px;
        padding-left: 0;
        background-color: #ffffff;
        .date-history-action {
          width: 100% !important;
          font-size: $accompanying_text_size;
          color: $inactive;
          margin-top: 10px;
          text-align: center;
          margin-bottom: 15px;
        }
        .row-action {
          .time-action {
            right: 0;
          }
        }
      }
      &.comment {
        background-color: #ffffff;
        .row-action {
          padding: 8px 0;
          .comment-content {
            display: inline-block;
          }
        }
      }
    }
  }
  .title-history {
    .icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      path {
        fill: #b0bec5;
      }
    }
  }
}
