#popover-basic{
    max-width: 350px;
}
.admin-header{
    flex: 0;
    margin-bottom: 10px;
    //min-height: 30px;

    svg{
        path{
            // fill: $primary;
        }
    }
    .left-pannel{
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        align-items: flex-end;
        //padding-top: 5px;
        .header-wrapper{
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .title{
            margin-bottom: 0;
            display: inline-block;
            color: #555;
            margin-left: 10px;
        }
    }
    .search-pannel{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        input{
            border-radius: 5px;
            //width: 100%;
            border: none;
            height: 30px;
            border: none;
            background: $secondary_two;
            padding: 0 10px;
            color: #555;
            width: 100%;
            &:focus{
                outline: none;
            }
        }
        .search-criterias-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 9px;

            .header-tab-button{
                border-radius: 0;
                border-left: none !important;
                border-top: none !important;
                border-right: none !important;
                border-bottom: 2px solid $primary;
                padding-top: 0;
                &:hover, &:active, &:focus{
                    color: $primary !important;
                    background: transparent !important;
                }
                &.cancel{
                    border-bottom: 2px solid transparent;
                    &:hover{
                        border-bottom: 2px solid $primary;
                    }
                }
            }
        }
        .search-criterias-wrap,
        .search-wrap,
        .filters-wrap{
            margin-top: 0px;
            .custom-chip{
                border: 1px solid #cad2d8;
                border-radius: 5px;
                background: $secondary_two;
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }
    .right-pannel{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 30px;
        align-self: flex-start;
        //padding-top: 5px;
        .actions-wrapper{
            display: flex;
            justify-content: flex-start;
            button{
                align-self: flex-start;
                margin: 0 5px;
                padding: 0;
            }
        }
        .menu-button{
            border: none;
            background: transparent;
            padding: 5px;
            margin: 5px;
            //&:hover{
            //    background: #ddd;
            //}
            &:focus{
                outline: none;
            }
            &:active{
                background: #eee;
            }
        }
        .author-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
                margin-left: 5px;
            }
        }
        .author-title{
            margin-left: 10px;
        }
        .dropdown-list {
            position: absolute;
            top: calc(100% + 5px);
            padding: 5px 0;
            border: 1px solid $inactive;
            min-width: 150px;
            background-color: #fff;
            z-index: 100;
            margin-bottom: 0;
            border-radius: 5px;
            right: 0;
            a {
                display: flex;
                align-items: center;
                padding: 2px 10px;
                color: $primary;
                font-size: $normal_text_size;
                font-family: Roboto-regular;
                &:hover {
                    text-decoration: none;
                    background-color: #f2f2f2;
                }
            }
        }
    }
}

.menu-popover{
    padding-top: 15px;
    >div {
        padding-bottom: 15px;
    }
    .menu-item{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3px;
        &:hover{
            outline: $inactive solid 1px;
            //border: 1px solid #cad2d8;
        }
    }
    p{
        color: $primary;
        margin: 0;
        text-align: center;
    }
    a, a:hover{
        color: inherit;
        text-decoration: inherit;
    }
    svg{
        width: $menu_icon_size;
        height: $menu_icon_size;
        path{
            // fill: $primary;
        }
    }
}

.btn-back {
    width: fit-content;
    margin-bottom: 10px;
    svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path {
            fill: #B0BEC5
        }
    }
    &:hover {
        svg {
            path {
                fill: #ffffff
            }
        }
    }
}

.badge {
    &.badge-default {
        border: 2px solid #ff9800;
        background-color: #ff9800;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        position: absolute;
        right: 10px;
        top: -10px;
        color: #fff;
    }
    &.badge-header {
        background-color: #ff9800;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        position: absolute;
        left: 24px;
        top: -15px;
        color: #fff;
        border: 2px solid #fff;
    }
    &.badge-homepage {
        border: 1px solid #fff;
        background-color: #ff9800;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        position: absolute;
        right: 3px;
        top: 0px;
        color: #fff;
    }
}