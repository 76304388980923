@import '../../admin/adminStyle/variable.scss';

$offset: 187;
$duration: 1.4s;

.preloader-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-direction: column;
  height: 100vh;
  .text {
    font-size: $normal_text_size;
    font-family: Roboto-light;
    color: $primary;
  }
  .spinner {
    margin: 0;
    width: 18px;
    text-align: center;
    > div {
      width: 4px;
      height: 4px;
      background-color: $inactive;
      margin: 0 1px;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.64s;
      animation-delay: -0.64s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce3 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}

//.spinner {
//  animation: rotator $duration linear infinite;
//}
//
//@keyframes rotator {
//  0% { transform: rotate(0deg); }
//  100% { transform: rotate(270deg); }
//}
//
//.path {
//  stroke-dasharray: $offset;
//  stroke-dashoffset: 0;
//  transform-origin: center;
//  animation:
//          dash $duration ease-in-out infinite,
//          colors ($duration*4) ease-in-out infinite;
//}
//
//@keyframes colors {
//  0% { stroke: #4285F4; }
//  25% { stroke: #DE3E35; }
//  50% { stroke: #F7C223; }
//  75% { stroke: #1B9A59; }
//  100% { stroke: #4285F4; }
//}
//
//@keyframes dash {
//  0% { stroke-dashoffset: $offset; }
//  50% {
//    stroke-dashoffset: $offset/4;
//    transform:rotate(135deg);
//  }
//  100% {
//    stroke-dashoffset: $offset;
//    transform:rotate(450deg);
//  }
//}
