.packages-wrapper {
  //padding-left: 10px;
  table {
    background-color: $secondary_one;
    border-collapse: collapse !important;
    thead {
      // background-color: $secondary_two !important;
      th {
        border-bottom: 2px solid $primary !important;
        color: $inactive !important;
        // background-color: $secondary_two;
        border-top: none;
        font-size: $normal_text_size;
        font-weight: 400;
      }
      .table-header-row {
        width: 100% !important;
      }
    }
    tr {
      td {
        color: $primary;
        font-size: $normal_text_size;
      }
    }
  }
  .accordion {
    .card {
      border: 1px solid $inactive;
      border-radius: 0;
      .card-header {
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 0;
        .header-collapse-package {
          display: flex;
          .left-part {
            flex: 1;
            font-family: Roboto-medium;
            display: flex;
            align-items: center;
            .edit-icon {
              visibility: hidden;
            }
            &:hover {
              .edit-icon {
                visibility: visible;
              }
            }
            svg {
              width: 16px;
              height: 16px;
              transition: 0.15s all;
              &.open {
                transform: rotate(180deg);
              }
              path {
                fill: $inactive;
              }
            }
            .action-icon {
              margin-left: 5px;
              flex-shrink: 0;
            }
          }
          .right-part {
            display: inline-flex;
            justify-content: flex-end;
            flex: 1;
            padding-top: 2px;
            .price {
              margin: 0 5px;
            }
            .item-icon {
              width: 16px;
              height: 20px;
              margin-right: 3px;
              margin-top: -3px;
              path {
                fill: $inactive;
              }
            }
          }
        }
      }
      .card-body {
        padding: 10px;
        table {
          margin-bottom: 0;
          border-collapse: collapse;
          thead {
            th {
              border-bottom: 2px solid $primary;
              color: $inactive;
              background-color: transparent;
              border-top: none;
            }
          }
          tr {
            td {
              color: $primary;
            }
          }
        }
      }
    }
  }
  .auto-resize-package-name {
    .package-input {
      border: 1px solid transparent;
      background-color: transparent;
      cursor: pointer;
      padding: 2px;
      color: rgb(84, 84, 84);
      margin-right: 2px;
      &:focus {
        outline: none;
      }
      &-edit {
        border-color: #ced4da;
        border-radius: 0.25rem;
      }
    }
  }
}
