.toolbar-activity{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}