.link-po {
  white-space: nowrap;
  a {
    color: $accent;
  }
}

.inventory-details {
  > .preloader-wrap {
    height: 200px;
  }
  .btn {
    .standart-svg {
      margin-right: 5px;
    }
  }
}

.align-items-center {
  align-items: center;
}

.inventory-table {
  &.with-details {
    .wrapper-table {
      min-width: 600px;
    }
  }
}

.view-pallet,
.pallet-detail {
  .header-pallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .info-pallet-wrapper {
      margin-bottom: 10px;
    }
    .info-pallet-wrapper.small {
      display: none;
    }
  }

  .all-actions {
    display: flex;
    justify-content: space-between;
    .actions-pallet {
      display: inline-block;
      button {
        margin-bottom: 10px;
      }
      .btn {
        margin-left: 10px;
      }
    }
  }

  .package__row,
  .box__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 0;
    font-size: $normal_text_size;
    .row__header {
      display: flex;
      width: 100%;
      min-height: 30px;
      .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;

        svg {
          transition: transform 0.3s;
          path {
            fill: $inactive;
          }
        }

        &:hover {
          cursor: pointer;
          svg {
            path {
              fill: $primary;
            }
          }
        }

        &.opened {
          svg {
            transform: rotate(90deg);
            path {
              fill: $primary;
            }
          }
        }
      }
      .info__header {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        border: 1px solid $inactive;
        //background-color: $secondary_two;
        width: 100%;
        border-radius: $border-radius;
        cursor: pointer;
        &.pack-type {
          cursor: auto;
        }
        &.opened {
          border-radius: $border-radius $border-radius 0 0;
          //border-bottom: none;
        }
        &.scanned-box {
          .title__box {
            color: $accent;
            font-weight: bold;
          }
        }
      }
    }
    .row__content {
      min-height: 100px;
      display: flex;

      .details__line {
        width: 26px;
        display: flex;
        justify-content: center;
        padding-bottom: 15px;

        .line {
          width: 2px;
          //margin-right: -1px;
          //margin-top: -10px;
          border-left: 1px solid $inactive;
        }
      }
      .content {
        display: flex;
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        background-color: #ffffff;
        width: 100%;
        padding: 5px;
        border-radius: 0 0 $border-radius $border-radius;
        border-left: 1px solid $inactive;
        border-bottom: 1px solid $inactive;
        border-right: 1px solid $inactive;
        &.with-column-view {
          flex-direction: column;
        }
        .no-data-to-display {
          text-align: center;
          width: 100%;
        }
        .box__wrap {
          cursor: initial;
          margin: 5px;
          //border: 1px solid $inactive;
          display: inline-flex;
          //justify-content: space-between;
          flex-direction: column;
          padding: 10px 5px 5px 10px;
          width: 80px;
          height: 80px;
          position: relative;
          .items-count {
            margin-top: auto;
            //margin-top: 0px;
            position: relative;
            z-index: 11;
            display: inline-flex;
            align-items: center;
            svg {
              margin-right: 5px;
            }
          }
          .icon-wrap {
            margin-left: auto;
            margin-top: auto;
          }
          &:hover {
            .box-bg {
              svg {
                path {
                  stroke: $accent;
                }
              }
            }
          }
          .box-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
              z-index: 10;
              path {
                stroke: $inactive;
              }
            }
          }
          &.selected {
            //box-shadow:  0 0 0 0.2rem rgba(0,123,255,.25);
            .icon-wrap {
              svg {
                path {
                  fill: $inactive;
                }
              }
            }
          }
        }
      }
    }
  }

  .box__row {
    .row__header {
      min-height: 40px;
      .info__header {
        justify-content: flex-start;
        align-items: center;
        &:hover {
          .delete-icon {
            visibility: visible;
          }
        }
        .delete-icon {
          min-width: 24px;
          margin-left: 10px;
          visibility: hidden;
          svg {
            width: 21px;
            height: 21px;
          }
        }
        .total-count {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          width: 46px;
          &.empty-box-error {
            color: red;
            .standart-svg {
              path {
                fill: red;
              }
            }
          }
          .standart-svg {
            margin-right: 5px;
            width: 21px;
            height: 21px;
            flex-shrink: 0;
          }
        }
        .title__box {
          flex: 1;
        }
        .actions {
          .btn {
            margin-right: 8px;
            // width: 92px;
          }
        }
        .checkbox-box-wrapper {
          display: inline-flex;
          align-items: center;
          width: 25px;
          padding-left: 4px;
          .checkbox-material {
            width: 15px;
            height: 15px;
            padding: 0;
            .mdc-checkbox__background {
              left: 0px;
              top: 0px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .row__content {
      .content {
        min-height: 100px;
        align-items: flex-start;
        .preloader-wrap {
          max-height: 100px;
        }
        .table {
          margin-bottom: 0;
          th {
            border-top: none;
            color: $inactive;
            font-family: Roboto-Regular;
            font-weight: 400;
          }
          td {
            color: $primary;
          }
          .checkbox-material {
            width: 15px;
            height: 15px;
            padding: 0;
            .mdc-checkbox__background {
              left: 0px;
              top: 0px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

.create-pallet__wrapper,
.edit-pallet__wrapper {
  .method-buttons-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    .header-tab-button {
      border-radius: 0;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      border-bottom: 2px solid $primary;
      padding-top: 0;
      margin-right: 10px;
      &:hover,
      &:active,
      &:focus {
        color: $primary !important;
        background: transparent !important;
      }
      &.cancel {
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
  .choose-method-wrapper {
    .content-step {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      .btn {
        font-size: $title_size;
      }
    }
  }
  .invalid-feedback {
    font-size: $accompanying_text_size;
  }
  .bids-option__wrapper {
    font-size: $accompanying_text_size;
    color: $inactive;
  }
  .preloader-wrap {
    height: auto !important;
    min-height: 150px;
  }
  .step {
    &.with-header-fix {
      .title-step {
        margin-bottom: 0.5rem;
      }
    }
    .title-step {
      color: $accent;
      border-bottom: 1px solid $inactive;
      font-size: $title_size;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      .footer-step {
        margin-top: 0;
        display: flex;
        align-items: center;
      }

      &.title-step-label {
        font-family: 'Roboto-Regular';
        font-weight: 400;
        color: $inactive;
      }

      .error {
        color: red;
        font-size: $accompanying_text_size;
        font-weight: 400;
        font-family: 'Roboto-light';
      }
    }
    .title-step-product {
      margin-top: 10px;
    }
    .subtitle-step {
      margin: 10px 0;
      font-size: $normal_text_size;

      &.title-step-label {
        font-family: 'Roboto-Regular';
        font-weight: 400;
        color: $inactive;
      }
    }
    .footer-step {
      margin-top: 10px;
      .btn {
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
      }
      .cancel {
        float: right;
        border: 1px solid transparent;
      }
    }
    &.first-step {
      .bootstrap-class {
        font-size: $normal_text_size;
        div[class*='control'] {
          //.bids-option__wrapper{
          //  display: none;
          //}
          > div {
            align-items: center;
          }
          div[class*='multiValue'] {
            background-color: transparent;
            border: 1px solid $inactive;
            border-radius: $border-radius;
            align-self: stretch;
            > div:last-of-type {
              &:hover {
                cursor: pointer;
                background-color: transparent;
                svg {
                  path {
                    color: $accent;
                  }
                }
              }
            }
          }
        }

        div[class*='menu'] {
          //position: static;
          box-shadow: none;
          border: 1px solid $inactive;
        }
      }
    }
    &.third-step {
      .title-step {
        margin-bottom: 0.5rem;
      }
      .form-control {
        font-size: $normal_text_size;
      }
      .bootstrap-class {
        font-size: $normal_text_size;
      }
    }
    &.second-step {
      .no-available-boxes {
        color: $inactive;
        text-align: center;
        font-size: $accompanying_text_size;
      }
      .create-box-wrapper {
        font-size: $normal_text_size;
        display: inline-flex;
        transition: all 0.25s;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        //top: 10px;
        margin-left: 5px;
        height: 80px;
        width: 80px;
        &:hover {
          background-color: $secondary_two;
        }
      }
      .boxes-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: $normal_text_size;
        .box__wrap {
          cursor: initial;
          .icon-wrap {
            z-index: 100;
            margin-top: 0;
            position: absolute;
            right: 5px;
            top: 8px;
          }
          svg {
            cursor: pointer;
          }
        }
      }
      .box__wrap {
        cursor: pointer;
        margin: 5px;
        //border: 1px solid $inactive;
        display: inline-flex;
        flex-direction: column;
        padding: 10px 5px 5px 10px;
        width: 100px;
        height: 100px;
        position: relative;
        justify-content: space-between;
        .items-count {
          //margin-top: auto;
          margin-top: 0;
          position: relative;
          z-index: 11;
          display: inline-flex;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }
        .icon-wrap {
          margin-left: auto;
          margin-top: auto;
        }
        &:hover {
          .box-bg {
            svg {
              path {
                stroke: $accent;
              }
            }
          }
        }
        .box-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
            z-index: 10;
            path {
              stroke: $inactive;
            }
          }
        }
        &.selected {
          //box-shadow:  0 0 0 0.2rem rgba(0,123,255,.25);
          .icon-wrap {
            svg {
              path {
                fill: $accent;
              }
            }
          }
        }
      }
      .package__row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px 0;
        font-size: $normal_text_size;
        .row__header {
          display: flex;
          width: 100%;
          min-height: 30px;
          .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;

            svg {
              transition: transform 0.3s;
              path {
                fill: $inactive;
              }
            }

            &:hover {
              cursor: pointer;
              svg {
                path {
                  fill: $primary;
                }
              }
            }

            &.opened {
              svg {
                transform: rotate(90deg);
                path {
                  fill: $primary;
                }
              }
            }
          }
          .info__header {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            border: 1px solid $inactive;
            //background-color: $secondary_two;
            width: 100%;
            border-radius: $border-radius;
            cursor: pointer;
            &.opened {
              border-radius: $border-radius $border-radius 0 0;
              //border-bottom: none;
            }
          }
        }
        .row__content {
          //min-height: 100px;
          min-height: 30px;
          display: flex;

          .details__line {
            width: 44px;
            display: flex;
            justify-content: center;
            padding-bottom: 15px;

            .line {
              width: 2px;
              //margin-right: -1px;
              //margin-top: -10px;
              border-left: 1px solid $inactive;
            }
          }
          .content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            background-color: #ffffff;
            width: 100%;
            padding: 5px;
            border-radius: 0 0 $border-radius $border-radius;
            border-left: 1px solid $inactive;
            border-bottom: 1px solid $inactive;
            border-right: 1px solid $inactive;
            .no-data-to-display {
              text-align: center;
              width: 100%;
            }
          }
        }
      }

      .workplace_row {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 0 15px 0;
        font-size: $normal_text_size;
        &:last-child {
          margin-bottom: 0;
        }
        .workplace__header {
          display: flex;
          width: 100%;
          min-height: 30px;
          .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 26px;

            svg {
              transition: transform 0.3s;
              path {
                fill: $inactive;
              }
            }

            &:hover {
              cursor: pointer;
              svg {
                path {
                  fill: $primary;
                }
              }
            }

            &.opened {
              svg {
                transform: rotate(90deg);
                path {
                  fill: $primary;
                }
              }
            }
          }
          .info__header {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $inactive;
            //background-color: $secondary_two;
            width: 100%;
            //border-radius: $border-radius;
            border-radius: 0;
            cursor: pointer;
            &.opened {
              border-radius: $border-radius $border-radius 0 0;
              //border-bottom: none;
            }
            &.scanned-box {
              .title__box {
                color: $accent;
                font-family: Roboto-medium;
              }
            }
          }
        }
        .workplace__content {
          min-height: 100px;
          display: flex;

          .details__line {
            width: 26px;
            display: flex;
            justify-content: center;
            padding-bottom: 15px;

            .line {
              width: 2px;
              //margin-right: -1px;
              //margin-top: -10px;
              border-left: 1px solid $inactive;
            }
          }
          .content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            background-color: #ffffff;
            width: 100%;
            padding: 5px;
            border-radius: 0 0 $border-radius $border-radius;
            //border-left: 1px solid $inactive;
            //border-bottom: 1px solid $inactive;
            //border-right: 1px solid $inactive;
            border-left: none;
            border-bottom: none;
            border-right: none;
            &.with-column-view {
              flex-direction: column;
            }
            .no-data-to-display {
              text-align: center;
              width: 100%;
            }
            .box__wrap {
              cursor: initial;
              margin: 5px;
              //border: 1px solid $inactive;
              display: inline-flex;
              //justify-content: space-between;
              flex-direction: column;
              padding: 10px 5px 5px 10px;
              width: 80px;
              height: 80px;
              position: relative;
              .items-count {
                margin-top: auto;
                //margin-top: 0px;
                position: relative;
                z-index: 11;
                display: inline-flex;
                align-items: center;
                svg {
                  margin-right: 5px;
                }
              }
              .icon-wrap {
                margin-left: auto;
                margin-top: auto;
              }
              &:hover {
                .box-bg {
                  svg {
                    path {
                      stroke: $accent;
                    }
                  }
                }
              }
              .box-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                svg {
                  width: 100%;
                  height: 100%;
                  z-index: 10;
                  path {
                    stroke: $inactive;
                  }
                }
              }
              &.selected {
                //box-shadow:  0 0 0 0.2rem rgba(0,123,255,.25);
                .icon-wrap {
                  svg {
                    path {
                      fill: $accent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.edit-pallet__wrapper {
  .step {
    margin-bottom: 10px;

    .title-step {
      border-bottom: none;
      font-size: $normal_text_size;
      padding-bottom: 0;
    }
  }
  .step.first-step {
    .bootstrap-class {
      div[class*='menu'] {
        z-index: 200;
        position: absolute;
        box-shadow: none;
        border: 1px solid $inactive;
      }
    }
  }
  .footer-inventory {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cancel {
      border: 1px solid transparent;
    }
  }
  .title-step {
    margin-bottom: 10px;
    .error {
      color: red;
      font-size: $accompanying_text_size;
      margin-left: 5px;
    }
  }
  .second-step {
    //position: relative;

    .preloader-wrap {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100% !important;
    }
  }
  .step.second-step {
    .boxes-wrapper {
      .box__wrap {
        //justify-content: space-between;
        .icon-wrap {
          position: relative;
          top: 0;
          right: 0;
        }
        .icon-delete {
          position: absolute;
          right: 5px;
          top: 8px;
        }
      }
    }
  }
}

.move-boxes-modal-wrapper,
.move-articles-modal-wrapper {
  font-size: $normal_text_size;
  .bootstrap-class {
    margin-bottom: 10px;
    .label-pallet {
      .label {
        font-family: Roboto-medium;
      }
    }
    div[class*='control'] {
      .label-pallet {
        > div:not(.label) {
          display: none;
        }
      }
    }
  }
  .footer-inventory {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &.move-articles-modal-wrapper {
    .bootstrap-class {
      div[class*='menu'] {
        z-index: 1000;
      }
    }
    .boxes-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      .error {
        display: block;
        width: 100%;
        color: red;
        font-size: $accompanying_text_size;
        margin-top: 10px;
      }
      .box__wrap {
        cursor: pointer;
        margin: 5px;
        //border: 1px solid $inactive;
        display: inline-flex;
        flex-direction: column;
        padding: 10px 5px 5px 10px;
        width: 106px;
        height: 100px;
        position: relative;
        &.new-box {
          .icon-wrap {
            //display: flex;
            //align-items: center;
            //justify-content: space-between;
            //flex-direction: row-reverse;
            text-align: right;
            position: initial;
            margin-right: 5px;
            .delete-icon {
              path {
                fill: $inactive;
              }
            }
          }
        }
        .items-count {
          margin-top: auto;
          position: relative;
          z-index: 11;
          display: inline-flex;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }
        .icon-wrap {
          margin-left: auto;
          margin-top: auto;
        }
        &:hover {
          .box-bg {
            svg {
              path {
                stroke: $accent;
              }
            }
          }
        }
        .box-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
            z-index: 10;
            path {
              stroke: $inactive;
            }
          }
        }
        &.selected {
          //box-shadow:  0 0 0 0.2rem rgba(0,123,255,.25);
          .icon-wrap {
            svg {
              path {
                fill: $accent;
              }
            }
          }
        }
      }
      .create-box-wrapper {
        display: inline-flex;
        transition: all 0.25s;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        //top: 10px;
        margin-left: 5px;
        height: 80px;
        width: 80px;
        &:hover {
          background-color: $secondary_two;
        }
      }
    }
  }
  .form-group {
    width: 100%;
    margin-bottom: 10px;
    .form-label {
      margin-bottom: 10px;
    }
    .form-control {
      &.is-invalid {
        background-image: none;
        border-color: #ced4da;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.toolbar-inventory {
  .actions-inventory-toolbar {
    display: inline-flex;
    align-items: center;
    flex: 1;
  }
  .scan-elements {
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
    span {
      margin-right: 15px;
    }
    .btn {
      margin-right: 15px;
      svg {
        margin-right: 5px;
      }
      &.active-mode {
        svg {
          path {
            fill: $accent;
          }
        }
      }
    }
  }
}

.move-action-title {
  font-size: $subtitle_size;
  text-align: center;
}

.info-pallet {
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
}

.inventory-confirm {
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
}

.footer-form-inventory {
  padding: 1rem;
}
.add-box-btn {
  margin-left: 5px;
}

.list-history {
  .list-history-title {
    .header-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    h6 {
      font-size: 12px;
      border-bottom: 1px solid #b0bec5;
      color: #b0bec5;
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding-bottom: 5px;
    }
  }

  .history-item {
    padding: 10px 0px;
    .date-history-action {
      font-size: 10px;
      color: #b0bec5;
      margin-top: 0 !important;
      margin-bottom: 5px !important;
      text-align: center;
    }

    .row-action {
      position: relative;
      padding-right: 30px;
      .user-name-action {
        color: #3498db;
      }
      .action-name {
        margin-left: 10px;
        word-break: break-all;
        &.with-diff {
          cursor: pointer;
        }
      }
      .with-diff {
        color: #b0bec5;
      }
      .time-action {
        font-size: 10px;
        color: #b0bec5;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .header-icon {
    margin-right: 5px;
    path {
      fill: #b0bec5;
    }
  }
}

//@media (max-width: 1250px) {
@media (max-width: 1440px) {
  .actions-pallet button {
    margin-left: 0px !important;
    margin-right: 5px;
    margin-bottom: 5px !important;
  }
  .info-pallet-wrapper {
    //display: grid;
    display: none;
    margin-bottom: 5px !important;
  }
  .info-pallet-wrapper.small {
    display: flex !important;
    margin-right: 5px;
    .info-pallet-col {
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-right: 5px;
        min-width: 95px;
      }
      .info-pallet-title,
      .info-pallet-val {
        font-size: $normal_text_size;
        height: 15px;
      }
    }
  }
}

.pack-type-icon__wrapper {
  display: flex;
  justify-content: center;
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
    path {
      fill: $inactive;
    }
  }
}
.pack-status-icon__wrapper {
  display: flex;
  justify-content: center;
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
  }
}

.packs-type-option {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  .packs-type-option__icon-wrapper {
    margin-right: 10px;
    svg {
      width: $action_icon_size !important;
      height: $action_icon_size !important;
      path {
        fill: $inactive;
      }
    }
  }
  .packs-status-option__icon-wrapper {
    margin-right: 10px;
    svg {
      width: $action_icon_size !important;
      height: $action_icon_size !important;
    }
  }
}
