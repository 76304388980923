.header-collapse-set-wrapper{
  display: flex;
  cursor: pointer;
  border: 1px solid $inactive;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 7px 10px;
  background-color: rgba(0,0,0,.03);
  .left-part{
    flex: 1;
    display: flex;
    font-family: Roboto-medium;
    svg{
      width: 16px;
      height: 16px;
      margin-right: 2px;
      transition: all .15s;
      &.open{
        transform: rotate(180deg);
      }
      path{
        fill: $inactive;
      }
    }
  }
  .right-part{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .price{
      margin: 0 5px;
    }
    .item-icon {
        width: 16px;
        height: 20px;
        margin-right: 3px;
        margin-top: -3px;
        path {
          fill: $inactive;
        }
    }
  }
}