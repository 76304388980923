.inbound-report-right-part {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .last-scanned-item {
        white-space: nowrap;
        margin-bottom: 10px;
        strong{
            font-family: 'Roboto-Regular';
        }
    }
}