.release-card{
  margin-bottom: 20px;
  &.card{
    border: none;
  }
  svg{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
  }
}
.release-note-modal-body{
  .error-group{
    >.ck{
      border: 1px solid #dc3545;
      border-radius: .25rem;
      +span{
        color: #dc3545;
        font-size: 12px;
      }
    }
  }
}

.modal-80{
  width: 70% !important;
  max-width: 900px !important;
}

.modal-70{
  width: 90% !important;
  max-width: 800px !important;
}