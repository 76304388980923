.charts-block{
  height: 100%;
  overflow-y: auto;
  .preloader-wrap{
    max-height: 200px;
  }
  h5{
    text-align: center;
    font-family: Roboto-light;
    font-size: $subtitle_size;
    .bootstrap-class{
      display: inline-block;
      >div {
        min-height: auto;
        >div:first-of-type {
          min-width: 30px;
        }
      }
      div[class*=IndicatorsContainer]{
        //display: none;
        >div{
          padding: 1px;
        }
      }
    }
  }
  .top-charts{
    width: 100%;
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    .chart-wrapper{
      padding-top: 5px;
      width: calc(100%/2);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .total-charts{
    width: 100%;
    display: flex;
    margin-top: 50px;
    .chart-wrapper{
      width: calc(100%/3);
    }
  }
}