@import "src/components/admin/adminStyle/variable";

.mapDictionaryForm{
  .colors-wrapper{
    display: flex;
    flex-wrap: wrap;
    max-width: 210px;
    .project-color-block{
      cursor: pointer;
      width: 25px;
      height: 25px;
      margin-right: 10px;
      margin-bottom: 10px;
      .standart-svg{
        width: 25px;
        height: 25px;
        //stroke: $backgound_color;
      }
    }
  }
  .mdc-checkbox{
    
  }
}