.tasks-wrapper{
  //margin-top: 10px;
  .task{
    //margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    //border-bottom: 1px solid $inactive;
    background-color: $secondary_one;
    padding: 10px;
    margin-top: 0;
    position: relative;
    color: $primary;

    &:last-child{
      border-bottom: none;
    }
    .task-name{
      font-family: Roboto-Regular;
      color: $primary;
    }
    &.completed-task{
      //background-color: rgba(189, 189, 189,0.1);
      color: $inactive;
      .task-name{
        color: $inactive;
      }
    }
    .dropdown{
      .dropdown-menu{
        .dropdown-item {
          color: #212121;
          padding: 2px 10px;
          font-family: "Roboto-Regular";
        }
      }
      ul{
        padding: 0;
      }
      button{
        padding: 0;
        margin-left: 5px;
        border: none;
        background-color: transparent;
        outline: none!important;
      }
      .deadline-value{
        svg{
          width: $action_icon_size;
          height: $action_icon_size;
          path{
            fill: $inactive;
          }
        }
      }

    }
    .complete-icon{
      //cursor: pointer;
      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: $inactive;
        }
      }
      &.with-hover{
        cursor: pointer;
        &:hover{
          svg{
            path{
              fill: #25e8c8;
            }
          }
        }
      }

      &.complete{
        svg{
          path{
            fill: #25e8c8;
          }
        }
      }
    }
    .row-action{
      position: relative;
      display: flex;
      justify-content: space-between;

      .executor-value{
        &.canEdit{
          cursor: pointer;
        }
      }

      .info-task{
        display: inline-flex;
        align-items: center;
        svg{
          margin-right: 5px;
          //cursor: pointer;
          &.complete{
            path{
              fill: green;
            }
          }
        }
        //.task-name{
        //  color: $primary;
        //}
      }
      .deadline-executor{
        display: flex;
        align-items: center;
        .deadline-value{
          font-size: $accompanying_text_size;
          color: $inactive;
          svg{
            margin-right: 5px;
          }
          margin-right: 10px;
        }
      }
    }
    .deadline-value,.dropdown{
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }

  }

  .wrapper-department-column{
    +.wrapper-department-column{
      margin-left: 5px;
      .icon-department{
        margin: 0;
      }
    }
  }
  .task-responsible-wrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
    .icon-department{
      margin-right: 5px;
    }
    .list-users-responsible{
      right: 0 !important;
      left: auto !important;
      top: 20px;
    }
  }
}

