.toolbar-inbox{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .actions-tasks-toolbar{
    display: flex;
    align-items: center;
    .filters-list-wrapper{
      .filters-list{
        left: 0;
      }
    }
    .add-button-task{
      margin-right: 10px;
      svg{
        width: 16px;
        height: 16px;
        margin-right: 5px;
        // path{
        //   fill: $inactive;
        // }
      }
    }
  }
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}