@import '../../admin/adminStyle/variable.scss';
.customer-details {
  padding: 15px;
  &.edit-details {
    padding: 0 !important;
    .content-details {
      background-color: $secondary_two;
    }
  }
  .content-details {
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    background-color: #fff;
    height: 100%;
  }
  .close-details-block {
    position: absolute;
    z-index: 1;
  }
  .customer-form {
    margin-bottom: 1rem;

    .responsible-wrapper {
      display: flex;
      align-items: center;

      ul {
        top: 26px;
      }
    }
    .row {
      margin-bottom: 1rem;
    }
    .action-supp-row {
      margin-bottom: 0;
    }
    .form-group {
      width: 100%;
    }
    //.form-label {
    //  //color: $inactive;
    //  color: $primary;
    //  font-size: 12px;
    //  font-weight: 500;
    //}
    //.form-control.is-invalid {
    //  background-image: none;
    //  //border-color: #ced4da;
    //}

    //.form-control::placeholder{
    //    //  color: $inactive;
    //    //}
    //    //.invalid-feedback {
    //    //  font-size: 80%;
    //    //}
    label {
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
    }
    .contacts-title {
      font-size: 12px;
      border-bottom: 1px solid $inactive;
      color: $inactive;
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding-bottom: 5px;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        path {
          fill: $inactive;
        }
      }
    }
    .add-customer-contact {
      margin-top: 10px;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
    }
    .col-9:not(:last-of-type) {
      .contact-customer-block {
        border-bottom: 1px solid $secondary_two;
      }
    }
    .contact-customer-block {
      //border: 1px solid $inactive;
      position: relative;
      padding: 5px 25px 5px 0px;
      margin-top: 10px;
      //border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      &:not(:last-of-type) {
        border-bottom: 1px solid $secondary_two;
      }
      //border-bottom: 1px solid $secondary_two;
      .delete-customer-icon {
        visibility: hidden;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
      &:hover {
        .delete-customer-icon {
          visibility: visible;
        }
      }
      .customer-element-form-wrapper {
        //width: 50%;
        //display: inline-flex;
        //align-items: center;
        //flex-wrap: wrap;
        //margin-right: 15px;
        .form-label {
          min-width: 140px;
        }
        margin-bottom: 5px;
        //&:nth-of-type(odd){
        //  padding-right: 15px;
        //}
        //&:nth-of-type(even){
        //  padding-left: 15px;
        //}
        //&:nth-of-type(even) {
        //  margin-right: 0;
        //}
        //&:last-of-type {
        //  margin-bottom: 0;
        //}
        .error-span {
          display: block;
          font-size: 10px;
          color: #dc3545;
          width: 100%;
        }
        .auto-resize-wrapper {
          max-width: 100%;
          .auto-resize-input {
            border: 1px solid transparent;
            padding: 0 0.25rem;
            height: auto;
            font-size: 12px;
            max-width: 100%;
            &:focus {
              border-color: $border_hover;
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }

            //&::-webkit-input-placeholder { /* Edge */
            //  color: $inactive;
            //}
            //
            //&:-ms-input-placeholder { /* Internet Explorer 10-11 */
            //  color: $inactive;
            //}
            //
            //&::placeholder{
            //  color: $inactive;
            //}
          }
        }
      }
    }

    .bootstrap-class {
      //& > div[class*="control"] {
      //  border: 1px solid #ced4da;
      //}

      &:hover {
        & > div[class*='control'] {
          border-color: #80bdff;
        }
      }

      //&.invalid-select{
      //  & > div[class*="control"],&:hover > div[class*="control"]  {
      //    border: 1px solid #dc3545;
      //  }
      //
      //}
    }

    .bootstrap-class + .error-span {
      color: #dc3545;
      font-size: 80%;
    }
  }
  .footer-form.customer-footer {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .history-block {
    flex: 0;
  }
}

.customer-form-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  .customer-select {
    margin-bottom: 0.5rem;
  }
  .error-span {
    margin-top: 0.25rem;
    display: block;
    font-size: 80%;
    color: #dc3545;
    width: 100%;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path {
      fill: $inactive;
    }
  }
  .auto-resize-wrapper {
    max-width: 100%;
    &:hover {
      .auto-resize-input {
        border: 1px solid $accent;
      }
    }
    .auto-resize-input {
      border: 1px solid transparent;
      padding: 0 0.25rem;
      height: auto;
      font-size: $normal_text_size;
      max-width: 100%;
      &:focus {
        border-color: $border_hover;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .bootstrap-class {
    flex-grow: 1;
  }
}
.request-file-upload {
  background-color: transparent;
  border: 1px solid transparent;
  color: #707070;
  border-radius: 0.25rem;
  &:hover {
    border: 1px solid #3498db;
  }
  &:focus {
    border-color: transparent;
    outline: none;
  }
}

.wrapper-body {
  .wrap-table-with-details-panel.customer-table {
    max-height: calc(100vh - 70px);
    .actions-customer-toolbar {
      display: flex;
      align-items: center;
      .add-button-customer {
        margin-right: 10px;
      }
      .filters-list-wrapper {
        .filter-button {
          text-transform: none;
        }
      }
    }
    &.with-details {
      display: grid;
      grid-template-columns: 1fr 2fr;
      .customer-details {
        width: 100%;
        .content-details {
          width: 100%;
          .edit-customer-form {
            .customer-form {
              width: 100% !important;
              .customer-info-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 15px;
                margin-right: 0;
              }
            }
          }
        }
      }
      .toolbar-customer {
        flex-wrap: wrap;
        .actions-customer-toolbar {
          width: 100%;

          .filters-list-wrapper {
            display: inline-flex;
          }
        }
      }
    }

    .mdc-checkbox .mdc-checkbox__checkmark-path {
      fill: none;
    }
  }
}
.customer-company-cell {
  display: flex;
  flex-direction: column;
}
.customer-cell-info {
  margin-bottom: 0.2rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  &-inactive {
    color: $inactive;
  }
}

.customer-table-header {
  display: flex;
  flex-direction: column;
}

.customers-table {
  th {
    vertical-align: top !important;
  }

  .wrapper-department-column:not(.bulk) {
    //background-color: red;
    .list-users-responsible {
      right: 0;
      left: unset !important;
    }
  }
}

.reject-modal {
  textarea.form-control.is-invalid {
    background-image: none;
  }
}

.edit-customer-form {
  display: flex;
  .wrapper-department-column {
    //border: 1px solid $accent;
    background-color: $secondary_one;
    cursor: pointer;
  }
  .customer-form {
    flex: 1;
    .responsible-name {
      align-self: center;
      color: $inactive;
    }
    .status {
      align-self: center;
      margin-right: 10px;
      display: flex;
      align-items: center;

      span {
        color: $inactive;
      }
      &.deactivated {
        color: $inactive;
      }
      svg {
        width: 16px;
        height: 16px;
        margin: 0 5px;
      }
    }
  }
  .history-customer-wrapper {
    position: relative;
  }
  .info {
    padding: 10px;
    background-color: $secondary_one;
    &.view {
      .form-group {
        &.horizontal {
          .form-label {
            margin: 0;
          }
        }
      }
    }
  }
  .contacts {
    background-color: $secondary_one;
    padding: 10px;
    //margin-top: 10px;
    &.view {
      .horizontal {
        .form-label {
          margin: 0;
        }
      }
    }
  }
  .customer-form {
    display: flex;
    flex-wrap: wrap;
    .footer-form-custom {
      .btn.btn-primary {
        border: 1px solid $inactive;
      }
    }
    .form-header {
      width: 100%;
    }
    .customer-info-wrapper {
      flex: 1;
      margin-right: 15px;
      .contacts-wrapper {
        margin-top: 10px;
        .contacts-title {
          margin-bottom: 10px;
        }
      }
    }
    .history-customer-wrapper {
      flex: 1;
    }
    .form-title {
      display: flex;
      align-items: center;
      .footer-form {
        flex: 1;
      }
    }
    .form-group {
      .form-label {
        min-width: 140px;
      }
    }
  }
}

.email-customer-list {
  color: $inactive;
  font-size: $accompanying_text_size;
}

.create-customer-form {
  .customer-form {
    display: flex;
    flex-wrap: wrap;
    .form-header {
      width: 100%;
    }
    .info-wrapper {
      flex: 1;
      margin-right: 20px;
      .info {
        background-color: $secondary_one;
        padding: 10px;
      }
    }
    .contacts-wrapper {
      flex: 1;
      .contacts {
        margin-top: 10px;
        padding: 10px;
        background-color: $secondary_one;
        .contact-customer-block:not(:last-of-type) {
          border-bottom: 1px solid $secondary_two;
        }
        .btn {
          margin-top: 0;
        }
      }
    }
  }
}
