@import './list/style.scss';
@import './history/style.scss';

.template-responsible-dueDate {
  display: flex;
  .task-template {
    margin-right: 10px;
    flex: 1;
  }
  .responsible-form-group {
    min-width: 150px;
    margin: 0 10px;
  }
  .wrapper-calendar-asana {
    min-width: 190px;
    .react-bootstrap-daterangepicker-container {
      //width: 100%;
    }
  }
}

.title-responsible-wrapper {
  display: flex;
  .title-form-group {
    flex: 1;
  }
  .responsible-form-group {
    min-width: 340px;
    margin: 0 10px;
  }
}
.modal-body{
  .title-responsible-wrapper{
    .responsible-form-group {
      min-width: 180px;
      margin: 0 10px;
    }
  }
}

.wrap-table-with-details-panel {
  &.with-details {
    .table-block {
      &.tasks-table {
        max-width: 650px;
      }
    }
    .tasks-details {
      padding: 15px;
      min-height: 500px;
      .close-details-block {
        position: absolute;
      }
      .content-details {
        width: 100%;
        //margin: 0 15px;
        padding: 15px;
        background-color: $secondary_one;
      }
    }
  }
}

//.task-form{
*[class*=loadingIndicator] {
  display: none !important;
}

.bootstrap-class {
  &:hover {
    > div[class*=control] {
      border-color: #80bdff;
    }
  }
  > div[class*=control] {
    background-color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
    min-height: 30px;
    display: flex;
    flex-direction: row-reverse;
    > div {
      padding-left: 0;
      > div {
        color: $inactive;
      }
    }
    div[class*=singleValue] {
      color: $primary;
    }
    svg {
      width: 16px;
      height: 16px;
    }
    *[class*=indicatorSeparator] {
      display: none;
    }
    *[class*=indicatorContainer] {
      padding: 1px;
    }
  }
}
//}

.status-toggler {
  text-align: center;
  cursor: pointer;
  svg {
    height: 20px;
    width: 25px;
  }
}