@import "../../admin/adminStyle/variable";

$offset: 187;
$duration: 1.4s;

.preloader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  padding: 50px 0;
  background: rgba(0, 0, 0, .7);
  .centerText{
      display: flex;
  }
  .desc{
    justify-self: flex-end;
    font-size: $accompanying_text_size;
  }
  .text {
    font-size: $subtitle_size;
    font-family: 'Roboto-Light';
    color: white;
  }
  .spinner {
    width: 18px;
    text-align: center;
    margin: 4px 0 0 2px;
    > div {
      width: 4px;
      height: 4px;
      background-color: white;
      margin: 0 1px;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.64s;
      animation-delay: -0.64s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce3 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}

.global-preloader{
  position: absolute;
  top:0;
  left: 0;
  z-index: 2000;
}