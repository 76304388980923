.row-so-form{
  display: flex;
  flex-wrap: nowrap;
  .form-group{
    flex: 1;
    //align-content: center;
    &:not(:last-of-type){
      margin-right: 15px;
    }
    .wrapper-calendar-asana{
      margin-top: 15px;
      align-self: center;
    }
  }
}