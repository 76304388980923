.wrap-supplier-filter{
  height: $action_icon_size;
  //width: $action_icon_size;
  overflow: visible;
  //display: none;
  &.open{
    .select-supplier{
      display: block;
    }
  }
  .filter-supplier-button{
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    &:focus{
      outline: none;
    }
    svg{
      width: $action_icon_size;
      height: $action_icon_size;
      &.active-filter{
        path{
          fill: $accent;
        }
      }
      path{
        fill: $inactive;
      }
    }
  }
  .select-supplier{
    z-index: 100;
    position: absolute;
    left: 0;
    top: 40px;
    min-width: 100%;
    //width: 200%;
    display: none;
    div[class*=MenuList]{
      >div{
        white-space: normal;
      }
    }
    >div[class*=container]{
      >div[class*=control]{
        flex-wrap: nowrap;
      }
    }
  }
}