@import "src/components/admin/adminStyle/variable";

.legend-svg{
  position: sticky;
  position: -webkit-sticky;
  &.top-legend{
    top: 0;
    z-index: 6;
  }
  &.bottom-legend{
    bottom: 0;
    z-index: 6;
  }
  &.left-legend{
    left: 0;
    z-index: 5;
  }
  &.right-legend{
    right: 0;
    z-index: 5;
  }
}

.legend-group{
  &.hovered{
    .legend-rect{
      fill: $grey;
    }
  }
  .legend-rect{
    fill: white;
    stroke: #B0BEC5;
    stroke-width: 0.5;
  }
}

.slot{
  z-index: 2;
  &.with-active{
    &:hover, &.rect, &.text{
      cursor: pointer;
    }
    &:hover{
      rect{
        //fill: $accent;
        //stroke-width: 1;
        stroke: $accent;
      }
    }
  }
  &.draggable{
    z-index: 1;
  }
  &.is-dragging{
    //display: none;
  }
  &.is-drag-view{
    //&, rect, text,{
    //  transition: all 0.1s ease;
    //}
  }
  //&.searched{
  //  rect{
  //    //fill: $accent;
  //    stroke: $accent;
  //  }
  //}
}

.map-toolbar{
  margin: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-part{
    button{
      &:first-child{
        margin-right: 10px;
      }
    }
  }
  .actions{
    display: flex;
    align-items: center;
    .change-mode-icon{
      margin-left: 10px;
      width: 20px;
      height: 20px;
      &:hover{
        cursor: pointer;
      }
      path{
        fill: $primary;
      }
    }
  }
}

.pallet-detail{
  display: flex;
  flex-direction: column;
  .actions-pallet{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    button{
      margin-left: 10px;
    }
  }
  .pallet-detail-row{
    display: flex;
    .pallet-detail-col{
      flex: 1;
      display: flex;
      .detail-label{
        width: 90px;
        margin-right: 5px;
        font-size: $normal_text_size;
      }
      .detail-val{
        font-size: $normal_text_size;
      }
    }
  }
  .list-history{
    .row-action{
      font-size: $normal_text-size;
    }
  }
}

.scale-panel{
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .rc-slider{
    width: 200px;
    margin: 0 10px;
  }

  .standard-svg{
    cursor: pointer;
  }
}

.inventory-filters-wrapper{
  display: flex;
  align-items: center;
}

.multiselect-filter__wrapper{
  margin-left: 10px;
  position: relative;
  .multiselect-filter__toggle{
    padding: 5px;
    display: flex;
    min-height: 30px;
    align-items: center;
    border: 1px solid transparent;
    border-radius: .25rem;
    cursor: pointer;
    &:hover{
      border: 1px solid $accent;
    }
    .toggle-name{
      margin-right: 5px;
    }
    .toggle-count{
      margin-right: 5px;
      color: white;
      background: $primary;
      border: 1px solid transparent;
      border-radius: .25rem;
      padding: 2px;
      min-width: 18px;
      text-align: center;
      font-size: $accompanying_text_size;
    }
    svg{
      width: 16px;
      height: 16px;
      transform: rotate(90deg);
      path{
        fill: $inactive;
      }
    }
  }
  .multiselect-filter__list{
    display: none;
    position: absolute;
    margin-bottom: 0;
    padding-left: 0;
    border: 1px solid $inactive;
    border-radius: 5px;
    min-width: 150px;
    right: 0;
    z-index: 10;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    &.open{
      display: block;
    }
    li{
      list-style-type: none;
      padding: 5px;
      &.search-list-item{
        .form-control{
          border-radius: 5px;
          font-size: 12px;
          border: none;
          height: 20px;
          background: $secondary_two;
          padding: 0 10px;
          color: #555;
          width: 100%;
          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
      }
      &.info-list-item{
        display: flex;
        justify-content: space-between;
        .clear-btn{
          &:hover{
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      &.list-item{
        &:hover{
          cursor: pointer;
          background: $secondary_two;
        }
        .form-group{
          margin-bottom: 0;
          .form-check{
            display: flex;
            align-items: center;

            .form-check-input{
              margin-top: 0;
            }
            .form-check-label{
              text-align: left;
            }
          }
        }
        .custom-control-label{
          width: 100%;
          &:hover{
            cursor: pointer;
          }
        }
        .custom-control-input:checked~.custom-control-label::before{
          color: #fff;
          border-color: #228B22 !important;
          background-color: #228B22 !important;
        }

        .custom-control-input:focus~.custom-control-label::before{
          box-shadow: 0 0 0 0.1rem rgba(34,139,34, 0.25);
        }

        .custom-control-label{
          text-align: left;
          padding-top: 3px;
          &:before, &:after{
            width: 14px !important;
            height: 14px !important;
            //border-color: green !important;
            //background-color: green !important;
          }
        }
      }
    }
  }
}

.hovered-tooltip{
  padding: 10px;
  border-radius: 5px;
  width: 150px;
  position: absolute;
  background: white;
  box-shadow: 0 0 10px $inactive;
  z-index: 10;
  .tooltip-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
    &.po-row{
      align-items: flex-start;
    }
    .status-block{
      display: flex;
      align-items: center;
      .status__wrapper{
        margin-right: 5px;
        svg{
          width: 20px;
          height: 20px;
        }
      }
    }
    .icons-wrapper{
      display: flex;
      .quantity-block{
        display: flex;
        align-items: center;
        svg{
          margin-right: 5px;
        }
      }
    }
  }
}