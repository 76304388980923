
.editable-item{
  min-width: 15px;
  display: inline-block;
  min-height:10px;
  cursor: pointer;
  color: #007bff;
  border-bottom: 1px dashed #007bff;
}
.controll-buttons{
  margin-top: 15px;
  .subm-btn{
    margin-right: 15px;
  }
}
.editable-form{
  min-width: 200px;
}