@import "../adminStyle/variable";

.wrap-table-with-details-panel {
  &.with-details {
    .table-block.users-table {
      max-width: 800px;
    }
  }
}

.toolbar-users {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .actions-users-toolbar {
    .add-button-users {
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
    }
  }
  .pagination-toolbar {
    td {
      border-bottom: none;
      padding: 0 !important;
      .MuiTablePagination-toolbar {
        min-height: 0;
        height: auto;
      }
      button {
        &:focus {
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}

.user-notifications {
  .notification-row {
    display: flex;
    .col-notif {
      flex: 1;
      > .checkbox-material {
        //padding: 0;
      }
      > svg {
        width: $action_icon_size;
        height: $action_icon_size;
        margin-right: 5px;
        path {
          fill: $inactive;
        }
      }
    }
    &.header-notifications {
      //padding-left: 0;
      border-bottom: 1px solid $inactive;
    }
  }
  h6 {
    display: flex;
    border-bottom: 1px solid $inactive;
    padding-bottom: 5px;
    font-size: $normal_text_size;
    align-items: flex-end;
    color: $inactive;
    svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
  .notifications-list {
    .notification-row {
      //padding-left: 10px;
      display: flex;
      align-items: center;
    }
  }
}

.language-cards__wrapper {
  .language-card {
    padding: 5px 20px 12px 20px;
    margin-bottom: 15px;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    position: relative;

    &:hover {
      .remove__btn {
        visibility: visible;
      }
    }

    .remove__btn {
      position: absolute;
      visibility: hidden;
      top: 5px;
      right: 5px;
      width: 16px;
      height: 16px;
      path {
        fill: $inactive;
      }
    }
  }
}
//.user-form {
//  .form-label {
//    margin-right: 10px;
//    min-width: 120px;
//    max-width: 120px;
//    font-family: "Roboto-Regular";
//    font-weight: 400;
//    color: $inactive;
//  }
//  input {
//    border: 1px solid transparent;
//    border-radius: 0.25rem;
//    padding: 3px 6px;
//    max-width: 100%;
//    &::placeholder {
//      color: $inactive;
//    }
//    &:hover {
//      border-color: #80bdff;
//    }
//  }
//  .form-group {
//    display: flex;
//    align-items: center;
//  }
//  .footer-form.form-group {
//    margin-bottom: 10px;
//  }
//  .auto-resize-wrapper {
//    max-width: 100%;
//    width: 100%;
//  }
//  .invalid-feedback {
//    display: block;
//  }
//  .color-item {
//    width: 100%;
//  }
//  .input-item {
//    max-width: 50%;
//  }
//}
