.icon-button-material{
  border: none;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  svg{
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      fill: $primary;
    }
  }
  &:focus{
    outline: none;
  }
  &:hover{
    //background-color: #f9f9f9;
  }
}