.accounting-block {
  padding: 1rem;
  margin-top: 10px;
  background-color: $secondary_one;

  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }

  .deadline-value{
    font-size: $accompanying_text_size;
    color: $inactive;
    svg{
      margin-right: 5px;
    }
    margin-right: 10px;
  }

  .deadline-value {
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: $inactive;
      }
    }
  }

  .form-line-format {
    .form-group {
      label {
        align-self: flex-start;
      }
    }
  }

  .title-accounting {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .name-block {
      display: flex;
      justify-content: flex-start;
      p {
        margin: 0;
      }
      .title {
        font-size: $subtitle_size;
        color: $inactive;
      }
    }
    .action-accounting {
      display: flex;
      .with-icon {
        font-size: $normal_text_size;
        &.complete {
          color: green;
          &:hover {
            color: $secondary_one;
            svg {
              path {
                fill: $secondary_one;
              }
            }
          }
          svg {
            path {
              fill: green;
            }
          }
        }
        margin-left: 15px;
        &:hover {
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
    }
  }
}

.margin-left-center {
  margin: auto;
  padding-left: 10px;
  background-color: transparent;
}
