.wrap-table-with-details-panel {
  display: flex;
  //&.po-wrapper{
  //  height: calc(100vh - 100px);
  //  .po-table{
  //    height: 100%;
  //  }
  //}
  .table-block {
    flex: 1;
    overflow-x: auto;
    //overflow: scroll;
  }
  .details {
    flex: 1;
    background-color: $grey;
  }
  &.with-details {
    .table-block {
      table th,
      table td {
        padding: 10px;
      }

      + .tasks-details,
      + .suppliers-details {
        padding: 10px;
        position: relative;
        .close-details-block {
          position: absolute;
          right: -4px;
          top: -4px;
          cursor: pointer;
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: $inactive;
            }
          }
        }
      }
      + .customer-details {
        padding: 10px;
        position: relative;
        .close-details-block {
          position: absolute;
          right: -4px;
          top: -4px;
          cursor: pointer;
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: $inactive;
            }
          }
        }
      }
      + .service-desk-details {
        padding: 10px;
        position: relative;
        display: flex;
        .content-details {
          padding: 0;
          .service-desk-form {
            margin-bottom: 10px;
          }
        }
        .close-details-block {
          position: absolute;
          right: -4px;
          top: -4px;
          cursor: pointer;
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: $inactive;
            }
          }
        }
      }
      //+.details {
      //  .close-details-block {
      //    position: absolute;
      //    left: -2px;
      //    top: -2px;
      //    right: initial;
      //  }
      //  .content-details {
      //    padding: 10px;
      //    .transport-chronology {
      //      margin-top: 10px;
      //    }
      //  }
      //}

      &.po-table {
        overflow-y: hidden;
        max-width: 290px;
        //height: calc(100vh - 100px);

        .flat-table {
          flex-wrap: wrap;
          flex-direction: row;
          .purchase-order-toolbar {
            + div {
              height: calc(100% - 180px);
            }
          }
        }
        .add-btn {
          width: 80px;
        }
        .filters-list-wrapper {
          > .with-icon {
            max-width: 82px;
          }
        }
        + .details {
          max-width: calc(100% - 290px);
          .wrapper-bids-table {
            .table-responsive {
              overflow-x: auto;
              padding-bottom: 25px;
            }
          }
          .close-details-block {
            position: absolute;
            right: -4px;
            top: -4px;
            svg {
              width: 18px;
              height: 18px;
            }
          }
          .content-details {
            padding: 10px;
            .transport-chronology {
              margin-top: 10px;
            }
            .transport-block {
              margin-bottom: 10px;
            }
            .inbound-wrapper {
              margin-bottom: 10px;
            }
            .followers-block {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  cursor: pointer;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 123, 255, 0.25);
  cursor: pointer;
  border: 0 none #ffffff;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgba(0, 123, 255, 0.25);
}

::-webkit-scrollbar-thumb:active {
  cursor: pointer;
  background: rgba(0, 123, 255, 0.25);
}

::-webkit-scrollbar-track {
  cursor: pointer;
  background: #ffffff;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  cursor: pointer;
  background: #ffffff;
}

::-webkit-scrollbar-track:active {
  background: #ffffff;
}

::-webkit-scrollbar-corner {
  cursor: pointer;
  background: transparent;
}
