.wrapper-related-params{
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  margin-bottom: 15px;
  +.error-label{
    color: #dc3545;
    font-size: $accompanying_text_size;
  }
  .related-row{
    display: flex;
    align-items: flex-start;
    &:hover{
      .remove-row{
        visibility: visible;
      }
    }
    .form-group{
      width: 20%;
      margin-right: 10px;
      align-self: flex-start;
    }
    .remove-row{
      //margin-bottom: 10px;
      cursor: pointer;
      visibility: hidden;
      align-self: flex-start;
      margin-top: 5px;
      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: $inactive;
        }
      }
    }
  }
}
