.transport-block {
  padding: 1rem;
  margin-bottom: 10px;
  background-color: $secondary_one;

  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .bootstrap-class{
    div[class*=control]{
      .supplier-address-line{
        svg{
          display: none;
        }
      }
    }
  }

  .deadline-value{
    font-size: $accompanying_text_size;
    color: $inactive;
    svg{
      margin-right: 5px;
    }
    margin-right: 10px;
  }

  .deadline-value {
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: $inactive;
      }
    }
  }

  .supplier-address-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg{
      cursor: pointer;
    }
  }
  .wrapper-new-warehouse-address,
  .wrapper-edit-warehouse-address{
    margin-top: 10px;
    display: flex;
    .actions-address {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 55px;
      margin-left: 10px;
      svg {
        cursor: pointer;
        width: 16px;
        height: 16px;
        &:first-of-type {
          margin-right: 10px;
        }
        path {
          fill: $inactive;
        }
      }
    }
  }
  .form-element {
    position: relative;
    .transport-add-button {
      margin-top: 4px;
      position: absolute;
      right: 3px;
      //font-size: 10px;
      top: -2px;
      //border: none;
      &:hover {
        svg {
          path {
            fill: $secondary_one;
          }
        }
      }
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
    }
  }
  .form-line-format {
    .form-group {
      label {
        align-self: flex-start;
      }
    }
  }

  .title-transport {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .name-block {
      display: flex;
      justify-content: flex-start;
      p {
        margin: 0;
      }
      .title {
        font-size: $subtitle_size;
        color: $inactive;
      }
      .ref-id {
        font-size: $accompanying_text_size;
        b {
          margin-left: 5px;
        }
      }
    }
    .action-transport {
      display: flex;
      .with-icon {
        font-size: $normal_text_size;
        &.complete {
          color: green;
          &:hover {
            color: $secondary_one;
            svg {
              path {
                fill: $secondary_one;
              }
            }
          }
          svg {
            path {
              fill: green;
            }
          }
        }
        margin-left: 15px;
        &:hover {
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
    }
  }

  .transport-info-view-wrapper {
    .row-form {
    }
  }
  .transport-input-file {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
  // .file-link {
  //   margin-left: 5px;
  //   cursor: pointer;
  //   display: flex;
  //   align-items: center;
  //   color: #3498db;
  //   svg {
  //     width: 16px;
  //     height: 16px;
  //     path {
  //       fill: #b0bec5;
  //     }
  //   }
    
  // }
}


.margin-left-center {
  margin: auto;
  padding-left: 10px;
  background-color: transparent;
}
