@import "../../admin/adminStyle/variable.scss";

.pagination {
  display: flex;
  font-family: Roboto-light;
  padding: 5px 0;
  position: relative;
  z-index: 50;
  .limit-list {
    align-self: center;
    position: relative;
    z-index: 1;
    ul {
      width: 100%;
      position: absolute;
      z-index: 50;
      top: 100%;
      margin: 0;
      padding: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      list-style-type: none;
      background-color: $secondary_one;
      //box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      //  0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      box-shadow: none;
      border: 1px solid $inactive;
      border-radius: $border-radius;
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        min-height: 24px;
        line-height: 1;
        font-size: $normal_text_size;
        &:hover {
          background-color: rgba(0, 0, 0, 0.14);
        }
      }
    }
    .page-size-btn {
      box-shadow: none;
      background-color: transparent;
      border: none;
      svg {
        width: $action_icon_size;
        height: $action_icon_size;
        path {
          fill: $primary;
        }
      }
      &:focus {
        background-color: rgba(0, 0, 0, 0.05);
        outline: none;
      }
    }
  }
  .pagination__actions {
    display: flex;
    flex-wrap: nowrap;
    .pagination__info {
      //align-self: flex-start;
      margin: 0 10px;
      display: flex;
      align-items: center;
    }
    .btn-pagination {
      cursor: pointer;
      background-color: transparent;
      border: none;
      &:disabled {
        svg {
          path {
            fill: $inactive;
          }
        }
      }
      &:focus {
        outline: none;
      }
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}
