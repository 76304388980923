.history-task-wrapper{
  .history-task-action{
    display: flex;
    .name-user{
      color: $accent;
      margin-right: 5px;
    }
    .edit-task{
      cursor: pointer;
      color: $inactive;
      font-weight: bold;
    }
    .date-action{
      align-self: center;
      margin-left: auto;
      font-size: $accompanying_text_size;
      color: $inactive;
    }
  }
}