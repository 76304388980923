.wrapper-department-column{
  display: inline-flex;
  border: 1px solid transparent;
  border-radius: 5px;
  &:hover:not(.disabled):not(.bulk){
    border: 1px solid $accent;
  }

  &.disabled .departament-flex, &.disabled .initials-button{
    cursor: auto;
  }
}
.responsible-wrapper{
  color: $primary;
  position: relative;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  .initials-button{
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    font-family: Roboto-medium;
    outline: none;
    font-size: $subtitle_size;
    font-weight: bold;
  }
  .list-users-responsible{

    z-index: 1000;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: white;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    max-height: 150px;
    overflow: auto;
    left: 0%;
    min-width: 150px;
    .search-li{
      .form-control{

        padding: 2px 8px;
        height: auto;
        font-size: $normal_text_size;
      }
    }
    &.hide{
      display: none;
    }
    li{
      cursor: pointer;
      padding: 5px 10px;
      transition: 0.25ms linear;
      &.selected-li{
        background-color: #e2e2e2;
      }
      &:hover{
        background-color: #e2e2e2;
      }
    }
  }
}

.wrap-table-with-details-panel{
  min-height: calc(100vh - 70px);
  .flat-table{
    display: flex;
    flex-direction: column;
    height: 100%;
    >div[style*=overflow-x]{
      height: 100%;
    }
    .table-cell-th {
      text-transform: initial;
    }
  }
}

.departament-flex{
  display: flex;
  align-items: center;
  .main-info-icon{
    svg{
      width: 19px !important;
      height: 19px !important;
    }
  }
}

.status-placement-date-wrapper{
  display: flex;
  align-items: center;
}
.icons-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .alert-palcement-date{
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      fill: red;
    }
  }
}
.icons-cell-item {
  min-width: 20px;
  min-height: 20px;
  .icon-button-material {
    width: 20px;
    height: 20px;
    padding: 0;
  }
}
