
.toolbar-suppliers{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  .filters-list-wrapper{
    display: inline-flex;
    .filter-button{
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .filters-list{
      left: 0;
    }
  }

  .actions-suppliers-toolbar{
    width: 100%;
    display: flex;
    align-items: center;
    .add-button-suppliers{
      margin-right: 10px;
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }
  }
  .pagination-toolbar{

    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}
.wrap-table-with-details-panel {
    .table-block.wrapper-table {
      table {
        th {
          background-color: #fff;
          position: sticky;
        }
      }
    }
}