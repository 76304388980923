
.filters-list-wrapper {
  position: relative;
  margin-left: 0px;
  color: $primary;
  .filter-button {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    transition: all .15s;
    padding: 0.25rem 0.5rem;
    display: inline-flex;
    color: $primary;
    svg{
      width: 25px;
      height: 16px;
      margin-right: 5px;
      path{
        // fill: $inactive;
      }
    }
    &:hover {
      background-color: $primary;
      color: $secondary_one;
    }
    &:focus {
      outline: none;
    }
  }
  .filters-list {
    overflow: hidden;
    list-style-type: none;
    background-color: $secondary_one;
    min-width: 100%;
    width: 200px;
    visibility: hidden;
    position: absolute;
    //left: -25px;
    left: 0;
    //top: 25px;
    top: 30px;
    padding: 0;
    margin: 0;
    border-radius: $border-radius;
    border: 1px solid $inactive;
    z-index: 100;
    &.open {
      visibility: visible;
    }
    .deletable-li{
      border-top: 1px solid $inactive;
    }
    li {
      width: 100%;
      display: inline-flex;
      padding: 0.5rem 0.5rem 0.5rem 24px;
      white-space: pre-wrap;
      cursor: pointer;
      justify-content: space-between;
      position: relative;
      .actions-filter{
        float: right;
        width: 36px;
        .filter-button-action{
          padding: 0;
          margin: 0;
          border: none;
          box-shadow: none;
          background-color: transparent;
          &:focus{
            outline: none;
          }
        }
      }
      svg{
        width: 16px;
        height: 16px;
        &.selected-filter-icon{
          position: absolute;
          top: 8px;
          left: 5px;
          path{
            fill: $accent;
          }
        }
        path{
          fill: $inactive;
        }
      }

      &:hover {
        background-color: $primary;
        color: $secondary_one;
      }

      &.add-new-filter{
        padding-left: 4px;
        justify-content: flex-start;
        // border-top: 1px solid $inactive;
        &:hover{
          svg{
            path{
              // fill: $secondary_one;
            }
          }
        }
        svg{
          margin-right: 5px;
          width: 25px;
          height: 16px;
          path{
            // fill: $primary;
          }
        }
      }
    }
  }
}