$grey : #f9f9f9;
$btn_blue : #007bff;
$btn_grey: #4a5664;
$ligh_grey : #f8f9fa;
$text_color: #212121;
$light_blue : #55c1e9;


// colors
$primary: #616161;
$secondary_one: #ffffff;
$secondary_two: #f2f2f2;
$accent: #3498DB;
$inactive: #B0BEC5;
$grey: #f2f2f2;
$border_hover: #80bdff;
$light_accent: rgba(0, 123, 255, 0.25);

// fontSize

$title_size: 18px;
$subtitle_size: 14px;
$normal_text_size: 12px;
$accompanying_text_size: 10px;

// icons
$action_icon_size: 20px;
$menu_icon_size: 32px;

// line-height
$line-height-body: 1.25;

//borders
$border-radius: 5px;
$border-width: 1px;

//fonts family






