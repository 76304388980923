.articles-dictionary__wrapper{
  //max-width: 700px;
  margin-top: 5px;
  position: relative;
  .preloader-wrap{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 100px)!important;
    z-index: 10;
    background-color: rgba(255,255,255,0.7);
  }
  .aritcles-dictionary__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .with-icon{
      align-self: center;
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }
    .pagination{
      display: flex;
      font-family: Roboto-light;
      padding: 5px 0;
      .limit-list{
        align-self: center;
        position: relative;
        z-index: 1;
        ul{
          width: 100%;
          position: absolute;
          z-index: 50;
          top: 100%;
          margin: 0;
          padding: 0;
          padding-top: 8px;
          padding-bottom: 8px;
          list-style-type: none;
          background-color: $secondary_one;
          //box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: none;
          border: 1px solid $inactive;
          border-radius: $border-radius;

          li{
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            min-height: 24px;
            line-height: 1;
            font-size: $normal_text_size;
            &:hover{
              background-color: rgba(0, 0, 0, 0.14);
            }
          }
        }
        .page-size-btn{
          box-shadow: none;
          background-color: transparent;
          border: none;
          svg{
            width: $action_icon_size;
            height: $action_icon_size;
            path{
              fill: $primary;
            }
          }
          &:focus{
            background-color: rgba(0, 0, 0, 0.05);
            outline: none;
          }
        }
      }
      .pagination__actions{
        display: flex;
        flex-wrap: nowrap;
        .pagination__info{
          //align-self: flex-start;
          margin: 0 10px;
          display: flex;
          align-items: center;
        }
        .btn-pagination{
          cursor: pointer;
          background-color: transparent;
          border: none;
          &:disabled{
            svg{
              path{
                fill: $inactive;
              }
            }
          }
          &:focus{
            outline: none;
          }
          svg{
            path{
              fill: $primary;
            }
          }
        }
      }
    }
  }

  .list-articles{
    margin-top: 0;
    height: calc(100vh - 135px);
    overflow: auto;
    .article-row{
      margin-bottom: 10px;
      display: flex;
      border: 1px solid $inactive;
      border-radius: $border-radius;
      padding: 10px;
      font-family: Roboto-Regular;
      &.disable{
        .form-control{
          pointer-events: none;
          border: 1px solid transparent;
          &:hover{
            //border: 1px solid $accent;
          }
        }
        .actions{
          svg{
            visibility: hidden;
          }
        }
        &:hover{
          .actions{
            svg{
              visibility: visible;
            }
          }
        }
      }
      &.header_row{
        z-index: 10;
        border: none;
        font-family: Roboto-Regular;
        color: $inactive;
        background-color: white;
        position: sticky;
        top: 0;
        border-radius: 0;
        border-bottom: 2px solid $inactive;
        padding-top: 5px;
        .article-field {
          padding-left: 7px;
        }
      }
      .form-group{
        margin-bottom: 0;
      }
      .article-field{
        margin-right: 10px;
        &.ean{
          //width: 140px;
          flex: 1;
        }
        &.description{
          flex: 1;
        }

        &.ean, &.description{
          max-width: calc(50% - 45px);
        }
        &.actions{
          align-self: center;
          width: 60px;
          text-align: center;
          svg{
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            path{
              fill: $inactive;
            }
          }
        }
        &.article-field-custom {
          height: 32px;
          align-self: flex-start;
          display: flex;
          align-items: center;
        }
      }
    }
  }

}
.row-empty {
  text-align: center;
  font-family: Roboto-regular;
}