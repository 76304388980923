.icon-department{
  svg{
    width: $action_icon_size!important;
    height: $action_icon_size!important;
    path{
      //fill: $inactive;
    }
  }
}

.error-title-h{
  font-size: $normal_text_size;
}