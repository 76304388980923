.new-version-wrapper{
  font-size: $normal_text_size;
  border-radius: $border-radius;
  border: 1px solid $inactive;
  position: fixed;
  left: 50px;
  bottom: 50px;
  background-color: white;
  padding: 10px 15px;
  z-index: 10;
  .close-icon{
    display: none;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    path{
      fill: $inactive;
    }
  }
  .info-icon{
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: $accent;
    position: static;
    transform: none;
  }
}