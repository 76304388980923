.wrap-statuses{
  position: relative;
  .active-status{
    padding: 3px;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: $border-radius;
    &:hover{
      border: 1px solid $accent;
    }
    svg{
      width: $action_icon_size;
      height: $action_icon_size;
    }
  }
  &.open{
    .list-statuses{
      visibility: visible;
    }
  }
  .list-statuses{
    z-index: 1000;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: white;
    border: 1px solid #B0BEC5;
    border-radius: 5px;
    max-height: 150px;
    overflow: auto;
    left: 0%;
    min-width: 150px;
    visibility: hidden;
    li{

      cursor: pointer;
      padding: 5px 10px;
      transition: 0.25ms linear;
      &:hover{
        background-color: #e2e2e2;
      }
    }
  }
}