.all-component {
  display: flex;
  justify-content: space-between;
  border: 1px solid $inactive;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: $border-radius;
  &.active {
    //outline: 1px solid $accent;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .name {
    font-family: Roboto-medium;
  }
  .info-all-component {
    .total-rrp {
      margin-right: 10px;
    }
    .items {
      svg {
        width: 16px;
        height: 20px;
        margin-right: 3px;
        margin-top: -3px;
        path {
          fill: $inactive;
        }
      }
    }
  }
}
