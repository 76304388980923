.profile {
    display: flex;
    justify-content: center;
    max-height: calc(100vh - 70px);
    background-color: #f2f2f2;
    padding: 10px;
    .content-details {
        background-color: #fff;
        padding: 15px;
        width: 100%;
        display: flex;
    }
    form {
        //flex-grow: 1;
        flex: 5;
        padding-right: 10px;
    }
    .user-notifications {
        //flex-grow: 1;
        flex: 4;
        padding-left: 10px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .header-notifications {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
    }
    .notifications-list {
        overflow: auto;
    }
    .footer-notifications {
        margin-top: 10px;
    }
}
