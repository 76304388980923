@import '../../adminStyle/variable.scss';
.history-customer-wrapper {
  background-color: $secondary_two;
  padding: 10px 0;
  .form-title {
    height: 24px;
  }
  .history-customer-action {
    padding: 10px 16px;
    background: #fff;
    .date-action {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 10px;
      color: #b0bec5;
      padding: 10px 0;
      text-align: center;
      border-top: 1px solid #f4f4f4;
    }
    .action-name-block {
      display: grid;
      grid-template-columns: auto 30px;
      .action-name {
        margin-right: 5px;
      }
      .name-user {
        color: $accent;
        margin-right: 5px;
        white-space: nowrap;
      }
      .edit-customer {
        cursor: pointer;
        color: $inactive;
        font-weight: bold;
      }
      .time-action {
        align-self: flex-start;
        white-space: nowrap;
        //align-self: center;
        font-size: 10px;
        color: #b0bec5;
        text-align: center;
        margin-top: 2px;
        margin-left: auto;
        font-size: $accompanying_text_size;
        color: $inactive;
      }
    }
  }
}
