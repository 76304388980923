@import './variable';

.auto-resize-wrapper {
  max-width: calc(100% - 10px);
  position: relative;
  &.textarea,
  & input {
    background-color: transparent;
  }
  //padding: 4px;
  input {
    min-height: 24px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 3px 6px;
    max-width: calc(100% - 10px);
    &::placeholder {
      color: $inactive;
    }
    &:hover:not(:disabled) {
      border-color: #80bdff;
    }
    &:disabled::placeholder {
      display: none !important;
    }

    &:disabled {
      color: $primary;
      -webkit-text-fill-color: $primary;
      opacity: 1; /* required on iOS */
    }
  }
  input:focus {
    outline: none;
    border: 1px solid $border_hover;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.standard-label {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  color: $inactive;
}

.form-group.horizontal {
  display: flex;
  align-items: flex-start;

  .form-label {
    margin-right: 10px;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    color: $inactive;
    margin-top: 8px;
    //margin-top: 11px;

    &.with-select {
      margin-top: 7px;
      //margin-top: 9px;
    }

    &.with-multiple-select {
      margin-top: 8px;
      //margin-top: 10px;
    }
  }

  .input-item {
    //overflow: hidden; /* for box-shadow*/
    width: 100%;

    &.with-input {
      overflow: hidden;

      .auto-resize-wrapper {
        padding: 3px;
        input {
          min-height: 18px;
        }
      }
    }

    //.bootstrap-class{
    //  margin: 3px;
    //}
    .auto-resize-wrapper {
      max-width: calc(100% - 10px);
      position: relative;
      input {
        //min-height: 18px;
        //margin: 3px;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
        min-height: 24px;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        padding: 3px 6px;
        max-width: calc(100% - 10px);
        max-width: 130px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &::placeholder {
          color: $inactive;
        }
        &:hover:not(:disabled) {
          border-color: #80bdff;
        }
        &:disabled::placeholder {
          display: none !important;
        }
        &:disabled {
          color: $primary;
          -webkit-text-fill-color: $primary;
        }
      }

      input:focus {
        outline: none;
        border: 1px solid $border_hover;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }

    textarea.auto-resize-wrapper {
      min-height: 24px;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      padding: 8px 6px;
      max-width: 100%;
      overflow: visible;
      //margin: 3px;

      &::placeholder {
        color: $inactive;
      }
      &:disabled {
        color: $primary;
        -webkit-text-fill-color: $primary;
      }
      &:hover:not(:disabled) {
        border-color: #80bdff;
      }

      &:focus {
        outline: none;
        border: 1px solid $border_hover;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }

    .error {
      color: red;
      font-size: $accompanying_text_size;
    }

    .error-label {
      color: #dc3545;
      font-size: 80%;
      //margin-left: 7px;
    }
    &.color-item {
      width: 100%;
    }

    .wrapper-calendar-asana {
      max-width: 170px;

      &:hover {
        border: 1px solid $border_hover;
      }

      input {
        font-family: Roboto-light;
        font-size: $normal_text_size;
        color: $primary;
      }
    }
  }
}

.user-invite-form {
  .form-label {
    min-width: 65px;
    max-width: 65px;
  }

  .input-item {
    max-width: calc(100% - 65px);
  }

  .form-group.last {
    margin-bottom: 1rem;
  }
}

.user-form {
  .form-label {
    min-width: 120px;
    max-width: 120px;
  }

  .input-item {
    max-width: calc(100% - 124px);
  }
}

.user-profile-form {
  .form-label {
    min-width: 120px;
    max-width: 120px;
  }

  .input-item {
    max-width: calc(100% - 120px);
  }
}

.third-step,
.edit-inventory {
  .form-label {
    min-width: 90px;
    max-width: 90px;
  }

  .input-item {
    max-width: calc(100% - 80px);
  }
}

.customer-form {
  .form-group.horizontal {
    .form-label {
      min-width: 90px;
      max-width: 90px;
      margin-top: 9px;
    }
  }

  .input-item {
    max-width: calc(100% - 94px);
  }
}

.supplier-form {
  .form-group.horizontal {
    .form-label {
      min-width: 90px;
      max-width: 90px;
      margin-top: 9px;
    }
  }

  .input-item {
    max-width: calc(100% - 94px);
  }
}

.workspaces__form,
.cg1-form {
  .form-group.horizontal {
    .form-label {
      min-width: 60px;
      max-width: 60px;
      margin-top: 7px;
    }
  }

  .input-item {
    max-width: calc(100% - 64px);
  }
}

.cg1-form {
  .form-group.horizontal {
    .input-item {
      .auto-resize-wrapper.special {
        max-width: calc(100% - 46px);
      }
    }
  }
}

.task-template-form {
  .form-group.horizontal {
    .form-label {
      min-width: 80px;
      max-width: 80px;
      margin-top: 7px;
    }
  }

  .input-item {
    max-width: calc(100% - 84px);
    textarea.auto-resize-wrapper {
      padding: 6px !important;
    }

    &.with-switch {
      padding-top: 10px;
    }
  }
}

.cg3-form {
  .form-group.horizontal {
    .form-label {
      min-width: 70px;
      max-width: 70px;
      margin-top: 9px;

      &.with-select {
        margin-top: 7px;
      }
    }
  }

  .input-item {
    max-width: calc(100% - 74px);
  }
}

.size-set-form {
  .brand-col {
    max-width: calc(33% - 20px);
  }
}

.size-type.auto-resize-wrapper {
  max-width: 70px;
  overflow: visible;

  &.with-error input {
    border: 1px solid #dc3545;
    border-radius: 0.25rem;

    &:focus,
    &:hover {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.offer-second-step-form {
  .form-group.horizontal {
    .form-label {
      min-width: 70px;
      max-width: 70px;
      margin-top: 7px;

      &.with-select {
        margin-top: 7px;
      }
    }
  }

  .input-item {
    max-width: calc(100% - 74px);
  }
}

.service-desk-form {
  .form-group.horizontal {
    .form-label {
      min-width: 90px;
      max-width: 90px;
      margin-top: 6px;

      &.with-select {
        margin-top: 4px;
      }
    }
  }

  .input-item {
    max-width: calc(100% - 84px);

    textarea.auto-resize-wrapper {
      padding: 6px !important;
    }
  }
}

.tasks-form {
  .form-group.horizontal {
    .form-label {
      min-width: 90px;
      max-width: 90px;
      margin-top: 9px;

      &.with-select {
        margin-top: 7px;
      }
    }
  }

  .input-item {
    max-width: calc(100% - 94px);
  }
}

.mapDictionaryForm {
  .form-group.horizontal {
    .form-label {
      min-width: 90px;
      max-width: 90px;
      margin-top: 7px;

      &.with-select {
        margin-top: 9px;
      }
    }
  }

  .input-item {
    max-width: calc(100% - 94px);
  }
}

.warn-text {
  color: #ff9800;
}

.no-articles {
  font-size: $normal_text_size;
  text-align: center;
}

.standard-svg {
  width: 20px;
  height: 20px;
  path {
    fill: $inactive;
  }
}

.button-svg {
  width: 16px;
  height: 16px;
  path {
    fill: $inactive;
  }
}
