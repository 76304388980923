@import '../../../adminStyle/variable';

.inbounding-report__wrapper {
  display: flex;
  height: 100%;
  padding-bottom: 10px;
  font-size: $normal_text_size;
  flex-wrap: wrap;
  position: relative;
  > .preloader-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
  }
  .inbound-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    .btn {
      svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path {
          fill: $inactive;
        }
      }
    }
  }
  .packages__wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: $secondary_one;
    width: 30%;
    max-width: 350px;
    min-width: 220px;
    height: calc(100% - 55px);
    border-radius: $border-radius;
    .packages__outer {
      padding: 0 10px;
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 10;
    }
    .package__row {
      position: relative;
      #active {
        display: flex !important;
      }
      .actions-dropdown-menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .icon-button {
          .fa {
            color: #616161;
          }
        }
      }

      .package__content {
        display: flex;
        padding: 10px 10px 10px 5px;
        .package-icon {
          text-align: center;
          margin-right: 0;
        }
      }
      .package__cell {
        flex: 1;
        text-align: center;
        align-self: center;
        &:first-of-type {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        &.package__cell__add {
          .btn {
            margin-left: 10px;
            svg {
              margin-right: 5px;
            }
          }
        }
        &-custom {
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            margin-left: 10px;
            cursor: pointer;
            path {
              fill: $inactive;
            }
          }
        }
        &:first-child {
          flex: 3;
          text-align: left;
        }
        .inactive {
          color: #adadad;
        }
      }

      &.is-searched {
        .package__name {
          background-color: rgba(0, 123, 255, 0.25);
        }
        .package__content {
          //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        }
      }

      &.row__header {
        border-bottom: $border-width solid $inactive;
        //padding: 10px 0;
        padding: 10px 0;
        background-color: #fff;
      }
    }

    .package--info {
      // &::-webkit-scrollbar {
      //   width: 0;
      // }
      padding: 0 10px;
      .edit-package-icon {
        width: 16px;
        height: 16px;
        // position: absolute;
        top: calc(50% - 8px);
        margin-left: 10px;
        path {
          fill: $inactive;
        }
      }
      .delete-package-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        left: 38px;
        path {
          fill: $inactive;
        }
      }
      .package__content {
        padding: 10px 10px 10px 5px;
      }
      .package__row {
        position: relative;

        &.custom-package__row {
          .package__cell:first-of-type {
            display: flex;
            flex-wrap: wrap;
            .package__name {
              color: $accent;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
            .package__description {
              width: 100%;
              text-overflow: ellipsis;
              white-space: pre-wrap;
              //max-width: 126px;
              margin-top: 2px;
              color: $inactive;
              font-size: $accompanying_text_size;
              overflow: hidden;
            }
          }
        }
        &:hover {
          cursor: pointer;
          background-color: $secondary_two;
          .edit-package-icon,
          .delete-package-icon {
            visibility: visible;
          }
        }

        &.active {
          background-color: $secondary_two;
        }
      }
      .package__cell:not(:first-child) {
        text-align: center;
      }
    }

    .packages__bottom {
      padding: 0 10px;
      background-color: #fff;
      position: sticky;
      bottom: 0;
      margin-top: auto;
      z-index: 10;
    }

    .package--additional-info {
      border-top: $border-width solid $inactive;
      bottom: 0;
      padding-bottom: 10px;
      .package__row {
        // margin: 0 -20px;
        //padding: 10px 20px;
        &:hover {
          cursor: pointer;
          background-color: $secondary_two;
        }

        &.active {
          background-color: $secondary_two;
        }
        &:first-child {
        }
        .package__content {
          padding: 10px 10px 10px 5px;
        }
      }
    }
  }
  .package-custom_wrapper {
    margin-top: auto;
    // .packages__outer {
    //   position: relative;
    //   top: initial;
    //   background-color: transparent;
    // }
    .package__row {
      position: relative;
      &:hover {
        .edit-package-icon,
        .delete-package-icon {
          visibility: visible;
        }
      }
    }
    .package__custom {
      margin-top: auto;
      .package--info {
        .package__cell {
          &:first-of-type {
            display: flex;
            flex-direction: column;
            .package__description {
              text-overflow: ellipsis;
              white-space: nowrap;
              //max-width: 126px;
              margin-top: 2px;
              color: $inactive;
              font-size: $accompanying_text_size;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .packages__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .boxes__wrapper {
    width: 70%;
    flex-grow: 1;
    height: calc(100% - 40px);
    overflow: auto;
    .box__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;

      &.pack__row {
        .toggle {
          width: 50px;
          &:hover {
            cursor: auto;
          }
        }
        .row__header {
          .info {
            cursor: auto;
            .right {
              .with-icon {
                svg {
                  width: 16px;
                  height: 16px;
                  margin-right: 5px;
                  path {
                    fill: $inactive;
                  }
                }
              }
            }
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .row__header {
        display: flex;
        width: 100%;
        min-height: 48px;
        .toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;

          svg {
            transition: transform 0.3s;
            path {
              fill: $inactive;
            }
          }

          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: $primary;
              }
            }
          }

          &.opened {
            svg {
              transform: rotate(90deg);
              path {
                fill: $primary;
              }
            }
          }
        }

        .info {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          background-color: $secondary_one;
          width: 100%;
          border-radius: 8px;
          cursor: pointer;
          border-radius: $border-radius;
          &.is-searched {
            //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
            strong {
              background-color: $light_accent;
            }
          }
          &.opened {
            border-radius: 8px 8px 0 0;
          }
          .left {
            margin-left: 10px;
            display: flex;
            align-items: center;
            strong {
              &.closed-box {
                color: $primary;
              }
              color: $accent;
              margin-left: 3px;
            }
            .package-icon {
              margin-right: 15px;
            }
          }

          .right {
            margin-right: 10px;
            display: flex;
            align-items: center;

            .btn {
              margin-right: 15px;
              &:disabled {
                opacity: 1;
                border: 1px solid transparent;
                color: $inactive;
                &:hover {
                  background-color: transparent;
                  border: 1px solid transparent;
                }
              }
            }

            .package-icon {
              margin-right: 25px;
            }

            .items-count {
              width: 40px;
            }
          }
        }
      }
      .row__details {
        min-height: 100px;
        display: flex;
        .preloader-wrap {
          max-height: 100px;
        }
        .details__line {
          width: 44px;
          display: flex;
          justify-content: center;
          padding-bottom: 15px;

          .line {
            width: 2px;
            margin-right: -1px;
            margin-top: -10px;
            border-left: 1px solid $inactive;
          }
        }
        .details__table {
          background-color: $secondary_one;
          width: 100%;
          padding: 0 10px;
          border-radius: 0 0 8px 8px;
          .table {
            margin-top: 10px;
            width: 100%;
            border-collapse: collapse;

            th {
              color: $inactive;
              background-color: $secondary_one;
              border-top: none;
              font-weight: 400;
            }

            td {
              vertical-align: middle;
              &.is-searched {
                span {
                  background-color: $light_accent;
                }
              }
            }

            .actions {
              display: flex;

              svg {
                cursor: pointer;
                width: 16px;
                height: 16px;

                path {
                  fill: $inactive;
                }
              }
            }
          }
        }
      }
    }
  }
}

.package-icon {
  width: $action_icon_size;
  min-height: $action_icon_size;
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
  }

  &.opened-box {
    svg {
      path {
        fill: $accent;
      }
    }
  }

  &.closed-box {
    svg {
      path {
        fill: $inactive;
      }
    }
  }

  &.items {
    svg {
      path {
        fill: $inactive;
      }
    }
  }
}

.inbound-report-search__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  width: 160px;
  align-items: flex-start;
  position: relative;
  .error {
    bottom: -15px;
    position: absolute;
    color: red;
    font-size: $accompanying_text_size;
  }
}

.printer__icon {
  width: $action_icon_size;
  height: $action_icon_size;
  margin-right: 5px;
  path {
    fill: $inactive;
  }
}
.custom-package-form {
  .form-control {
    &.is-invalid {
      background-image: none;
    }
  }
  .method-buttons-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    .header-tab-button {
      border-radius: 0;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      border-bottom: 2px solid $primary;
      padding-top: 0;
      margin-right: 10px;
      &:hover,
      &:active,
      &:focus {
        color: $primary !important;
        background: transparent !important;
      }
      &.cancel {
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
