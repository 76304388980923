.toolbar-analysis{
  display: flex;
  .buttons-bulk-edit{
    flex: 1;
  }
  .remove-button{
    //margin-left: 10px;
    svg{
      width: 20px;
      height: 20px;
      margin-right: 5px;
      path{
        fill: $inactive;
      }
    }
  }
  .btn.btn-primary.products-errors{
    color: red;
    margin-right: 10px;
    svg{
      width: 20px;
      height: 20px;
      margin-right: 5px;
      path{
        fill: red;
      }
    }
  }
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}