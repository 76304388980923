.collapse-set-item{
  //border: 1px solid $inactive;
  //border-left: 1px solid $inactive;
  //border-right: 1px solid $inactive;
  .collapse,.collapsing{
    border: 1px solid $inactive;
  }
  .content-collapse{
    padding: 10px;
    table.table{
      thead{
        th{
          background-color: transparent;
        }
      }

    }
  }
}