.modal{
  color: $primary;
}

.wrap-text{
  font-size: $normal_text_size;
}

.text-to-repeat-for-delete{
  color: $accent;
}


.footer-modal-delete{
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  .btn:first-of-type {
    margin-right: 0.25rem;
  }
}