@import './variable';
@import '../../login/style';
@import './details-purchase';
@import './filters';
@import './reuse-styles';
@import './defaul_styles';
@import './table-with-details';
@import '../../reuseComponent/Header/style';
@import '../../reuseComponent/Buttons/ButtonWithMaterialIcon/style.scss';
@import '../homepage/style.scss';
@import '../../reuseComponent/modal/style.scss';
@import '../suppliers/createEdit/style.scss';
@import '../purchaseOrders/list/styleResponsibleList.scss';
@import '../purchaseOrders/list/AsyncMultiSupplierFilter/style.scss';
@import '../purchaseOrders/details/info/history/style';
@import '../dictionaries/departments/style.scss';
@import '../purchaseOrders/details/info/bid-info/style.scss';
@import '../purchaseOrders/details/info/transport/transport.scss';
@import '../purchaseOrders/details/info/accounting/accounting.scss';
@import '../purchaseOrders/details/info/followers/style.scss';
@import '../purchaseOrders/products/analysis/style.scss';
@import '../purchaseOrders/products/style.scss';
@import '../purchaseOrders/products/dividing/style.scss';
@import '../dictionaries/style.scss';
@import '../dictionaries/cg3/style.scss';
@import '../purchaseOrders/products/analysis/analysis.scss';
@import '../roles/style.scss';
@import '../dictionaries/sizes/style.scss';
@import '../purchaseOrders/products/smart-filter/style.scss';
@import '../purchaseOrders/products/dividing/all-component-packages/style.scss';
@import '../tasks/style.scss';
@import '../tasks/inbox/Toolbar/style.scss';
@import '../purchaseOrders/details/info/tasks/style.scss';
@import '../purchaseOrders/list/ToolbarPo/style.scss';
@import '../purchaseOrders/list/statusComponent/style.scss';
@import '../purchaseOrders/list/Header/style.scss';
@import '../purchaseOrders/list/undo-component/style.scss';
@import '../users/style.scss';
@import '../purchaseOrders/createForm/style.scss';
@import '../purchaseOrders/createPurchaseOrder/style.scss';
@import '../../newVersionComponent/style.scss';
@import '../suppliers/style.scss';
@import '../activity/style.scss';
@import '../notifications/style.scss';
@import '../offers/style.scss';
@import '../../reuseComponent/smart-filter/style.scss';
@import '../serviceDesk/style.scss';
@import '../customers/style.scss';
@import '../serviceDesk/history/style.scss';
@import '../dictionaries/Articles/style.scss';
@import '../inventory/style.scss';
@import '../salesOrders/style.scss';
@import '../profileSettings/style.scss';

body {
  font-family: Roboto-Light;
  &.no-overflow {
    overflow: hidden;
  }
}

//*::-webkit-scrollbar-track{
//  //background-color: transparent;
//  //border: none;
//  //box-shadow: none;
//}

div.ql-snow * {
  font-size: inherit;
}

.wrapper-body {
  .wrap-table-with-details-panel {
    flex: 1;
  }
  .suppliers-wrap {
    height: calc(100vh - 70px);
  }
  &.disable-scroll {
    //overflow-y: hidden;
    overflow: auto;
    max-height: 100vh;
    .wrap-table-with-details-panel {
      //height: 100%;
      max-height: calc(100vh - 70px);
    }
    .content-details {
      max-height: calc(100vh - 70px);
      overflow-y: auto;
    }
  }
  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 14px 20px 0px 20px;
    width: calc(100% - 55px);
    //height: calc(100vh - 28px);
    // margin-left: 55px;
    margin: 0 auto;
    transition: all 0.2s ease-in-out;
  }
}

.footer-form {
  display: flex;
  justify-content: space-between;
  .btn {
    a {
      color: $primary;
      outline: 0;
      text-decoration: none;
    }
  }
}
.footer-form-custom {
  justify-content: flex-end;
  .btn {
    &:first-of-type {
      border: 1px solid transparent;
      &:hover {
        border-color: #b0bec5;
      }
    }
    &:nth-of-type(2) {
      margin-left: 0.25rem;
    }
  }
}
.modal-content {
  .modal-footer {
    .btn:first-of-type {
      border: 1px solid transparent;
      &:hover {
        border-color: #b0bec5;
      }
    }
  }
}

.center-col {
  margin: 0 auto;
}

.center-h {
  text-align: center;
  font-family: Roboto-light;
  font-size: $subtitle_size;
}
.left-h {
  font-family: Roboto-light;
  font-size: $subtitle_size;
}

.no-margin {
  margin: 0;
}

.menu-table-actions {
  li {
    a {
      padding: 2px 10px;
      display: flex;
      width: 100%;
      color: $text_color;
      font-size: $normal_text_size;
      &:hover {
        background-color: $ligh_grey;
        text-decoration: none;
      }
    }
  }
}

.btn-top-title-table {
  background-color: #55c1e9;
  margin-left: 15px;
  padding: 4px 12px;
  border: none;
  a {
    display: block;
    padding: 4px 12px;
    color: $primary;
  }
  &:hover {
    background-color: $primary !important;
    color: $secondary_one;
  }
}

.hover-container-icon {
  display: flex;
  padding-top: 4px;
  &.seminars-collapse {
    align-items: center;
  }
  .icon-button {
    visibility: hidden;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
    path {
      fill: #616161;
    }
  }
  &:hover {
    .icon-button {
      visibility: visible;
    }
  }
}

.left-p-none {
  padding-left: 0;
}

.without-border {
  border-bottom: none;
  .nav-link {
    &:focus {
      outline: none;
    }
  }
}

.rowWithColumnFlex {
  > * {
    display: flex;
    flex-direction: column;
  }
}

.icon-purchase-status {
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
  }
}

.modal-body {
  .preloader-wrap {
    height: auto;
  }
}

.table-image {
  max-width: 200px;
}

.form-group {
  .react-bootstrap-daterangepicker-container {
    display: block !important;
  }
}

.wrapper-table {
  table {
    font-size: $normal_text_size;
    color: $primary;
    th {
      position: relative;
      white-space: nowrap;
    }
    th,
    td {
      padding: 10px 20px 10px 10px;
      font-size: $normal_text_size;
    }
  }
  &.products-table {
    table {
      thead {
        th {
          white-space: nowrap;
        }
      }
    }
  }
}

.products-table {
  .flat-table {
    div[style*='overflow-x'] {
      overflow: auto !important;
    }
  }
}

.flat-table {
  .light-grey {
    color: rgba(0, 0, 0, 0.3);
  }

  //tr:hover{
  //  .light-grey{
  //    color: black;
  //  }
  //}
  > div[style*='overflow-x'] {
    // min-height: 535px;
    > div {
      width: 100%;
    }
  }
  td {
    padding: 10px 20px 10px 10px;
  }
  div[style*='overflow-y'] {
    overflow: visible !important;
  }
  div[style*='overflow-x'] {
    //overflow: visible!important;
  }
  thead {
    border-bottom: 2px solid $primary;
    th {
      background-color: transparent;
    }
  }
}

.table-cell-th {
  .select-filter-dropdown {
    padding: 0.5rem;
  }
  .multi-select-filter {
    min-width: 300px;
  }
  .dropdown {
    button:not(.btn) {
      padding: 0;
      background-color: transparent;
      border: none;
      svg {
        width: $action_icon_size;
        height: $action_icon_size;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.date-picker-input {
  position: relative;
  svg {
    left: 5px;
    width: $action_icon_size;
    height: $action_icon_size;
    position: absolute;
    top: 8px;
    path {
      fill: $inactive;
    }
  }
}

.search-field {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  border: none;
  background: $secondary_one;
  padding: 0 10px;
  color: $primary;
  font-size: $normal_text_size;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.read-more-button {
  border: none;
  color: $accent;
  padding: 0;
  //outline: 1px solid $inactive;
  &:focus {
    outline: none;
  }
}

.tabs-custom {
  border: 3px solid $secondary_one;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .nav-item {
    .nav-link {
      border: none;
      font-size: $normal_text_size;
      font-family: Roboto-light;
      //border: 1px solid $secondary_one;
      .content-tab {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab-name {
          //text-transform: uppercase;
          color: $primary;
        }
      }
      .stowing-tab {
        display: flex;
        flex-direction: row;
        & > span {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
    svg {
      width: $menu_icon_size;
      height: $menu_icon_size;
      path {
        fill: $inactive;
      }
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.wrap-divide-type {
  display: inline-flex;
  position: relative;
  ul {
    min-width: 100%;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    li {
      text-align: left;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: $secondary_two;
      }
    }
  }
  .divide-type {
    top: 100%;
    position: absolute;
    z-index: 10;
    display: none;
    background-color: white;
    &.active {
      display: block;
    }
  }
}

.items-per-location-input {
  margin: 0 5px;
  height: 24px;
  max-width: 50px;
  display: inline-block;
  font-size: $normal_text_size;
  border: none;
  background-color: white;
  color: $accent;
  //border-bottom: 1px solid $inactive;
  //border-radius: 0;
  padding: 2px 3px;
}

.divide-type-drp-button.btn.btn-primary {
  border: none;
  //border-bottom: 1px solid $inactive;
  color: $accent;
  //border-radius: 0;
  &:hover {
    color: $secondary_one;
    svg {
      path {
        fill: $secondary_one;
      }
    }
  }
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
    path {
      fill: $accent;
    }
  }
}

.divide-block-header {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.po-create-modal-body {
  max-width: 900px;
}

.po-crate-modal {
  .card {
    border: none;
    .card-body {
      padding: 0;
    }
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    padding: 0;
    //margin-bottom: 1rem;

    .nav-tabs {
      width: 100%;
      border: 2px solid $secondary_one;
      border-top: none;
      border-radius: 5px;
      .nav-link {
        border: none;
        padding: 0.5rem 1rem;
        position: relative;
        color: $primary;
        &.disabled {
          color: $inactive;
          svg {
            path {
              fill: $inactive;
            }
          }
        }
        &.active {
          background-color: $secondary_two;
          .tab-name {
            display: block;
          }
        }
      }
      .content-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          width: $action_icon_size;
          height: $action_icon_size;
          path {
            fill: $inactive;
          }
        }
        .tab-name {
          //display: none;
          font-size: $accompanying_text_size;
          //position: absolute;
          //bottom: -20px;
        }
      }
    }
  }
}

.nav-tabs {
  &.tabs-custom {
    .nav-item {
      .nav-link {
        &.active {
          background-color: transparent;
        }
      }
    }
  }
}

.material-style-custom {
  .css-1fhf3k1-control {
    background-color: transparent;
    color: $inactive;
  }
  .bootstrap-class.disabled > div[class*='control'] div[class*='singleValue'] {
    //color: $inactive;
    color: $primary;
  }
  .wrapper-table.po-table {
    th {
      .table-cell-th {
        // display: inline-flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        .filter-wrap {
          margin-right: 10px;
        }
      }
    }
  }
}

.supplier-title-link {
  word-break: break-all;
  text-decoration: none;
  color: $accent;
  &:hover {
    text-decoration: none;
    color: $accent;
  }
}

.po-table {
  .flat-table {
    .purchase-order-toolbar {
      + div {
        //display: flex;
        //flex-direction: row-reverse;
        > div {
          display: flex;
        }
      }
    }
  }
  th,
  td {
    * {
      word-break: break-all;
    }
  }
}

.invoice-title-icon {
  width: $action_icon_size;
  height: $action_icon_size;
  path {
    fill: $inactive;
  }
}

.bootstrap-class {
  &.with-add-button {
    > div[class*='control'] {
      padding-right: 60px;
    }
  }
  > div[class*='menu'] {
    z-index: 2;
  }
}

.bootstrap-class.disabled {
  svg {
    display: none;
  }
}

.custom-toolbar {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .actions-toolbar {
    .add-button {
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
    }
  }
  .pagination-toolbar {
    td {
      border-bottom: none;
      padding: 0 !important;
      .MuiTablePagination-toolbar {
        min-height: 0;
        height: auto;
      }
      button {
        &:focus {
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}

.po-create-supp-modal {
  max-width: 1000px;
  font-size: $normal_text_size;
}

.package-icon {
  width: 16px;
  height: 16px;
  margin-top: -3px;
  margin-right: 3px;
}

.MuiTable-root {
  .MuiTableCell-root {
    border-bottom: none;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    vertical-align: top;
  }
}

.p-right {
  text-align: right;
}

.details {
  .no-access-details {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}

.standart-svg {
  width: 16px;
  height: 16px;
  &.dropdown-icon {
    cursor: pointer;
    width: $action_icon_size;
    height: $action_icon_size;
    path {
      fill: $primary;
    }
  }
  &.filter-icon {
    cursor: pointer;
    width: $action_icon_size;
    height: $action_icon_size;
  }
  path {
    fill: $inactive;
  }
}

.form-control {
  &:disabled {
    background-color: transparent;
    color: $inactive;
  }
}

.inputs-wrapper {
  //border: 1px solid $inactive;
  //padding: 10px;
  //margin-bottom: 15px;
  .input-field-wrapper {
    display: flex;
    margin-bottom: 10px;

    &:hover {
      svg {
        visibility: visible;
      }
    }
    .field {
      display: flex;
      flex-direction: column;
      max-width: 366px;
      flex: 1;
      & + svg {
        width: $action_icon_size;
        height: $action_icon_size;
        margin-left: 10px;
        margin-top: 6px;
        cursor: pointer;
      }
    }
    svg {
      visibility: hidden;
    }
  }
}

.footer-form.form-group {
  margin-bottom: 0;
}

a {
  color: $accent;
  &:hover {
    color: $accent;
  }
}

.undo-button {
  background-color: #00a800 !important;
  color: white !important;

  &:focus, &:active, &:hover {
    background-color: #00c900 !important;
  }
}