@import "../../admin/adminStyle/variable.scss";

td {
  .offer-pic {
    max-width: 100px;
    object-fit: contain;
  }
}

.wrap-table-with-details-panel {
  &.with-details {
    .table-block.wrapper-table {
      &.offers-table {
        max-width: 600px;
      }
    }
  }
  .actions-offers-toolbar {
    display: flex;
    flex-wrap: nowrap;
    .add-button-offers {
      align-self: flex-start;
      margin-right: 10px;
    }
  }
  .offer-form {
    position: relative;
    .dividing-label {
      font-size: $accompanying_text_size;
    }
    .label-title {
      font-size: $normal_text_size;
      color: $inactive;
      margin: 0;
    }
    .preloader-wrap {
      z-index: 100;
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}


.wrap-table-with-details-panel{
  .offers-table{
    flex: 2;
  }
  .offers-details {
    position: relative;
    padding: 10px;
    flex: 3;
    max-height: calc(100vh - 65px);
    overflow-y: auto;
    .close-details-block{
      cursor: pointer;
      position: absolute;
      right: -3px;
      top: -3px;
    }
    .content-details{
      background-color: $secondary_one;
      height: 100%;
      padding: 10px;
      .offer-data-wrapper{
        display: flex;
        font-size: $normal_text_size;
        .pallets-wrapper{
          flex: 1;
          .pallet__row{
            margin: 0 0 15px 0 ;
          }
        }
        .offer-details-info{
          max-width: 300px;
          flex: 0;
          min-width: 200px;
          margin-right: 25px;
          .image-offer{
            img{
              max-width: 150px;
              object-fit: contain;
              border: 1px dashed $inactive;
              border-radius: $border-radius;
            }
          }
          .offer-row-info{
            margin: 10px 0;
            display: flex;
            align-items: flex-start;
            white-space: nowrap;
            strong{
              font-family: Roboto-medium;
              display: inline-block;
              min-width: 100px;
            }
            span{
              display: inline-block;
              max-width: calc(100% - 100px);
              word-break: break-word;
              white-space: normal;
            }
          }
        }
      }
      .offer-details-title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &>div:first-child{
          display: flex;
          justify-content: flex-start;
        }
        &>div:last-child{
          display: flex;
          justify-content: flex-end;
          .btn{
            margin-left: 5px;
          }
        }
        .btn{
          .standart-svg{
            margin-right: 5px;
          }
        }
      }
    }
    .form-control {
      border-color: transparent;
      &:hover {
        border-color: $border_hover;
      }
    }
  }
}

.offers-table {
  .flat-table > div[style*="overflow-x"] {
    min-height: 0;
  }
}

.offer-create-modal {
  max-width: 1200px;
}

.create-offer-wrapper,
.pallets-wrapper{
  .pallet__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 0;
    font-size: $normal_text_size;
    .row__header {
      display: flex;
      width: 100%;
      min-height: 30px;
      .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;

        svg {
          transition: transform 0.3s;
          path {
            fill: $inactive;
          }
        }

        &:hover {
          cursor: pointer;
          svg {
            path {
              fill: $primary;
            }
          }
        }

        &.opened {
          svg {
            transform: rotate(90deg);
            path {
              fill: $primary;
            }
          }
        }
      }
      .info__header {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        border: 1px solid $inactive;
        //background-color: $secondary_two;
        width: 100%;
        border-radius: $border-radius;
        cursor: pointer;
        &.opened {
          border-radius: $border-radius $border-radius 0 0;
          //border-bottom: none;
        }
      }
    }
    .row__content {
      min-height: 100px;
      display: flex;

      .details__line {
        width: 26px;
        display: flex;
        justify-content: center;
        padding-bottom: 15px;

        .line {
          width: 2px;
          //margin-right: -1px;
          //margin-top: -10px;
          border-left: 1px solid $inactive;
        }
      }
      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        background-color: #ffffff;
        width: 100%;
        padding: 5px;
        border-radius: 0 0 $border-radius $border-radius;
        border-left: 1px solid $inactive;
        border-bottom: 1px solid $inactive;
        border-right: 1px solid $inactive;
        .no-data-to-display {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  .pallet__row {
    .row__header {
      .info__header {
        justify-content: flex-start;
        align-items: center;
        .total-count {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 46px;
          .standart-svg {
            margin-right: 5px;
            width: 21px;
            height: 21px;
            flex-shrink: 0;
          }
        }
        .title__pallet {
          margin-left: 8px;
          flex: 1;
        }
        .actions {
          .btn {
            margin-right: 8px;
            width: 92px;
          }
        }
        .checkbox-pallet-wrapper {
          display: inline-flex;
          align-items: center;
          width: 24px;
          padding-left: 4px;
          .checkbox-material {
            width: 15px;
            height: 15px;
            padding: 0;
            .mdc-checkbox__background {
              left: 0px;
              top: 0px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .row__content {
      .content {
        position: relative;
        min-height: 100px;
        align-items: flex-start;
        .preloader-wrap {
          max-height: 100px;
        }
        .table {
          margin-bottom: 0;
          th {
            border-top: none;
            color: $inactive;
            font-family: Roboto-Regular;
            font-weight: 400;
          }
          td {
            color: $primary;
          }
          .checkbox-material {
            width: 15px;
            height: 15px;
            padding: 0;
            .mdc-checkbox__background {
              left: 0px;
              top: 0px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

.create-offer-wrapper {
  position: relative;
  .preloader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    height: 100% !important;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .btn-secondary {
    border: none;
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    .title-step {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $inactive;
      padding-bottom: 10px;
      .name-step {
        span {
          color: $accent;
        }
        strong {
          font-family: Roboto-medium;
          font-size: $normal_text_size;
        }
      }
      .actions {
        .btn {
          margin-left: 10px;
        }
      }
    }
    .content-step {
      //margin-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      > div {
        padding-top: 10px;
      }
    }

    &.first-step {  
      .sets-wrapper {
        flex: 1;
        border-right: 1px solid $inactive;
        padding-right: 10px;
        h3 {
            margin: 0;
            font-size: $subtitle_size;
            margin-bottom: 10px;
        }
        .set-item {
          background-color: $secondary_two;
          border: 1px solid $inactive;
          display: flex;
          flex-wrap: wrap;
          border-radius: $border-radius;
          margin-top: 10px;
          padding: 20px;
          position: relative;
          .delete-set-icon {
            display: none;
            cursor: pointer;
          }
          &:hover {
            .delete-set-icon {
              display: inline-block;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .footer-conditions {
            display: none;
          }
          &.selected {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

            .footer-conditions {
              display: block;
            }
          }
          .list-conditions {
            width: 100%;
            .offer-condition-row {
              display: flex;
              width: 100%;
              margin-bottom: 10px;
              font-size: $normal_text_size;
              &:hover {
                .action-condition {
                  visibility: visible;
                }
              }
              .offer-condition-col {
                flex: 1;
                margin-right: 10px;
              }
              .action-condition {
                visibility: hidden;
                align-self: center;
                cursor: pointer;
              }
            }
          }
          .footer-conditions {
            width: 100%;
          }
          .btn {
            background-color: $secondary_one;
            &:hover {
              background-color: $primary;
            }
          }
        }
      }
      .offer-pallets-wrapper {
        flex: 1;
        padding-left: 10px;
        .title-pallets {
          font-size: $normal_text_size;
          display: flex;
          align-items: center;
          > span {
            margin-right: 10px;
            strong {
              font-family: Roboto-medium;
            }
          }
          .actions {
            flex: 1;
            display: flex;
            justify-content: flex-end;
          }
        }

      }
    }

    &.second-step {
      .content-step {
        padding: 0 20%;
      }
      .offer-info-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .offer-info-col {
          max-width: 50%;

          &.col-with-file{
            max-width: 100%;
          }
          flex: 1;
          padding: 0 10px;
          .bootstrap-class {
            //border: 1px solid $inactive;
            border-radius: $border-radius;
            &.invalid-select {
              //border: 1px solid #dc3545;
            }
          }
          .invalid-feedback {
            font-size: $accompanying_text_size;
          }
          .offer-file-control {
            max-width: 50%;
            margin: 0 auto;
            .invalid-feedback {
              text-align: center;
            }
          }
          .file-wrapper {
            position: relative;
            font-size: $normal_text_size;
            max-width: 50%;
            margin: 0 auto;
            min-height: 100px;
            border-radius: $border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              object-fit: contain;
              & + .overlay {
                visibility: hidden;
              }
            }
            &:hover {
              img {
                & + .overlay {
                  visibility: visible;
                }
              }
              .overlay {
                background-color: rgba(0, 0, 0, 0.5);
                color: $secondary_one;

                svg {
                  path {
                    fill: $secondary_one;
                  }
                }
              }
            }
            .overlay {
              position: absolute;
              border-radius: $border-radius;
              top: 0;
              left: 0;
              min-height: 100px;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: 0.25s background;
            }
          }
          input[type="file"] {
            visibility: hidden;
            display: none;
          }
        }
      }
    }
  }
}
.MuiTableCell-body {
  &:hover {
    .wrapper-calendar-reserv {
      border: 1px solid $inactive;
      visibility: visible;
    }
  }
}
.wrapper-calendar-reserv {
  visibility: hidden;
  max-width: 140px;
  display: flex !important;
  align-items: center;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
  .react-bootstrap-daterangepicker-container {
    display: flex !important;
  }
  svg {
    width: 26px;
    height: 26px;
    margin-right: 5px;
    border: 1px dashed #b0bec5;
    padding: 5px;
    border-radius: 50%;
    path {
      fill: $inactive;
    }
  }
  .date-text {
    font-size: $accompanying_text_size;
    display: flex;
    align-items: center;
  }
}
.reservation-info {
  position: relative;
  display: flex;
  align-items: center;
  .show-on-hover-custom {
    flex-shrink: 0;
    path {
      fill: $inactive;
    }
  }
}


.toolbar-offers{
  flex-wrap: wrap;
}

.offers-details{
  max-width: 60%;

  .offer-details-info{
    max-width: 200px !important;
  }

  .pallets-wrapper{
    max-width: calc(100% - 225px);

    .pallet__row .row__content .content{
      overflow-x: auto;
    }
  }
}

.list-history {
  .list-history-title {
    .header-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    
    h6 {
      font-size: 12px;
      border-bottom: 1px solid #B0BEC5;
      color: #B0BEC5;
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  
  .history-item {
    padding: 10px 0px;
    .date-history-action {
      font-size: 10px;
      color: #B0BEC5;
      margin-top: 10px;
      margin-bottom: 15px;
      text-align: center;
    }

    .row-action {
      position: relative;
      padding-right: 30px;
      .user-name-action {
        color: #3498DB;
      }
      .action-name {
        margin-left: 10px;
        word-break: break-all;
        &.with-diff {
          cursor: pointer;
        }
      }
      .with-diff {
        color: #B0BEC5;
      }
      .time-action {
        font-size: 10px;
        color: #B0BEC5;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  

  .header-icon {
    margin-right: 5px;
    path {
      fill: #B0BEC5;
    }
  }
}