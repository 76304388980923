@import "../../../../../adminStyle/variable";

.add-transport-file {
  position: relative;
  color: $primary;
  display: inline-flex;
  align-self: center;
  align-items: center;
  .file-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .add-file {
    width: 20px;
    height: 20px;
    cursor: pointer;
    path {
      fill: #b0bec5;
    }
  }
  .files-list {
    overflow: hidden;
    list-style-type: none;
    background-color: $secondary_one;
    min-width: 100%;
    width: 208px;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 30px;
    padding: 0;
    margin: 0;
    border-radius: $border-radius;
    border: 1px solid $inactive;
    z-index: 100;
    &.open {
      visibility: visible;
    }
    li {
      width: 100%;
      display: inline-flex;
      padding: 0.5rem 0.5rem 0.5rem 4px;
      white-space: pre-wrap;
      cursor: pointer;
      justify-content: space-between;
      position: relative;
      .icon {
        visibility: hidden;
      }
      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        path {
          fill: $inactive;
        }
      }
      a {
        color: #3498db;
        width: 90%;
        display: flex;
        align-items: center;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
        }
        .file-icon {
          margin-right: 5px;
          flex-shrink: 0;
        }
      }

      &:hover {
        .icon {
          visibility: visible;
        }
      }

      &.add-new-files {
        padding-left: 4px;
        justify-content: flex-start;
        border-top: 1px solid $inactive;
        &.without-border {
          border-color: transparent;
        }
        &:hover {
          background-color: $primary;
          color: $secondary_one;
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}

.count-files {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 15px;
  height: 15px;
  background-color: $inactive;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.doc-icon {
  width: 23px;
  height: 23px;
  cursor: pointer;
}