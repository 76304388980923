.wrap-comment {
  //padding: 0 10px;
  margin-top: 5px;
  &.with-border {
    border: 1px solid $inactive;
    height: 150px;
  }
  //height: 150px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  #toolbar {
    transition: all 0.4s;
    border: none;
  }
  .quill {
    background-color: white;
    transition: all 0.4s;
    //max-height: 100px;
    .ql-container.ql-snow {
      border: none;
    }
    .ql-editor.ql-blank::before {
      font-style: unset;
    }
    .ql-editor {
      word-break: break-all;
      padding: 6px 7px;
      overflow-y: initial;
    }
    .ql-mention-list {
      padding: 0;
      border: 1px solid $inactive;
      border-radius: $border-radius;
      background-color: $secondary_one;
      max-height: 150px;
      overflow-y: auto;
      list-style-type: none;
      li {
        color: $primary;
        text-align: left;
        cursor: pointer;
        padding: 5px 10px;
        transition: 0.25ms linear;
        position: relative;
        svg {
          position: absolute;
          right: 4px;
          top: 0;
          path {
            fill: $accent;
          }
        }
        &.padding-right {
          padding-right: 25px;
        }
        &:hover {
          background-color: #e2e2e2;
        }
      }
    }
  }
  .comment-button {
    display: inline-flex;
    align-items: center;
    color: $primary;
    text-transform: capitalize;
    font-weight: 400;
    border: 1px solid $inactive !important;
    width: auto !important;
    &:focus {
      color: $primary !important;
      box-shadow: none !important;
      &:active {
        box-shadow: none;
      }
    }
    &:disabled {
      background-color: transparent !important;
      border: 1px solid $inactive !important;
    }
    &:hover {
      background-color: $primary !important;
      color: $secondary_one !important;
      border: 1px solid $inactive !important;
      //color: #7d949e;
    }
    &:active {
      background-color: $primary !important;
      color: $secondary_one !important;
      box-shadow: none !important;
    }
    &-cancel {
      background-color: transparent !important;
      border: 1px solid transparent !important;
    }
  }
}
.history-block {
  //height: calc(100% - 200px);
  flex: 1;
  display: flex;
  flex-direction: column;
  //min-height: 120px;
  //overflow-y: hidden;
  //min-height: 100%;
  .tab-button {
    font-family: 'Roboto-Light';
    font-size: 14px;
    border: none;
    background-color: transparent;
    outline: none;
    //font-size: $subtitle_size;
    color: $inactive;
    padding: 0;
    margin-right: 10px;
    position: relative;
    &.active {
      color: $primary;
      font-family: Roboto-medium;
      //&:after{
      //  display: block;
      //  content: '';
      //  width: calc(100% + 4px);
      //  position: absolute;
      //  left: -2px;
      //  height: 1px;
      //  background-color: $primary;
      //}
    }
  }
  .list-history {
    //max-height: calc(100% - 30px);
    //max-height: 500px;
    overflow-y: auto;
    //padding-bottom: 15px;
    //margin-top: 2px;
    //border-top: 1px solid $inactive;
    //border-bottom: 1px solid $inactive;
  }
  .title-history {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    background-color: $secondary_one;
    .history-compose {
      position: relative;
      &.open {
        .list-compose {
          display: block;
        }
      }
      .list-compose {
        z-index: 100;
        position: absolute;
        width: 100%;
        top: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        border: 1px solid #b0bec5;
        border-radius: 5px;
        background-color: #ffffff;
        max-height: 150px;
        overflow-y: auto;
        display: none;
        li {
          padding: 5px 10px;
          color: $primary;
          cursor: pointer;
          transition: 0.15s all;
          &:hover {
            background-color: #e2e2e2;
            //color: $secondary_one;
          }
        }
      }
      .button-aily-with-icon {
        outline: none;
        font-size: $normal_text_size;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .followers-wrap {
      display: flex;
      align-items: center;
      svg {
        width: $action_icon_size;
        height: $action_icon_size;
        path {
          fill: $inactive;
        }
      }
      .followers {
        position: relative;
        z-index: 1;
        &.open {
          .list-users-to-follow {
            display: block;
          }
        }
        .list-users-to-follow {
          display: none;
          position: absolute;
          padding: 0;
          list-style-type: none;
          border: 1px solid $inactive;
          border-radius: $border-radius;
          background-color: $secondary_one;
          max-height: 150px;
          overflow-y: auto;
          li {
            color: $primary;
            text-align: left;
            cursor: pointer;
            padding: 5px 10px;
            transition: 0.25ms linear;
            position: relative;
            svg {
              position: absolute;
              right: 4px;
              top: 0;
              path {
                fill: $accent;
              }
            }
            &.padding-right {
              padding-right: 25px;
            }
            &:hover {
              background-color: #e2e2e2;
            }
          }
        }
      }
      .followers-button-list {
        background-color: transparent;
        border: none;
        outline: none;
        color: $accent;
        font-size: $normal_text_size;
        svg {
          path {
            fill: $accent;
          }
        }
      }
    }
    .actions-history {
      width: 110px;
      .filter-wrap {
        position: relative;
        &.open {
          .filter-list {
            visibility: visible;
          }
        }
        .filter-list {
          right: 0;
          padding: 0;
          list-style-type: none;
          visibility: hidden;
          position: absolute;
          z-index: 1000;
          margin: 0;
          top: 100%;
          background-color: white;
          border: 1px solid #b0bec5;
          border-radius: 5px;
          max-height: 150px;
          overflow: auto;
          li {
            cursor: pointer;
            padding: 5px 10px;
            transition: 0.25ms linear;
            &:hover {
              background-color: #e2e2e2;
            }
          }
        }
      }
    }
  }

  .list-history {
    .preloader-wrap {
      height: 100%;
    }
    .history-item {
      .absolute-type-action-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        left: -20px;
        path {
          fill: $inactive;
        }
      }

      &.comment {
        //background-color: white;
        position: relative;
        padding: 0 16px !important;
        background-color: #fff;
        .date-block {
          display: flex;
          justify-content: center;
          font-size: 10px;
          color: #b0bec5;
          .comment-time {
            margin-top: 5px;
          }
        }
        .absolute-type-action-icon {
          position: absolute;
          width: 16px;
          height: 16px;
          left: 14px;
          path {
            fill: $inactive;
          }
        }
        .comment-block {
          display: grid;
          grid-template-columns: auto 50px;
          padding: 10px 0 0 0;
          p {
            display: inline;
          }
          .comment-block-user {
            .user-name-action {
              word-break: break;
              margin-left: 18px;
            }
          }
          .mention {
            color: #3498DB;
            cursor: pointer;
          }
          .date-block {
            text-align: end;
            font-size: 10px;
            color: #b0bec5;
          }
        }
        &:hover {
          .date-history-action {
            .time {
              .icon-button-view {
                margin-right: 5px;
                //visibility: visible;
              }
            }
          }
        }
        .date-history-action {
          // width: 25px;
          margin-top: -5px;
          font-size: $accompanying_text_size;
          color: $inactive;
          .time {
            .dropdown {
              display: inline-block;
            }
            .icon-button-view {
              visibility: hidden;
            }
            .dropdown {
              .dropdown-item {
                color: #212121;
                padding: 2px 10px;
                font-family: 'Roboto-Regular';
              }
              ul {
                padding: 0;
              }
              button {
                padding: 0;
                margin-left: 5px;
                border: none;
                background-color: transparent;
                outline: none !important;
              }
              svg {
                width: $action_icon_size;
                height: $action_icon_size;
                path {
                  fill: $inactive;
                }
              }
            }
          }
        }
        .user-name-action {
          color: $accent;
        }
        .comment-content {
          word-break: break-all;
          position: relative;
        }
        p,
        ol,
        ul {
          margin: 0;
        }
      }
      &.file {
        padding: 10px 0;
        background-color: #fff;
        .date-history-action {
          font-size: $accompanying_text_size;
          color: $inactive;
          margin-top: 10px;
          padding-left: 10px;
        }

        .row-action {
          position: relative;
          padding-right: 55px;
          padding-left: 10px;
          &:hover {
            .delete-icon {
              display: inline-block;
            }
          }
          .user-name-action {
            color: $accent;
          }
          .file-link {
            color: $accent;
          }
          .time-action {
            font-size: $accompanying_text_size;
            color: $inactive;
            position: absolute;
            right: 10px;
            top: 0;
          }
          .delete-icon {
            position: absolute;
            right: 40px;
            top: -2px;
            cursor: pointer;
            display: none;
            svg {
              width: 16px;
              height: 16px;
              path {
                fill: $inactive;
              }
            }
          }
        }
      }
      &.task-action.action {
        padding: 10px 16px 10px 34px;
      }
      &.task {
        background-color: $secondary_one;
        padding: 10px;
        margin-top: 0;

        .row-action {
          position: relative;
          display: flex;
          justify-content: space-between;

          .info-task {
            display: inline-flex;
            align-items: center;
            svg {
              margin-right: 5px;
              //cursor: pointer;
              &.complete {
                path {
                  fill: green;
                }
              }
            }
            .task-name {
              color: $inactive;
            }
          }
          .deadline-executor {
            .deadline-value {
              font-size: $accompanying_text_size;
              color: $inactive;
              svg {
                margin-right: 5px;
              }
              margin-right: 10px;
            }
          }
        }
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
      &.border-bottom {
        border-bottom: 0 !important;
      }
      &.action {
        padding: 10px 16px;
        background-color: $secondary_one;

        .task-name {
          //cursor: pointer;
          color: $accent;
        }
        .date-history-action {
          font-size: $accompanying_text_size;
          color: $inactive;
          padding: 10px 0 !important;
          text-align: center;
          border-top: 1px solid #f4f4f4;
        }
        .row-action {
          position: relative;
          padding-right: 40px;
          // margin: 10px 7px;
          .user-name-action {
            color: $accent;
            padding: 8px 0;
          }
          .action-name {
            word-break: break-all;
            margin-left: 0;
            padding: 8px 0;
            margin-left: 10px;
            &.with-diff {
              color: $inactive;
              font-weight: bold;
              cursor: pointer;
            }
          }
          .time-action {
            font-size: $accompanying_text_size;
            color: $inactive;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}

.wrap-diff-modal {
  font-size: $normal_text_size;
  + .btn.btn-secondary {
    float: right;
  }
  .diff-row {
    display: flex;
    line-height: 2rem;
    margin-bottom: 1rem;
    &.header {
      border-bottom: 1px solid $inactive;
    }
    .field-name {
      flex: 1;
      text-transform: capitalize;
    }
    .diff-old {
      flex: 1;
    }
    .diff-new {
      flex: 1;
    }
  }
}
