@import "./variable";

.form-title {
  font-size: 12px;
  border-bottom: 1px solid $inactive;
  color: $inactive;
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding-bottom: 5px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path {
      fill: $inactive;
    }
  }
}

.form-header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .left-part{
    display: flex;
    justify-content: flex-start;
  }

  .right-part{
    display: flex;
    justify-content: flex-end;
    &>div, &>button{
      margin-left: 10px;
    }
  }
}