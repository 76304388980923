.homepage-wrap {
  display: flex;
  height: calc(100vh - 14px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .welcome-row {
    margin-top: 3rem;
    color: $primary;
    font-size: $title_size;
    text-transform: uppercase;
    text-align: center;
  }
  &-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      max-width: 300px;
      max-height: 250px;
    }
  }
  &-left {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
  &-right {
    width: 40%;
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .nav-items-wrapper {
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    max-width: 300px;
    //margin-top: 1rem;
    .home-nav-item {
      padding: 0.5rem;
      width: 99px;
      transition: 0.2s;
      &:hover {
        transform: scale(1.15);
      }
    }
    .link-home-page {
      position: relative;
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $inactive;
      border-radius: 8px;
      padding: 5px;
      color: $primary;
      font-size: $normal_text_size;
      &:hover {
        text-decoration: none;
      }
      svg {
        width: $menu_icon_size * 1.5;
        height: $menu_icon_size * 1.5;
        // path {
        //   fill: $inactive;
        // }
      }
    }
  }
}
