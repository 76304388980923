.followers-block {
  padding: 0;
  flex: 1;
  background-color: $secondary_one;

  .followers-row {
    display: flex;
    align-items: flex-start;
  }
  .followers-title {
    font-size: $subtitle_size;
    color: $inactive;
    line-height: 1.6rem;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
  }
  .unfollow-button {
    //flex: auto;
    display: inline-flex;
    font-size: $accompanying_text_size;
    margin-right: 5px;
    padding: 2px 8px;
    margin-top: 2px;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: $inactive;
      }
    }
  }
  .list-followers {
    display: flex;
    flex-wrap: wrap;
    //margin: 0px 5px 0 5px;
    flex: 1;
    min-height: 26px;
    margin-left: 10px;
    .more-followers{
      position: relative;
      display: inline-flex;
      ul{
        background-color: $secondary_one;
        list-style-type: none;
        margin: 0;
        padding: 10px;
        border: 1px solid $inactive;
        border-radius: $border-radius;
        position: absolute;
        top: 100%;
        z-index: 100;
        right: 0;
        .follower-item{
          margin: 5px 0;
        }
      }
    }
    .follower-item {
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      margin-bottom: 2px;
      position: relative;
      cursor: pointer;
      width: 55px;
      //overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
      //padding: 0.25rem 1rem;
      display: inline-flex;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      border-radius: $border-radius;
      &:hover {
        >.remove-follower {
          visibility: visible;
        }
      }
      .remove-follower {
        background-color: $secondary_one;
        right: -6px;
        top: -6px;
        position: absolute;
        cursor: pointer;
        visibility: hidden;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        path {
          fill: $inactive;
        }
      }
    }
    .followers {
      display: inline-flex;
      position: relative;
      margin-right: 10px;
      .add-follower-button {
        //padding: 0;
        padding: 0;
        border: none;

        //border-radius: 50%;
        outline: none;

        font-size: $accompanying_text_size;
        align-self: center;
        &:hover {
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          //width: $action_icon_size;
          //height: $action_icon_size;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }

      }
      .list-users-to-follow {
        display: none;
        position: absolute;
        padding: 0;
        list-style-type: none;
        border: 1px solid $inactive;
        border-radius: $border-radius;
        background-color: $secondary_one;
        width: 150px;
        max-height: 170px;
        overflow-y: auto;
        z-index: 100000;
        top: 100%;
        .search-li{
          .form-control{

            padding: 2px 8px;
            height: auto;
            font-size: $normal_text_size;
          }
        }
        li {
          color: $primary;
          text-align: left;
          cursor: pointer;
          padding: 5px 10px;
          transition: 0.25ms linear;
          position: relative;
          svg {
            position: absolute;
            right: 4px;
            top: 0;
            path {
              fill: $accent;
            }
          }
          &.padding-right {
            padding-right: 25px;
          }
          &:hover {
            background-color: #e2e2e2;
          }
        }
      }
      &.open {
        .list-users-to-follow {
          display: block;
        }
      }
    }
  }
}