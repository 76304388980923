@import "../../adminStyle/variable.scss";

.wrap-form-statuses {
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 100%;
    height: 30px;
    cursor: pointer;
    //margin-top: -5px;
    &.error-input {
        border-color: transparent;
        box-shadow: none;
    }
    &:hover {
        border-color: #80bdff;
    }
    &.disabled {
        //color: $inactive;
        color: $primary;
        pointer-events: none;
        &:hover {
            border-color: #ced4da;
        }
    }
    .active-status {
        svg {
            width: $action_icon_size !important;
            height: $action_icon_size !important;
            margin-right: 5px;
        }
    }
    &.open {
        box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
        .list-statuses {
            visibility: visible;
        }
    }
    .current-status {
        display: flex;
        align-items: center;
        height: 100%;
        //padding: 3px 3px 3px 2px;
        padding: 3px 0;
        .css-tj5bde-Svg {
            margin-right: 2px;
            path {
                fill: $inactive;
            }
        }
        // &.current-priority {
        //     margin-top: -5px;
        // }
    }
    .list-statuses {
        z-index: 1000;
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        background-color: white;
        border: 1px solid #ced4da;
        border-radius: 5px;
        max-height: 170px;
        overflow: auto;
        left: 0%;
        min-width: 150px;
        visibility: hidden;
        top: calc(100% + 5px);
        padding: 4px 0;
        .list-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 8px 12px;
            transition: 0.25ms linear;
            &:hover {
                background-color: #DEEBFF;
              }
            span {
                margin-right: 5px;
                svg {
                    width: $action_icon_size !important;
                    height: $action_icon_size !important;
                }
            }
        }
    }
}
