.wrapper-table {
  .po-header-cell {
    &.dragging{
      z-index: initial;
    }
    background-color: white;
    &:hover{
      .resize-icon{
        visibility: visible;
      }
    }
    .table-cell-th {
      display: inline-flex;
    }
    .resize-icon {
      position: absolute;
      right: 0;
      visibility: hidden;
      cursor: col-resize;
      margin-left: 10px;
      width: $action_icon_size;
      height: $action_icon_size;
      path {
        fill: $accent;
      }
    }
  }
}
