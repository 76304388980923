.error-select-bootstrap-style{
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
  display: none;
}
.bootstrap-class{
  .css-2o5izw,>span + div{
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    &:hover{
      border-color: #80bdff;
    }
  }
}

.invalid-select {
  >div{
    border-color: #dc3545;
    box-shadow: none;
    &:hover{
      border-color: #dc3545;
    }
  }
  &+.error-select-bootstrap-style{
    display: block;
  }
}
.custom-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}