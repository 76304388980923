@import '../../admin/adminStyle/variable.scss';
.service-desk-details {
  padding: 15px 0;
  .content-details {
    width: 100%;
    padding: 0 15px;
    overflow-y: auto;
    .wrap-comment {
      margin-top: 10px;
    }
  }
  .close-details-block {
    position: absolute;
    z-index: 1;
  }
  .service-desk-form {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 1rem;
    position: relative;
    .btn {
      text-transform: initial;
    }
    .row {
      margin-bottom: 10px;
      // max-width: 70%;
      // &:last-of-type {
      //   max-width: 100%;
      // }
    }
    .service-desk-inner {
      max-width: 70%;
      width: 100%;
      display: flex;
      .custom-col {
        display: flex;
        align-items: flex-end;
      }
      .with-icon {
        align-self: flex-end;
        display: flex;
        align-items: center;
        margin: 0 0 10px 15px;
        height: 30px;
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
        &:hover {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    .service-file-wrapper {
      //max-width: 30%;
      position: relative;
      //padding-left: 15px;
      .form-text {
        margin: 0 0 5px 0;
      }
    }
    .action-supp-row {
      margin-bottom: 0;
      text-align: right;
    }
    .form-group {
      width: 100%;
      .error-span {
        margin-top: 0.25rem;
        display: block;
        font-size: 80%;
        color: #dc3545;
        width: 100%;
      }
    }
    .form-label {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
      min-width: 40px !important;
      max-width: 40px !important;
      position: relative;
      font-family: 'Roboto-Regular';
      font-weight: 400;
      color: $inactive;
    }
    .with-info {
      padding-left: 20px;
    }
    .form-text {
      display: flex;
      align-items: center;
      color: #616161;
      font-size: 12px;
      // margin: 0 0 10px 0;
    }
    .form-item {
      display: flex;
      align-items: center;
    }
    .form-control {
      height: 30px;
      font-size: $normal_text_size;
      &::placeholder {
        color: $inactive;
      }
      &.is-invalid {
        background-image: none;
      }
    }
    .invalid-feedback {
      font-size: 80%;
    }
    label {
      display: inline-flex;
      align-items: center;
      padding: 3px 0;
      margin-bottom: auto;
      svg {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .footer-form.footer-form-custom {
    margin-bottom: 0px;
  }
  .footer-form.service-desk-footer {
    margin-bottom: 10px;
    margin-top: 0.5rem;
    .with-icon {
      svg {
        display: none;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        path {
          fill: $inactive;
        }
      }
      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .title-history {
    justify-content: flex-start;
    padding: 1rem 1rem 1rem 10px;
  }
}
.service-desk-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  // overflow: hidden;
  .bootstrap-class {
    margin-top: 7px;
  }
  .with-checkbox {
    margin-bottom: 4px !important;
    margin-left: auto;
  }
  .with-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 30px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path {
        fill: $inactive;
      }
    }
    &:hover {
      svg path {
        fill: #fff;
      }
    }
  }
  .bootstrap-class {
    //margin-top: -4px;
    & > div[class*='control'] {
      border: 1px solid transparent;
    }
    &:hover {
      & > div[class*='control'] {
        border-color: #80bdff;
      }
    }
  }
  .error-span {
    margin-top: 0.25rem;
    display: block;
    font-size: 80%;
    color: #dc3545;
    width: 100%;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path {
      fill: $inactive;
    }
  }
  .icon-type {
    width: 20px;
    height: 20px;
  }
  .auto-resize-wrapper {
    max-width: 100%;
    width: 100% !important;
    // overflow-x: hidden;
    max-width: 100% !important;
    input {
      border: 1px solid #b0bec5 !important;
      padding: 3px 6px;
      border-radius: 0.25rem;
      width: calc(100% - 14px) !important;
      &:disabled {
        background-color: #fff;
        //color: $inactive;
        color: $primary;
        &:hover {
          border-color: transparent;
        }
      }
      &::placeholder {
        color: $inactive;
      }
      &:hover {
        border: 1px solid #80bdff;
      }
      &:disabled {
        &:hover {
          border: 1px solid transparent;
        }
      }
    }
    .auto-resize-input {
      border: 1px solid transparent;
      padding: 5px 4px;
      height: auto;
      font-size: $normal_text_size;
      border: 1px solid #ced4da;
      &::placeholder {
        color: $inactive;
      }
      &.form-control:focus {
        box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
        border: 1px solid $accent;
      }
    }
    .error-input {
      border-color: #dc3545;
    }
  }
  .bootstrap-class {
    width: 100%;
  }
  .invalid-select {
    & > div[class*='control'] {
      box-shadow: none;
      // border-color: #dc3545;
    }
    & + .error-select-bootstrap-style {
      display: block;
    }
  }
  .request-file-upload {
    background-color: transparent;
    border: 1px solid #ced4da;
    color: #495057;
    border-radius: 0.25rem;
    width: fit-content;
    &:hover {
      border: 1px solid #3498db;
    }
    &:focus {
      border: 1px solid #ced4da;
    }
  }
}
.auto-resize-textarea {
  padding: 8px 6px;
  resize: none;
  width: 100%;
  border-color: transparent;
  //margin-top: -2px;
  // max-width: 240px;
  &::placeholder {
    color: $inactive;
  }
  &:hover {
    border-color: #80bdff;
  }
  &:disabled {
    &:hover {
      border-color: transparent;
    }
  }
}
.col-12 {
  .auto-resize-textarea {
    width: 90%;
  }
}

.wrapper-body {
  .wrap-table-with-details-panel.service-desk-table {
    .MuiTableRow-head {
      th:first-of-type {
        .table-cell-th {
          vertical-align: middle;
        }
      }
    }
    .actions-service-desk-toolbar {
      display: flex;
      align-items: center;
      .add-button-service-desk {
        margin-right: 10px;
      }
      .filters-list-wrapper {
        .filter-button {
          text-transform: none;
        }
      }
    }
    &.with-details {
      .toolbar-service-desk {
        flex-wrap: wrap;
        .actions-service-desk-toolbar {
          width: 100%;

          .filters-list-wrapper {
            display: inline-flex;
          }
        }
      }
    }
  }
}
.service-desk-table {
  .actions-wrapper {
    display: flex;
    align-items: center;
    .wrap-statuses {
      pointer-events: none;
    }
    .actions-info {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .priority-wrapper {
    display: flex;
    align-items: center;
    .priority-info {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .type-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.priority-icon {
  width: 24px;
  height: 24px;
}

.urgent {
  color: #dc3545;
  input[type='text'] {
    color: #dc3545 !important;
    -webkit-text-fill-color: #dc3545 !important;
  }
}

.service-desk-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .with-icon {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      &:hover {
        background-color: transparent;
      }
      path {
        fill: $inactive;
      }
    }
    &:hover {
      svg path {
        fill: #fff;
      }
    }
  }
  .btn-back {
    margin-bottom: 0;
  }
}
.info-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 49%;
  transform: translateY(-50%);
  path {
    fill: $inactive;
  }
}
.files-list {
  display: flex;
  flex-direction: column;
  //position: absolute;
  padding: 5px;
  padding-right: 1px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  max-height: 80px;
  z-index: 1;
  top: 100%;
  width: calc(100% - 30px);
  &-inner {
    overflow: auto;
  }
}
.service-file-item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  .service-delete-file {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    visibility: hidden;
    flex-shrink: 0;
    cursor: pointer;
    path {
      fill: $inactive;
    }
  }
  &:hover {
    .service-delete-file {
      visibility: visible;
    }
  }
}
.input-item {
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  outline: none;
  textarea {
    border: 1px solid #b0bec5 !important;
  }
  &-priority {
    max-width: 100%;
    width: 100%;
  }
}

.inactive-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  &:hover {
    background-color: transparent;
  }
  path {
    fill: $inactive;
  }
}

.input-item-priority {
  width: 18px !important;
  .mdc-checkbox__background {
    left: 0;
    border: 1px solid #b0bec5 !important;
  }
  .checkbox-material {
    --mdc-ripple-left: -3px !important;
  }
}

.css-yk16xz-control {
  border: 1px solid #b0bec5 !important;
}
