.editable-item{
  align-self: center;
}
.collapse-container-body{
  table{
    th{
      border-top: none;
    }
  }
}
.wrapper-partly-privileges{
  .editable-form{
    .controll-buttons{
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      .subm-btn{
        margin-right: 0;
        margin-left: 8px;
      }
      .btn-secondary{
        border: none;
      }
    }
    +.icon-button{
      margin-top: 5px;
    }
  }
  table.table{
    td{
      text-align: center;
    }
    th{
      text-align: center;
      border-top: none;
      position: sticky;
      top: 0;
      background-color: $secondary_one;
      z-index: 10;
      color: $inactive;
      font-family: Roboto-Light;
      font-weight: normal;
      span{
        display: inline-block;
        min-width: auto;
        text-align: center;
      }

      &.name-role-cell{
        width: 300px;
        text-align: left;
      }

      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: $inactive;
        }
      }
    }
  }
  .wrap-list-privileges{
    position: relative;
    display: inline-block;
    >div {
      min-width: auto;
      text-align: center;
    }
    .list-privilege{
      width: 120px;
      z-index: 10;
      position: absolute;
      top: 100%;
      list-style-type: none;
      background-color: $secondary_one;
      padding: 0;
      margin: 0;
      border-radius: $border-radius;
      border: 1px solid $inactive;
      display: none;
      &.open{
        display: block;
      }
      li{
        display: flex;
        padding: 5px 10px;
        justify-content: space-between;
        &:hover{
          background-color: $secondary_two;
        }
        svg{
          margin-left: 5px;
          width: 16px;
          height: 16px;
        }
      }
      //display: none;
    }
    .active-privilege-button{
      padding: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      margin: 0 3px;
      &:focus{
        outline: none;
      }
      svg{
        width: $action_icon_size !important;
        height: $action_icon_size !important;
      }
    }

    .roles-list {
      border: 1px solid transparent;
      border-radius: 4px;
      align-items: center;
      color: #616161;
      text-transform: capitalize;
      font-weight: 400;
      padding: 0.25rem 0.5rem;
      text-align: left;
      .role-item {
        padding: 4px;
      }
      &:hover {
        cursor: default;
        border: 1px solid #007bff;
      }
    }

    .custom-control-label{
      text-align: left;
      padding-top: 5px;
    }
  }
}