.icon-button{
  border: none;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  &:focus{
    outline: none;
  }
  &:hover{
    background-color: #f9f9f9;
  }
}