@font-face {
  font-family: 'Roboto-light';
  src: url('../../../assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-medium';
  src: url('../../../assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../../assets/fonts/Roboto-Regular.ttf');
}

@import 'input';
@import './buttons';
@import 'form';

.material-style-custom {
  font-size: $normal_text_size;
  color: $primary;
  line-height: $line-height-body;
  min-height: 100vh;

  .action-icon {
    width: $action_icon_size;
    height: $action_icon_size;
    path {
      // fill: $inactive;
    }
    &.header-icon {
      // path{
      //   fill: $primary;
      // }
    }
  }

  .title-text {
    margin: 0;
    font-family: 'Roboto-light';
    font-size: 18px;
  }

  .subtitle-text {
    font-family: 'Roboto-medium';
    font-size: 14px;
  }

  .accompanying-text {
    font-family: 'Roboto-light';
    font-size: 10px;
  }

  //table
  .wrapper-table {
    > div {
      box-shadow: none;
    }
    table {
      tr {
        .show-on-hover {
          visibility: hidden;
          svg {
            width: $action_icon_size;
            height: $action_icon_size;
          }
        }
        &:hover {
          .show-on-hover {
            visibility: visible;
          }
        }
      }
      thead {
        border-bottom: 2px solid $inactive;
      }
      th {
        position: relative;
        font-size: $normal_text_size;
        color: $inactive;
        svg {
          path {
            fill: $inactive;
          }
        }
      }
      td {
        font-size: $normal_text_size;
        color: $primary;
      }
    }
  }

  .dropdown-menu {
    font-size: $normal_text_size;
  }

  // отступы
  *.m-10 {
    margin-left: 10px;
  }
}

table {
  font-family: 'Roboto-Regular';
}
// кнопки

.icon-button-view {
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 4px 7px;
  display: inline-flex;
  align-items: center;
  color: $primary;
  font-size: $normal_text_size;
  &.without-border {
    border: none;
    padding: 0;
    &:hover {
      background-color: transparent;
      color: $primary;
      svg {
        path {
          fill: $inactive;
        }
      }
    }
  }
  &.with-text {
    svg {
      margin-right: 5px;
    }
  }
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
    path {
      fill: $inactive;
    }
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:hover {
    background-color: $primary;
    color: $secondary_two;
    svg {
      path {
        fill: $secondary_two;
      }
    }
  }
}

.btn,
button.btn,
.button-aily {
  background-color: transparent;
  border: 1px solid $inactive;
}

.btn,
button.btn {
  padding: 0.25rem 0.5rem;
  font-size: $normal_text_size;
}
.btn:hover,
button.btn:hover {
  color: $secondary_one;
  background-color: $primary;
  border-color: $inactive;
}
.btn-secondary,
.btn.btn-secondary {
  background-color: transparent;
  color: $primary;
  border: 1px solid $inactive;
  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary,
.btn.btn-primary {
  display: inline-flex;
  align-items: center;
  color: $primary;
  text-transform: capitalize;
  font-weight: 400;
  &:focus {
    box-shadow: none !important;
    &:active {
      box-shadow: none;
    }
  }
  &:disabled {
    background-color: transparent;
    border: 1px solid $inactive;
  }
  &:hover {
    background-color: $primary;
    color: $secondary_one;
    //color: #7d949e;
  }
  &:active {
    background-color: $primary !important;
    color: $secondary_one;
    box-shadow: none;
  }
}

//text
.upper {
  text-transform: uppercase;
}

//hints and toasts
.Toastify__toast--success {
  border-radius: 5px;
  background: $secondary_one;
  color: $primary;
  border: 1px solid $inactive;
}
.tooltip {
  &.show {
    opacity: 1 !important;
  }
  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-top-color: transparent !important;
        border-left-color: $inactive;
      }
    }
  }
  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $inactive !important;
      }
    }
  }
  .arrow {
    &::before {
      border-top-color: $inactive !important;
    }
  }
  .tooltip-inner {
    background-color: $secondary_two;
    color: $primary;
    font-size: $normal_text_size;
    font-family: Roboto-light;
    border: 1px solid $inactive;
    text-align: left;
    border-radius: 5px;
  }
}

label {
  margin-bottom: 0;
  font-size: $normal_text_size;
  color: $primary;
}

div.form-group {
  margin-bottom: 0.5rem;
  * {
    font-size: $normal_text_size;
  }
  .error-select-bootstrap-style,
  .invalid-feedback {
    font-size: $accompanying_text_size;
  }
}

.title-text {
  margin: 0;
  font-family: 'Roboto-light';
  font-size: 18px;
}

.flex-1 {
  flex: 1;
}
.flex-3 {
  flex: 2;
}

.button-aily-with-icon {
  display: inline-flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
  color: $primary;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid $inactive;
  &:hover {
    background-color: $primary;
    color: $secondary_one;
    svg {
      path {
        fill: $secondary_one;
      }
    }
  }
  svg {
    width: $action_icon_size;
    height: $action_icon_size;
    transition: 0.15s ease-in-out;
    path {
      fill: $inactive;
    }
  }
}

.modal-dialog {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    height: 100%;
    .preloader-wrap {
      height: 50vh;
    }
  }
  .content-header {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    color: $primary;
    font-size: $normal_text_size;
    &.disabled {
      .related-disabled {
        pointer-events: none;
      }
    }
    .central-part {
      text-align: center;
      padding: 15px 45px;
    }
    .right-part {
      padding: 15px 0;
      display: flex;
      justify-content: flex-end;
      .actions {
        .icon-button-view {
          margin-left: 5px;
        }
      }
    }
    &.column-3 {
    }
  }
}

.with-pointer {
  cursor: pointer;
}

.nowrap-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

div.wrapper-calendar-asana {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border-radius: $border-radius;
  border: 1px solid transparent;
  font-size: $accompanying_text_size;
  .circle-close {
    cursor: pointer;
    width: 16px;
    height: 16px;
    visibility: hidden;
    path {
      fill: $inactive;
    }
  }
  &:hover {
    border: 1px solid $inactive;
    .circle-close {
      visibility: visible;
    }
  }
  .react-bootstrap-daterangepicker-container {
    display: flex !important;
    align-items: center;
    cursor: pointer;

    user-select: none;
    flex-wrap: wrap;
    svg:not(.circle-close) {
      width: 26px;
      height: 26px;
      margin-right: 5px;
      border: 1px dashed $inactive;
      padding: 5px;
      border-radius: 50%;
      path {
        fill: $inactive;
      }
    }

    .form-control {
      user-select: none;
      cursor: pointer;
      border: none;
      width: auto;
      max-width: 110px;
      background-color: transparent;
      box-shadow: none;
      font-size: $accompanying_text_size;
      &.is-invalid {
        background-image: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.form-title {
  font-size: 12px;
  border-bottom: 1px solid $inactive;
  color: $inactive;
  display: flex;
  align-items: flex-end;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path {
      fill: $inactive;
    }
  }
}

.form-control:disabled {
  color: $inactive;
  -webkit-text-fill-color: $inactive; /* Override iOS / Android font color change */
  -webkit-opacity: 1;
}

.footer-with-margin {
  margin-top: 8px;
}

.no-data-to-display {
  text-align: center;
  font-family: Roboto-light;
  color: $inactive;
  font-size: $normal_text_size;
}

.modal-800 {
  min-width: 800px;
}
