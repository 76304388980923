@import "src/components/admin/adminStyle/variable";

.inventory-editor{
  display: flex;
  flex: 1;
  //align-items: flex-start;
  .inventory-editor-map-block-wrapper{
    flex: 1;
    .grid-item{
      &.clickable{
        &:hover{
          cursor: pointer;
          rect{
            stroke: $accent;
          }
        }
      }
    }
    .select-space-wrapper{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .3);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
    }
  }
  .inventory-instruments-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $inactive;
    min-width: 300px;
    margin-left: 10px;
    margin-bottom: 10px;
    max-height: calc(100vh - 70px);
    .title-row{
      padding: 10px;
      border-bottom: 1px solid $inactive;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size: $subtitle_size;
      }
    }
    .action-row{
      //margin-bottom: 10px;
      padding: 10px;
      border-bottom: 1px solid $inactive;
    }
    .create-instrument-form{
      .create-instrument-form-row{
        display: flex;
        .form-group{
          display: flex;
          flex-direction: column;
          padding-right: 10px;
          &:last-child{
            padding-right: 0;
          }
          &.space-val{
            width: 100%;
            max-width: 179px;
          }
          &.color-val{
            width: 63px;
          }
          &.slot-val{

          }
          .form-label{
            padding-left: 7px;
            color: $inactive;
          }
          .error-label{
            padding-left: 7px;
          }
          .switch-label{
            padding-left: 7px;
            padding-top: 10px;
          }
          .color-dropdown{
            padding-left: 7px;
            margin-top: 6px;
          }
          .color-option{
            width: 20px;
            height: 15px;
          }
        }
        .auto-resize-wrapper{
          input{
            background: $secondary_two;
          }
        }
      }
    }
    .color-dropdown{
      position: relative;
      .color-dropdown-toggle{
        display: flex;
        cursor: pointer;
        align-items: center;
        .color{
          width: 30px;
          height: 20px;
          border-radius: 5px;
        }
        svg.down-icon{
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          path{
            fill: $primary;
          }
        }
      }
      .colors-list-picker{
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(23px);
        padding: 10px 0 0 10px;
        flex-wrap: wrap;
        max-width: 152px;
        width: 152px;
        background: white;
        border-radius: 5px;
        border: 1px solid $inactive;
        z-index: 10;
        .project-color-block{
          cursor: pointer;
          width: 25px;
          height: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $inactive;
          svg{
            path{
              fill: white;
            }
          }
        }
        &.opened{
          display: flex;
        }
      }
    }
    .inventory-instruments-table{
      padding: 10px 0;
      //border-bottom: 1px solid $inactive;
      table{
        width: 100%;
        vertical-align: middle;
        border-collapse: separate;
        border-spacing: 0;
        td, th{
          padding: 5px

        }
        tr{
          padding: 0 10px;
          td:first-child{
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            max-width: 147px;
          }
          &:hover{
            .actions-cell{
              .standard-svg{
                display: inline-block;
                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
          td{
            font-family: 'Roboto-Light';
          }
        }
        tbody{
          tr{
            td:first-child{
              border:  1px solid transparent;
              border-right: none;
            }
            td:nth-child(2), td:nth-child(3){
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;
            }
            td:last-child{
              border:  1px solid transparent;
              border-left: none;
            }

            &:hover{
              background: $grey;
            }

            &.selected, &:hover{
              cursor: pointer;
            }

            &.selected{
              td:first-child{
                border:  1px solid $accent;
                border-right: none;
              }
              td:nth-child(2), td:nth-child(3){
                border-top: 1px solid $accent;
                border-bottom: 1px solid $accent;
              }
              td:last-child{
                border:  1px solid $accent;
                border-left: none;
              }
            }
          }
        }
        .actions-cell{
          width: 56px;
          &.with-form{
            .standard-svg {
              display: inline-block;
            }
          }
          .standard-svg{
            display: none;
            &:first-child{
              margin-right: 5px;
            }
          }
        }
        .auto-resize-wrapper{
          input{
            background: $secondary_two;
          }
        }
      }
      .color-block{
        width: 30px;
        height: 20px;
        border-radius: 5px;
      }
    }
    .selected-instrument-card{
      border-top: 1px solid $inactive;
      padding: 10px;
      position: sticky;
      bottom: 0;
      z-index: 2;
      background: $secondary_one;
      .instrument-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .color-block{
          width: 30px;
          height: 20px;
          border-radius: 5px;
        }
      }
      .coordiantes-row{
        max-height: 100px;
        overflow-y: auto;
        margin: 10px 0;
      }
      .form-row{
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}