@import './Toolbar/style.scss';
.complete-icon{
  svg{
    //cursor: ;
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      fill: $inactive;
    }
  }

  &.with-hover{
    cursor: pointer;
    &:hover{
      svg{
        path{
          fill: #25e8c8;
        }
      }
    }
  }
  &.complete{
    svg{
      path{
        fill: #25e8c8;
      }
    }
  }
}
.tasks-table{
  .table-cell-th{
    flex-direction: row-reverse;
    justify-content: flex-end;
    text-transform: initial;
    .filter-wrap{
      margin-right: 10px;
    }
  }
}
.task-responsible-wrapper {
  .executor-value {
    font-size: 14px;
    font-weight: bold;
  }
}
.executor-wrapper {
  .executor-value {
    font-weight: bold;
    font-size: 14px;
  }
}