@import './rest-component/style.scss';
@import './packages/style.scss';
@import './all-component/style.scss';
.wrap-dividing {
  height: 100%;
  position: relative;
  > h5 {
    text-align: center;
  }
  .preloader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 40px !important;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 50;
  }
  .dividing-row {
    display: flex;
    font-size: $normal_text_size;
    color: $primary;
    padding-bottom: 5px;
    .left-dividing-part {
      flex: 4;
      padding: 10px 10px 2px 3px;
      height: 100%;
    }
    .right-dividing-part {
      flex: 8;
      .packages-wrapper {
        height: 100%;
        th {
          &.table-header-cell {
            word-break: break-word;
            &:first-of-type {
              min-width: 100px;
            }
            &:nth-of-type(2) {
              min-width: 100px;
            }
          }
        }
        .table-body {
          display: flex;
          .table-row {
            // height: auto !important;
            display: flex;
            align-items: center;
            td {
              &.table-cell {
                word-break: break-word;
                &:first-of-type {
                  min-width: 100px;
                }
                &:nth-of-type(2) {
                  min-width: 100px;
                }
              }
            }
          }
        }
        > div {
          // height: 100%;
        }
      }
    }
    &.header-row {
      color: $inactive;
      border-bottom: 2px solid $inactive;
      margin-left: 2px;
      font-family: Roboto-Regular;
      font-weight: 400;
      .right-dividing-part {
        padding-left: 10px;
      }
      .left-dividing-part {
        padding: 0;
      }
    }
    &.body-row {
      padding-top: 0px;
      height: calc(100% - 25px);
      .left-dividing-part {
        height: 100%;
        overflow-y: auto;
      }
      .right-dividing-part {
        height: 100%;
        overflow-y: auto;
        background-color: $secondary_one;
        border: 1px solid $inactive;
        border-top: none;
      }
    }
  }
  .wrap-condition-set {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border: 1px solid $inactive;
    margin-top: 10px;
    border-radius: $border-radius;
    position: relative;
    &:hover {
      .remove-set-button {
        visibility: visible;
      }
    }
    .remove-set-button {
      cursor: pointer;
      visibility: hidden;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $inactive;
        }
      }
    }
    &.active {
      //border: 1px solid $accent;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    .with-icon {
      align-self: flex-start;
      display: inline-flex;
      width: auto;
      font-size: $normal_text_size;
      margin-top: 10px;
      &:hover {
        svg {
          path {
            fill: $secondary_one;
          }
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path {
          fill: $inactive;
        }
      }
    }
    .condition-row {
      display: flex;
      margin-top: 10px;
      &.header-row {
        border-bottom: 2px solid $inactive;
        padding-bottom: 5px;
      }
      .bootstrap-class {
        font-size: $accompanying_text_size;
        > div {
          min-height: 28px;
          padding: 2px;
          div[class*='indicatorContainer'] {
            padding: 2px;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .field-condition {
        width: 25%;
        padding-right: 5px;
      }
      .value-condition {
        width: 65%;
        padding-right: 5px;
      }
      .remove-condition {
        display: flex;
        align-items: center;
        visibility: hidden;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: $inactive;
          }
        }
      }
      .name-set {
        flex: 3;
        color: $inactive;
        font-family: Roboto-medium;
      }
      .items-per-package {
        flex: 3;
        display: flex;
        align-items: center;
        .form-control {
          max-width: 50px;
          width: auto;
          padding: 4px;
          font-size: $accompanying_text_size;
          height: auto;
          line-height: 0;
          margin-left: 5px;
          &:disabled {
            background-color: transparent;
          }
        }
      }
      .packages {
        flex: 1;
      }
      .total-rrp {
        flex: 2;
      }
      .total-items {
        flex: 2;
        display: flex;
        svg {
          width: 16px;
          height: 20px;
          margin-right: 3px;
          margin-top: -3px;
          path {
            fill: $inactive;
          }
        }
      }
      &:hover {
        .remove-condition {
          visibility: visible;
        }
      }
    }
  }
}

.packages-used {
  p {
    margin: 0;
  }
}
