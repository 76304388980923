.form-group-custom {
    display: flex;
    flex-direction: column;
    .form-group {
        display: flex;
        align-items: center;
    }
    .switch-label {
        margin-left: 10px;
    }
}