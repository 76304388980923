@import '../purchaseOrders/details/info/inbound-section/style.scss';


.details-po{
  height: calc(100vh - 130px);
  .content-details{
    .title-text {
      margin-bottom: 10px;
    }
  }
}

.po-tab-content{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  overflow: hidden;
  .flat-table {
    display: flex;
    flex-direction: column;
    height: 100%;
      div[style*="overflow-x"] {
        height: 100%;
      }
    table {
      thead {
        th {
          background-color: $secondary_two;
        }
      }
      
    }  
  }
  .header-po-tab{
    display: flex;
    // padding: 0 10px;
    //margin-bottom: 10px;
    width: 100%;
    .left-part{
      flex: 1;
      display: flex;
      align-items: center;
      color: red;
      &.empty-bid {
        > p {
          margin: 0 !important;
        }
      }
    }
    .central-part{
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .right-part{
      flex: 1;
      justify-content: flex-end;
      display: flex;
      .btn{
        &:hover {
          background-color: #616161;
          color: #ffffff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        svg{
          margin-right: 5px;
        }
      }
    }
  }
  .content-po-tab{
    padding: 10px 0;
    //padding: 10px;
    height: 100%;
    width: 100%;
    background-color: $secondary_two;
    .products-table {
      height: 100%;
      .icon-button {
        width: auto;
        height: auto;
        visibility: hidden;
      }
      td {
        &:hover {
          .icon-button {
            visibility: visible;
          }
        }
      }
      .menu-table-actions {
        a {
          padding: 4px 10px;
        }
      }
    }
    .dividing-row.body-row{
      height: calc(100% - 35px);
    }
    .charts-block{
      .top-charts{
        .preloader-wrap{
            width: 50%;
        }
      }
      .total-charts{
        .preloader-wrap{
          width: 33%;
        }
      }
    }
    .mdc-checkbox{
      .mdc-checkbox__checkmark-path{
        fill: none;
      }
    }
  }
}

.details-po-header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  .details-po-action {
    display: flex;
    align-items: center;
  }
  .btn-back{
    align-self: center;
    margin-bottom: 0;
    //margin-top: auto;
  }
  .tabs-custom{
    align-self: center ;
    &.disabled{
      pointer-events: none;
    }
    .nav-item{
      white-space: nowrap;
      .nav-link{
        &.active{
          border-bottom: 2px solid $primary;
        }
        &.disabled{
          color: $inactive;
          .content-tab{
            color: $inactive;
            .tab-name{
              color: inherit;
            }
          }
        }
      }
      &-stowing {
        svg {
          path {
            fill: $accent;
          }
        }
        .nav-link {
          &.disabled {
            pointer-events: auto;
            svg {
              path {
                fill: $inactive;
              }
            }
          }
        }
      } 
    }
  }
}
.details-po{
  //padding: 0 2rem;
  padding-bottom: 1rem;
  position: relative;
  align-self: flex-start;
  align-self: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-height: calc(100vh - 130px);
  padding: 10px;
  background-color: $secondary_two;
  table{details
    td,th{
      vertical-align: middle;
    }
  }
  .tabs-content{
    height: 100%;
  }
  .wrap-contact-row{
    > div {
      word-break: break-all;
    }
    .phone-contact{
      color: $accent;
    }
  }
  .content-details{
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    padding-top: 0;
    min-height: 50vh;
    //flex-wrap: wrap;
    .stages{
      flex: 5;
      overflow: auto;
    }
    .history{
      display: flex;
      flex-direction: column;
      flex: 7;
      padding: 0 2rem;
      //overflow: hidden;
    }
    .preloader-wrap{
      height: 100%;
    }
  }
  .red-color{
    color: red;
  }
  .close-details-block{
    //position: absolute;
    top: 0px;
    //border: 2px solid $secondary_two;
    right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: $inactive;
      }
    }
  }
  .close-stage-button{
    svg{
      margin-right: 10px;
      width: $action_icon_size;
      height: $action_icon_size;
      path{
        fill: #90c58a;
      }
    }
  }

  .third-columns-row{
    display: flex;
    width: 100%;
    &.header-row{
      .target,
      .actual{
        font-weight: bold;
      }
    }
    .flex-2{
      flex: 3;
      display: flex;
      .form-element{
        flex: 1;
        &:first-of-type{
          margin-right: 5px;
        }
      }
    }
    .name{
      flex: 2;
    }
    .target{
      flex: 2;
      margin-left: 5px;
    }
    .actual{
      flex: 2;
      margin-left: 5px;
    }
  }
  .form-line-format{
    .form-group{
      margin-bottom: 0.5rem;
      display: flex;
      label{
        align-self: center;
        font-size: $normal_text_size;
        flex: 2;
      }
      .form-element{
        flex: 3;
      }
    }
    [class*="control"]{
      min-height: 32px;
    }
    [class*="singleValue"]{
      font-size: $normal_text_size;
    }
    [class*="Input"]{
      margin: 0;
    }
    .css-tlfecz-indicatorContainer{
      padding: 4px;
    }
    .form-control{
      font-size: $normal_text_size;
      height: auto;
      line-height: 1;
    }
  }
  .edit-component{
    background-color: transparent;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 4px 7px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus{
      outline: none;
    }
    &:hover{
      background-color: $primary;
      svg{
        path{
          transition: fill .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          fill: white;
        }
      }
    }
    svg{
      width: $action_icon_size;
      height: $action_icon_size;
      path{
        fill: $inactive;
      }
    }
  }
  .divider{
    border-bottom: 1px solid $inactive;
    margin: 0.5rem 0;
  }
  .row-form{
    display: flex;
    padding: 0.25rem 0;
    .label{
      color: $primary;
      flex: 2;
    }
    .value{
      flex: 3;
      color: $primary;
    }
  }
  .bold{
    font-weight: bold;
  }
  .body-delivery-info{
    color: $primary;
    padding: 0 0.5rem;
    font-size: $normal_text_size;
    .divider{
      margin-top: 0;
    }
    .body-dm-block{
      .row-dm-block-body{
        display: flex;
        padding: 5px 0;
        .name{
          flex: 2;
        }
        .target{
          flex: 1;
        }
        .actual{
          flex: 1;
        }
        &.header-row{
          border-bottom: 1px solid $inactive;
          .target{
            color: $primary;
            font-weight: bold;
          }
          .actual{
            color: $primary;
            font-weight: bold;
          }
        }
      }
    }
    .title{
      //border-top: 1px solid #cad2d8;

      display: flex;
      justify-content: space-between;
      .actions{
        display: flex;
        flex: 2;
        justify-content: flex-end;
        .edit-wrap{
          margin-left: 5px;
          display: inline-flex;
        }
      }
      .name-date-block{
        display: flex;
        flex-direction: column;
        flex: 4;
        .name{
          color: $primary;
        }
        .date{
          font-size: $normal_text_size;
          color: $accent;
        }
      }
    }
  }
  .tabs-container{
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
    //margin-bottom: 1rem;
    .nav-tabs{
      width: 100%;
      border: 2px solid $secondary_one;
      border-top: none;
      border-radius: 5px;
      .nav-link{
        border: none;
        padding: 0.5rem 1rem;
        position: relative;
        color: $primary;
        &.disabled{
          color: $inactive;
          svg{
            path{
              fill: $inactive;
            }
          }
        }
        &.active{
          .tab-name{
            display: block;
          }
        }
      }
      .content-tab{
        display: flex;
        flex-direction: column;
        align-items: center;
        svg{
          width: $action_icon_size;
          height: $action_icon_size;
          path{
            fill: $inactive;
          }
        }
        .tab-name{
          //display: none;
          font-size: $accompanying_text_size;
          //position: absolute;
          //bottom: -20px;
        }
      }
    }
  }


  // left part of details
  .stage-block{
    background-color: $secondary_one;
    padding: 0.5rem 0.5rem;
    .edit-wrap{
      visibility: hidden;
    }
    &:hover{
      .edit-wrap{
        visibility: visible;
      }
    }
    &.main-info-stage,
    &.transport-info-stage{
      margin-bottom: 1rem;
    }
    .finance-main-info{
      display: flex;
      .finance-info{
        flex: 2;
      }
      .status{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        color: $accent;

      }
    }
    .title-stage-block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .name-stage-block{
        &.complete-stage{
          svg{
            path{
              fill: #90c58a;
            }
          }
        }
        svg{
          width: $action_icon_size;
          height: $action_icon_size;
          path{
            fill: $inactive;
          }
        }
        font-size: $subtitle_size;
      }
    }
    .main-info{
      color: #707070;
      font-size: $normal_text_size;
      p{
        margin-bottom: 0;
      }
      .show-more-component{
        margin-right: 5px;
        word-break: break-word;
      }
      .body-main-info{
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .title-main-info{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        h3{
          margin: 0;
        }
        .ref-numb-block{
          display: flex;
          align-items: center;
          .order-number-po{
            color: $primary;
            font-weight: bold;
          }
          .date{
            margin-left: 5px;
            font-size: $accompanying_text_size;
            color: $primary;
          }
        }
      }
    }
  }

  .finance-block{
    margin-top: 0rem;
    font-size: $normal_text_size;
    padding: 0.5rem;
    //border-top: 1px solid #cad2d8;
    .title-finance-block{
      display: flex;
      justify-content: space-between;
      .title{
        color: $primary;
      }
    }
    .finance-body{
      margin-top: 1rem;

    }
  }
  .total-block{
    border-top: 1px solid $inactive;
    padding: 0.5rem;
  }
  .close-stage-button{
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;
  }
  .align-self-bottom{
    align-self: flex-end;
  }
  .stepper{
    font-size: $normal_text_size;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    padding: 0 15%;
    .divider-step{
      display: flex;
      flex-grow: 1;
      height: 1px;
      width: 100%;
      background: $inactive;
    }
    .step{
      align-items: center;
      display: flex;
      flex-direction: column;
      position: relative;
      &.complete{
        .icon{
          position: relative;

          svg{
            path{
              fill: $inactive;
            }
          }
        }

        .title{
          color: $inactive;
        }
      }
      &.active{
        .title{
          color: $accent;
        }
      }
      .icon{
        svg{
          width: $action_icon_size;
          height: $action_icon_size;
        }
        .done-icon{
          background-color: white;
          position: absolute;
          bottom: -1px;
          right: -2px;
          width: 14px;
          height: 14px;
          z-index: 1;
          border-radius: 50%;
          path{
            fill: #90c58a!important;
          }
        }
        .svg{
          svg{
            width: $menu_icon_size;
            height: $menu_icon_size;
          }
        }
        .with-pointer{
          position: absolute;
        }
      }
    }
  }
  h3{
    margin: 1rem 0;
    font-family: Roboto-light;
    color: $accent;
    font-size: $title_size;
    font-weight: 400;
  }
  .preloader-wrap{
    height: 100%;
  }
  .transport-chronology{
    display: flex;
    width: 100%;
    margin-top: 10px;
    //max-height: calc(100% - 245px);
    //flex: 1;
    //height: 50%;
    //flex: 1;
    //flex: 1;
    //height: 50%;
    //max-height: 50%;
    .left-column{
      align-self: baseline;
      flex: 2;
      height: 100%;
    }
    .right-column{
      flex: 3;
      padding-left: 10px;
      height: 100%;
      position: relative;
      //overflow-y: auto;
    }
  }
}

.products-modal{
  .modal-content{
    max-height: calc(100vh - 3.5rem);
    overflow-y: hidden;
    height: 100%;
    .content-modal-body{
      table{
        border-collapse: separate;
      }
      thead{
        border-bottom: none;
        th{
          border-bottom: 2px solid $primary;
          position: sticky;
          background-color: $secondary_two;
        }
      }
      overflow-x: visible;
      max-height: calc(100vh - 3.5rem - 60px);
      height: calc(100vh - 3.5rem - 60px);
    }
  }
  .close-btn{
    position: absolute;
    right: 15px;
    top: 22px;
  }

  &.modal-dialog{
    .content-header{
      .right-part{
        //padding: 40px 0;
      }
      .central-part{
        padding: 40px 15px;
      }
    }
  }


  .modal-content{
    background-color: $secondary_two;
  }
  &.modal-dialog{
    max-width: 97%;
    height: calc(100% - 3.5rem);
  }
}
.confirmUploadBlock{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 450px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  .reason-input{
    width: 100%;
    margin-bottom: 10px;
    textarea{
      font-family: Roboto-light;
      color: $primary;
      font-size: $normal_text_size;
    }
  }
  .actions {
    width: 100%;
    display: flex;
  }
}

.no-data-to-display{
  color: $inactive;
}