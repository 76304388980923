@import '../../admin/adminStyle/variable';

.custom-chip{
  -ms-flex-align: center;
  align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition-duration: .15s;
  transition-property: box-shadow,background;
  vertical-align: top;
  white-space: nowrap;
  margin-right: 10px;
  margin-top: 10px;
  cursor: inherit!important;
  border: 1px solid $inactive;
  border-radius: $border-radius;
  background: $secondary_one;
  margin-left: 0;
  margin-right: 10px;
  &:focus {
    outline: 0;
  }
  .text-chip{
    color: $primary;
    font-size: 13px;
    transition-duration: .15s;
    transition-property: color;
    margin-right: 10px;
  }
  i{
    cursor: pointer;
    color: $inactive;
    font-size: 14px;
    text-align: center;
    transition-duration: .15s;
    transition-property: color;
  }
}