@import "../adminStyle/variable";
@import './Toolbar/style.scss';

  .content-header {
    margin-bottom: 15px;

    .central-part {
      min-height: 30px;
      .search-wrap{
        display: flex;
      }
    }
    .right-part {
      display: flex;
      align-items: flex-start;
      padding: 0;
      padding-right: 20px;
      .products-action-icon{
        margin: 0 10px;
        cursor: pointer;
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: $inactive;
        }
      }
      .actions {
        align-items: flex-start;
        margin-top: 6px;
        margin-right: 10px;
        .export-dividing {
          display: inline-flex;
          align-items: center;
          color: $primary;
          padding: 5px;
          svg {
            margin-right: 5px;
          }
          &:hover {
            background-color: transparent;
          }
        }
        .dropdown-menu {
          padding: 0;
          font-size: $normal_text_size;
          color: $primary;
          min-width: 8rem;
          li {
            padding: 5px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            width: 100%;
            &.with-link {
              padding: 0;
            }
            svg {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              path {
                fill: $inactive;
              }
            }
            &:hover {
              background-color: $secondary_two;
              //color: $secondary_one;
            }
          }
        }
        .icon-button {
          padding: 0;
          width: 24px;
          height: 24px;
          font-size: 16px;
          color: $inactive;
          &:hover {
            background-color: transparent;
          }
          svg {
            path {
              fill: $inactive;
            }
          }
        }
      }
    }
  }

.table-analysis{
  thead{
    .mdc-checkbox{
      .mdc-checkbox__checkmark-path{
        fill: none;
      }
    }
  }
}


.content-header {
  .central-part {
    padding: 0 !important;
    min-height: 30px;
  }

  .left-part {
    flex: 1;
    .tabs-custom {
      width: 100%;
      border: none;
      display: inline-flex;
      flex-wrap: nowrap;
      .nav-item {
        flex-grow: unset;
        margin-right: 10px;
        font-family: Roboto-light;
        .nav-link {
          padding: 0;
          position: relative;
          .tab-name {
            white-space: nowrap;
          }
          &.active {
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: calc(100% + 4px);
              left: calc(-2px);
              height: 2px;
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}

.form-group {
  .input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    .custom-file {
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      height: calc(1.5em + .75rem + 2px);
      .custom-file-label {
        cursor: pointer;
        border-radius: .25rem;
        &::after {
          color: $primary;
          background-color: $secondary_two;
        }
      }
    }
  }
}

.checkbox-material {
  &:before {
    background-color: $accent!important;
  }
  .mdc-checkbox__native-control {
    &:indeterminate{
      + .mdc-checkbox__background {
        border-color: $accent!important;
        background-color: $accent!important;

      }
    }
    &:checked {
      + .mdc-checkbox__background {
        border-color: $accent!important;
        background-color: $accent!important;

      }
    }

  }
}

@-webkit-keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: $accent!important;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, $accent!important);
    background-color: $accent!important;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, $accent!important); } }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: $accent!important;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, $accent!important);
    background-color: $accent!important;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, $accent!important); } }

@-webkit-keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: $accent;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, $accent!important);
    background-color: $accent;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, $accent!important); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: $accent;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, $accent);
    background-color: $accent;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, $accent); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

.dropdown-item{
  color: $primary;
}

.buttons-bulk-edit{
  .edit-bulk-button{
    margin-right: 10px;
    &:disabled{
      opacity: 0.5;
    }
    svg{
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path{
        fill: $inactive;
      }
    }
  }
  .bulk-active-mode-button{
    margin-right: 10px;
    svg{
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path{
        fill: $inactive;
      }
    }
  }
}

.products-errors{
  color: red;
  margin-left: 0;
  font-size: $normal_text_size;
  cursor: pointer;
}

.smart-filter-button{
  margin-right: 10px;
  svg{
    margin-right: 5px;
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      fill: $inactive;
    }
  }
}

.wrapper-converter{
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: $normal_text_size;
  .actions{
    margin-top: 10px;
    .btn:first-of-type{
      margin-right: 10px;
    }
  }
}

.material-style-custom {
  .wrapper-table {
    table {
      thead {
        th {
          position: sticky;
          background-color: #fff;
          vertical-align: middle;
        }
      }
    }
  }
}