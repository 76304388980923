.tables-chart{
  height: 100%;
  overflow-y: auto;
  font-size: $normal_text_size;
  font-family: Roboto-light;
  color: $primary;
  display: flex;
  .wrapper-table-analysis{
    width: calc(100%/3);
    margin-right: 10px;
    table{
      border-collapse: collapse!important;
      th{
        white-space: nowrap;
        color: $inactive;
        font-family: Roboto-regular;
        border-top: none;
        border-bottom: 2px solid $inactive;
        font-weight: 400;
      }
      tr{
        &.caption-row{
          td{
            font-family: Roboto-regular;
            border: none;
            border-bottom: 2px solid $inactive;
          }
        }
        &.value-row{
          td:first-of-type{
            padding-left: 15px;
          }
          &:first-of-type{

          }
          td{
            font-family: Roboto-regular;
          }
        }
      }
    }
  }
}
