@import "../../../adminStyle/variable.scss";
.actions-conditions {
  height: 100%;
  .with-icon {
    font-size: $normal_text_size;
    height: 100%;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path {
        fill: $inactive;
      }
    }
    &:first-of-type {
      margin-right: 10px;
    }
    &.complete {
      border: 1px solid green;
      color: green;

      &:hover {
        color: $secondary_one;
        border: 1px solid $secondary_one;
        svg {
          path {
            fill: $secondary_one;
          }
        }
      }
      svg {
        path {
          fill: green;
        }
      }
      &:disabled {
        pointer-events: none;
      }
    }
  }
}
