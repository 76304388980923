.stowing-wrapper{
  display: flex;
  max-height: 100%;
  overflow: auto;
  .packages-wrapper{
    position: relative;
    .preloader-wrap{
      top: 0;
      left: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: rgba(255,255,255,.6)
    }
    .stowing-title-block{
      //margin-top: 5px;
    }
    flex: 1;
    .packages-list{
      padding-right: 15px;
      margin-top: 5px;
      .package-row{
        border: 1px solid $inactive;
        border-radius: $border-radius;
        margin-bottom: 5px;
        display: flex;
        font-size: $normal_text_size;
        color: $primary;
        padding: 5px 15px;
        &.active{
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
        svg{
          width: $action_icon_size;
          height: $action_icon_size;
          margin-right: 5px;
          path{
            fill: $inactive;
          }
        }
        >div{
          flex: 1;
          align-self: center;
          &:not(.name){
            justify-content: center;
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }
  .boxes-wrapper{
    flex: 2;
    position: relative;
    >.preloader-wrap{
      top: 0;
      left: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: rgba(255,255,255,.6)
    }
    .boxes-list{
      margin-top: 5px;
      font-size: $normal_text_size;
      .box-collapse{
        border: 1px solid $inactive;
        padding: 5px 10px;
        .box-products{
          min-height: 100px;
          .preloader-wrap{
            max-height: 100px;
          }
          .table{
            margin-top: 10px;
            width: 100%;
            border-collapse: collapse;
            th{
              color: $inactive;
              border-top: none;
            }
            td{
              vertical-align: middle;
            }
            .actions{
              display: flex;
              svg{
                cursor: pointer;
                width: 16px;
                height: 16px;
                path{
                  fill: $inactive;
                }
              }
            }
          }
        }
        .box-collapse-header{
          display: flex;
          align-items: center;
          .collapse-icon{
            width: 16px;
            height: 16px;
            transition: all .15s ease-in-out;
            margin-right: 5px;
            &.open{
              transform: rotate(-180deg);
            }
            path{
              fill: $inactive;
            }
          }
          .open-close-box-btn{
            margin-left: 10px;
          }
          >div{
            display: inline-flex;
            align-items: center;
          }
          .items-count{
            margin-right: 10px;
            svg{
              margin-right: 5px;
              path{
                fill: $inactive;
              }
            }
          }
          .box-name{
            flex: 1;
          }
          svg{
            width: $action_icon_size;
            height: $action_icon_size;
          }
        }
      }
    }
  }
  .stowing-title-block{
    display: flex;
    align-items: flex-end;
    h6{
      margin: 0;
      font-size: $normal_text_size;
      color: $inactive;
      margin-right: 10px;
    }
    .btn{
      padding: 0 8px;
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }
  }
  .closed-box-icon{
    //cursor: pointer;
  }
  .open-box-icon{
    //cursor: pointer;
    path{
      fill: $accent!important;
    }
  }
}

.edit-product-modal-backdrop{
  z-index: 1050;
  +.modal{
    .footer-form-edit-product-modal{
      display: flex;
      justify-content: space-between;
      .btn{
        //margin-left: 10px;
        text-transform: initial;
      }
    }
  }
}

.no-package{
  margin: 0;
  color: orange;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg{
    margin-right: 10px;
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      color: red;
    }
  }
}

.check-text{
  font-size: $normal_text_size;
  margin: 0;
  strong{
    font-family: Roboto-medium;
    font-weight: normal;
  }
}

.wrapper-form{
  font-size: $normal_text_size;
  display: flex;
  margin-top: 15px;
  margin-bottom: 5px;
  .form-group{
    margin-right: 10px;
    flex: 1;
  }
}

.schema-to-fill{
  font-size: $normal_text_size;
  //color: orange;
}

.create-article-form{
  position: relative;
  .col-12{
    padding-right: 5px;
  }
  .footer-form{
    margin-top: 10px;
    .btn[type=submit]{
      color: $accent;
    }
    .btn{
      text-transform: none;
    }
  }
  p{
    margin: 0;
    font-size: $subtitle_size;
  }
  .not-found-article{
    color: orange;

  }
  .are-you-sure{
    color: $primary;
  }

  .preloader-wrap{
    position: absolute;
    width: 100%;
    height: 100%!important;
    left: 0;
    z-index: 10;
    top: 0;
    background-color: rgba(255,255,255,0.6);
  }
}

.edit-article-in-box{
  position: relative;
  .preloader-wrap{
    position: absolute;
    width: 100%;
    height: 100%!important;
    left: 0;
    z-index: 10;
    top: 0;
    background-color: rgba(255,255,255,0.6);
  }
}

.put-article-form{
  position: relative;
  font-size: 16px;
  .preloader-wrap{
    position: absolute;
    width: 100%;
    height: 100%!important;
    left: 0;
    z-index: 10;
    top: 0;
    background-color: rgba(255,255,255,0.6);
  }
  .packages-select{
      div[class*=singleValue]{
        color: orange!important;
      }
  }
  .schema-to-fill,
  .check-text,
  .no-package{
    font-size: 16px;
  }
  .btn{
    font-size: 16px;
    svg{
      width: $action_icon_size;
      height: $action_icon_size;
      margin-right: 5px;
    }
    &.stowe-btn{
      color: $accent;

      svg{
        path{
          fill: $accent;
        }
      }
    }
    &.stowe-and-close-btn{
      svg{
        path{
          fill: $inactive;
        }
      }
    }
    &.cancel-stowe-btn{
      border: 1px solid transparent;
    }
  }
  .footer-form.footer-form-custom.form-group{
    margin-bottom: 10px;
  }
  .wrapper-form{
    font-size: 16px;
    margin: 20px 0;
    margin-bottom: 10px;

    div.form-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap ;
      //justify-content: center;
      .bootstrap-class{
        &.box-select{

          div[class*=singleValue]{
            color: black;
          }
        }
        *{
          font-size: 32px;
        }
        &.disabled{
          div[class*=control]{
            background-color: transparent;
            div[class*=placeholder]{
              color: $inactive;
            }
          }
        }

      }
      .invalid-feedback{
        font-size: $accompanying_text_size;
      }
      div[class*=placeholder]{
        color: $primary;
      }
      input[name=items]{
        //padding: 3px 6px;
        //margin-left: 30px;
        &.form-control.is-invalid{
          background-position: 100%;
        }
        height: calc(2rem + 12px);
        width: 145px;
        display: inline-block;
        +.invalid-feedback{
          font-size: 32px;
        }
      }
      >*{
        width: 100%;
      }
      *{
        font-size: 32px;
      }

    }

  }
}

.create-product-modal-backdrop{
  z-index: 1050;
  +.modal{
    .modal-dialog{
      max-width: 800px;
      .required-select{

        div[class*=control]{
          border: 1px solid red;
        }
      }
      .footer-form-custom{
        display: flex;
        justify-content: flex-end;

        .btn{
          &.cancel-stowe-btn{
            border: 1px solid transparent;
          }
          &.stowe-btn{
            background-color: $accent;
            color: white;
            &:disabled{
              background-color: transparent;
              border: none;
              color: $inactive;
              svg{
                path{
                  fill: $inactive;
                }
              }
            }
            svg{
              margin-right: 0;
              margin-left: 10px;
              path {
                fill: white;
              }
            }
          }
          svg{
            width: 32px;
            height: 32px;
          }
          padding: 8px 14px;
          font-size: 32px;
          margin-right: 10px;
          text-transform: initial;
        }
      }
    }

  }
}

.opened-box-exist-confirm-modal{
  max-width: 750px;
  .opened-box-exist-confirm{
    .center-h{
      font-size: 32px;
    }
  }
  .modal-footer{
    .btn{
      font-size: 32px;
    }
  }
}

.confirm-modal-edit-products{
  max-width: 450px;
  .footer-form{
    .btn:last-of-type{
      justify-self: flex-end;
    }
  }
}


.inbounding-wrapper{
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  .preloader-wrap{
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    height: 100%!important;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
  }
  .header-inbounding{
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    .enter-manually-btn{
      margin-right: 10px;
    }
    .scanned-info{
      flex: 1;
      display: flex;
      align-items: center;
      font-size: $title_size*1.35;
      strong{
        margin: 0 5px;
        //font-family: Roboto-medium;
        font-family: 'Roboto-Regular';
        font-weight: bold;
      }
      .bootstrap-class{
        font-family: Roboto-medium;
        width: 120px;
        margin-left: 10px;
      }
    }
    .enter-manually-wrapper{
      min-width: 150px;
      display: flex;
      justify-content: flex-end;
      input{
        border-radius: 5px;
        width: 100%;
        height: 30px;
        border: none;
        background: #ffffff;
        padding: 0 10px;
        color: #616161;
        font-size: 12px;
      &:focus {
          outline: none;
        }
      }
      .cancel-manually {
        margin-left: 10px;
      }
      .btn-primary {
        background-color: #fff;
        height: 30px;
        &:hover {
          background-color: #616161;
        }
      }
    }
  }
  .product-list__wrapper{
    background-color: $secondary_one;
    padding: 1rem 2rem;
    height: 100%;
    .search-scanned{
      max-width: 300px;
      background-color: $secondary_two;
    }
    .tabs-inbounding{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav-tabs{
        display: inline-flex;
        border: none;
        .nav-link{
          color: $primary;
          font-size: $subtitle_size;
          padding: 0.25rem 0.5rem;
          border: none;
          border-bottom: 2px solid transparent;
          &:hover{
            border: none;
            border-bottom: 2px solid $inactive;
          }
          &.active{
            color: $primary;
            border: none;
            border-bottom: 2px solid $primary;
          }
        }
      }

    }
    .product-list{
      margin-top: 15px;
      max-height: calc(100% - 100px);
      overflow: auto;
      position: relative;
      .chips{
        margin-bottom: 10px;
      }
      .table.table-sm{
        //font-family: ;
        font-family: 'Roboto-light';
        font-size: $normal_text_size;
        border-collapse: collapse;
        th{
          background-color: $secondary_one;
          position: sticky;
          top: 0px;
          color: $inactive;
          //background-color: transparent;
          border-bottom: 2px solid $inactive;
          // box-shadow:  inset 0 -2px 0 $primary;
          border-top: 0;
          font-weight: 400;
        }
        tbody{
          tr{

            &.inactive{
              td{
                color: $inactive;
                svg{
                  cursor: initial;
                  path{
                    fill: $inactive;
                  }
                }
              }
            }
            &.no-data-to-display-row{
              td{
                text-align: center;
                color: $inactive;
              }
              &:hover{
                background-color: transparent;
              }
            }
            &:hover{
              background-color: $secondary_two;
              .action-td{
                svg{
                  visibility: visible;
                }
              }
            }
            td{
              strong{
                font-family: 'Roboto-Regular';
              }
            }
            .action-td{
              min-width: 32px;
              width: 32px;
              svg{
                visibility: hidden;
                cursor: pointer;
                width: 16px;
                height: 16px;
                path{
                  fill: $inactive;
                }
              }
            }
          }

        }

      }
    }
    .pagination{
      display: inline-flex;
      font-family: Roboto-light;
      padding: 5px 0;
      font-size: $normal_text_size;
      .limit-list{
        align-self: center;
        position: relative;
        z-index: 1;
        ul{
          width: 100%;
          position: absolute;
          z-index: 50;
          top: 100%;
          margin: 0;
          padding: 0;
          padding-top: 8px;
          padding-bottom: 8px;
          list-style-type: none;
          background-color: $secondary_one;
          //box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: none;
          border: 1px solid $inactive;
          border-radius: $border-radius;
          li{
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            min-height: 24px;
            line-height: 1;
            font-size: $normal_text_size;
            &:hover{
              background-color: rgba(0, 0, 0, 0.14);
            }
          }
        }
        .page-size-btn{
          box-shadow: none;
          background-color: transparent;
          border: none;
          svg{
            width: $action_icon_size;
            height: $action_icon_size;
            path{
              fill: $primary;
            }
          }
          &:focus{
            background-color: rgba(0, 0, 0, 0.05);
            outline: none;
          }
        }
      }
      .pagination__actions{
        display: flex;
        flex-wrap: nowrap;
        .pagination__info{
          //align-self: flex-start;
          margin: 0 10px;
          display: flex;
          align-items: center;
        }
        .btn-pagination{
          cursor: pointer;
          background-color: transparent;
          border: none;
          &:disabled{
            svg{
              path{
                fill: $inactive;
              }
            }
          }
          &:focus{
            outline: none;
          }
          svg{
            path{
              fill: $primary;
            }
          }
        }
      }
    }
  }

}

.wrapper-workplace-list{
  position: relative;
  align-self: flex-start;
  display: flex;
  margin-right: 10px;
  &.empty-workplace{
    .name-active-workplace{
      border: 1px solid red;
    }
  }
  &.open{
    ul{
      display: block;
    }
  }
  .name-active-workplace{
    cursor: pointer;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: $border-radius;
    font-family: Roboto-medium;
    &:hover{
      border: 1px solid $accent;
    }
    .standart-svg{
      margin-right: 5px;
    }
  }
  ul{
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    width: auto;
    margin: 0;
    padding: 5px 0;
    list-style-type: none;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    background-color: $secondary_one;
    z-index: 10000;
    li{
      padding: 5px 10px;
      cursor: pointer;
      white-space: nowrap;
      &:hover{
        background-color: $secondary_two;
      }
    }
  }
}

.notif-to-select-workplace{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $title_size;
  //font-family: Roboto-medium;
  color: red;
  height: calc(100% - 140px);
  top: 140px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255,255,255,0.5);
}

