@import '../../adminStyle/variable';

.tickets-tabs {
  border-bottom: none !important;
  display: flex;
  align-items: center;
  .title-text {
  }

  &.inventory-tabs {
    .nav-link {
      padding: 0 0.5rem 11px 0.5rem;
      h3 {
        margin-left: 5px;
      }
    }
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 11px;
    position: relative;
    border: none !important;
    height: 20px;
    margin-top: 10px;
    .action-icon {
      margin-right: 5px;
      path {
      }
      &.header-icon {
        path {
        }
      }
    }
    &.active {
      h3:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 4px);
        left: calc(-2px);
        height: 2px;
        background-color: #616161;
      }
    }

    h3 {
      margin-left: 10px;
      color: $primary;
      position: relative;
      margin-top: 3px;
      //text-decoration: underline solid $primary;
    }
  }
}
