.sizes-wrapper {
  display: flex;
  flex-direction: column;
  font-size: $normal_text_size;
  font-family: Roboto-light;
  height: calc(100vh - 90px);
  overflow: hidden;
  .form-control {
    font-size: $normal_text_size;
    height: 28px;
  }
  .bootstrap-class {
    > div {
      min-height: 0;
    }
    div[class*=indicatorContainer] {
      padding: 3px;
    }
  }
  .button-with-icon {
    font-size: $normal_text_size;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path {
        fill: $inactive;
      }
    }
  }
  .icon-set-size-button {
    border: none;
    padding: 0;
    background-color: transparent;
    &:focus {
      outline: none;
    }
    svg {
      width: $action_icon_size;
      height: $action_icon_size;
      path {
        fill: $inactive;
      }
    }
  }
  .header-sizes-wrapper {
    border-bottom: 1px solid $inactive;
    display: flex;
    width: 100%;
  }
  .body-sizes-wrapper {
    display: flex;
    //max-height: calc(100% - 72px);
    max-height: 100%;
    .types-wrapper {
      .create-size-type-button {
        position: absolute;
        left: -11px;
        bottom: -11px;
        border: 1px solid $inactive;
        border-radius: $border-radius;
        padding: 0;
        background-color: $secondary_one;
        &:focus {
          outline: none;
        }
        svg {
          width: $action_icon_size;
          height: $action_icon_size;
          path {
            fill: $inactive;
          }
        }
      }
      .types-row {
        //display: flex;
        &:hover{
          .types-row-cell.remove-row{
            visibility: visible;
          }
        }
        .types-row-cell {
          padding: 10px 15px;
          position: relative;
          flex: 1;
          //width: 5%;
          &:hover {
            .edit-title {
              .remove-size-type-button {
                visibility: visible;
              }
            }
          }
          .form-group {
            margin: 0;
            display: flex;
            align-items: center;
            .form-control {
              width: auto;
              max-width: 70px;
              &.is-invalid {
                background-image: none;
              }
            }
            
          }
          .edit-title {
            color: $accent;
            border-bottom: 1px dashed $accent;
            display: inline-block;
            cursor: pointer;
            position: relative;
            min-width: 15px;
            min-height: 10px;

            .remove-size-type-button {
              right: -30px;
              top: -2px;
              position: absolute;
              border: none;
              padding: 0;
              background-color: transparent;
              visibility: hidden;
              &:focus {
                outline: none;
              }
              svg {
                width: $action_icon_size;
                height: $action_icon_size;
                path {
                  fill: $inactive;
                }
              }
            }
          }
          .actions-editable-type-cell {
            width: 65px;
            margin-left: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            .action-button {
              border: none;
              padding: 0;
              background-color: transparent;
              &:focus {
                outline: none;
              }
              svg {
                width: $action_icon_size;
                height: $action_icon_size;
                path {
                  fill: $inactive;
                }
              }
            }
          }
          &.remove-row {
            flex: inherit;
            width: 40px;
            visibility: hidden;
            .remove-set-row{
              border: none;
              padding: 0;
              background-color: transparent;
              &:focus {
                outline: none;
              }
              svg {
                width: $action_icon_size;
                height: $action_icon_size;
                path {
                  fill: $inactive;
                }
              }
            }
          }
          &.eu-column {
            flex: inherit;
            width: 100px;
            border-left: 1px solid $inactive;
          }
        }
      }
    }
    .sets-wrapper {
      padding: 0;
      padding-bottom: 10px;
      overflow: auto;
      .set-row {
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        border: 1px solid $inactive;
        padding: 15px 15px;
        border-radius: $border-radius;
        &.active-set {
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        }
      }
    }
  }

  .sets-wrapper {
    flex: 2;

    .title-sets-wrapper {
      //border-bottom: 1px solid $inactive;
      //.button-with-icon{
      //  margin-bottom: 10px;
      //  margin-left: 0;
      //}

      //padding: 10px 15px;
      h6 {
        font-size: $subtitle_size;
        display: flex;
        align-items: center;
        margin: 0 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        //justify-content: space-between;
      }
    }
    .set-row {
      display: flex;
      &.header-row{
        display: flex;
        align-items: center;
        margin-top: 17px;
        padding: 5px;
        margin-right: 0;
        margin-left: 0;
        border: none;
        border-bottom: 1px solid $inactive;
        border-radius: 0;
        position: sticky;
        z-index: 2;
        top: 0;
        background-color: #fff;
      }
      &:hover{
        .column-set-row.actions-set{
          visibility: visible;
        }
      }
      .column-set-row {
        flex: 1;

        &:not(.actions-set) {
          padding: 0 5px;
        }
        &.actions-set {
          flex: inherit;
          width: 48px;
          visibility: hidden;
          height: 28px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .types-wrapper {
    flex: 4;
    border-left: 1px solid $inactive;
    padding-top: 10px;
    //overflow: visible;

    //position: relative;
    overflow-x: auto;
    .button-with-icon{
      margin-left: 10px;
    }
    .wrapper-table-types{
      margin-top: 17px;
      //max-height: calc(100vh - 300px);
      .table-types{
        position: relative;
        width: 100%;
        //margin-top: 17px;
        //border-collapse: collapse;
        border-collapse: separate;
        border-spacing: 0;
        thead{
          tr{
            //border-bottom: 1px solid $inactive;
          }
        }
        th{
          width: 100%;
          position: sticky!important;
          visibility: visible!important;
          top: -11px;
          z-index: 10;
          border-bottom: 1px solid $inactive;
          .types-row-cell{
            background-color: white;
          }
          background-color: $secondary_one;
        }

        .types-row-cell{
          &.eu-column{
            min-width: 100px;
            max-width: 100px;

          }
          &.remove-row{
            min-width: 40px;
            max-width: 40px;
          }
        }
      }
    }

    .body-types{
      overflow: auto;
      max-height: calc(100% - 50px);
    }
    //overflow: auto;
    .title-types-wrapper {
      padding: 10px 15px;
      h6 {
        font-size: $subtitle_size;
        font-family: Roboto-medium;
      }
    }
  }

}

.remove-size-type-modal-content{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $subtitle_size;
  svg{
    margin-right: 10px;
    width: $action_icon_size;
    height: $action_icon_size;
    path{
      fill: red;
    }
  }
}
