.wrapper-received-table{
  color: $primary;
  th,td{
    font-size: 11px;
    vertical-align: middle;
    &:first-of-type{
      // border-top: none;
      //border-bottom: none;
    }
    &.status{
      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: red;
        }
      }
    }
  }
  table thead th{
    border-top: none;
    border-color: $inactive;
    color: $inactive;
    font-size: $normal_text_size;
    font-weight: 400;
  }
  .form-control{
    font-size: $accompanying_text_size;
    padding: 4px;
    height: auto;
    //max-width: 50px;
    max-width: 100%;
  }
  .icon-button{
    width: 20px;
    height: 20px;
  }
  .row-quantity{
    .form-control{
      width: 35px;
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  .action-row-button{
    border: none;
    padding: 0;
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: $inactive;
      }
    }
  }
  .edited-bid-row{

    .bootstrap-class{
      >div{
        min-height: auto;
      }
      div[class*=indicatorContainer]{
        padding: 1px;
        svg{
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .received-row{
    .form-group{
      margin-bottom: 0;
      .form-control.is-invalid{
        margin-top: 15px;
      }
    }
    color: $primary;
    .button-with-border {
      background-color: white;
      color: #616161;
      display: inline-flex;
      align-items: center;
      max-width: 150px;
      justify-content: space-between;
      border: 1px solid #B0BEC5;
      border-radius: 5px;
      font-size: 12px;
      padding: 0.15rem 0.5rem;
      outline: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      svg {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        path {
          fill: $inactive
        }
      }
      &:hover {
        background-color: #616161;
        color: #ffffff;
        svg {
          path {
            fill: #ffffff
          }
        }
      }
    }
    .no-data-to-display {
      margin-bottom: 0;
    }
    .action-row-button {
      background-color: transparent;
    }
    .arrival-date-span{
      display: block;
      color: $accent;
      user-select: none;
    }
    .wrapper-calendar-reveived{
      max-width: 140px;
      display: flex!important;
      align-items: center;
      padding: 5px;
      position: relative;
      border-radius: $border-radius;
      border: 1px solid transparent;
      .circle-close{
        cursor: pointer;
        width: 16px;
        height: 16px;
        visibility: hidden;
        path{
          fill: $inactive;
        }
      }
      &:hover{
        border: 1px solid $inactive;
        .circle-close{
          visibility: visible;
        }
      }
      .react-bootstrap-daterangepicker-container{
        display: flex!important;
        align-items: center;
        cursor: pointer;

        user-select: none;

        svg:not(.circle-close){
          width: 26px;
          height: 26px;
          margin-right: 5px;
          border: 1px dashed $inactive;
          padding: 5px;
          border-radius: 50%;
          path{
            fill: $inactive;
          }
        }

        .form-control{
          user-select: none;
          cursor: pointer;
          border: none;
          width: auto;
          background-color: transparent;
          box-shadow: none;
          &:focus{
            outline: none;
          }
        }
      }
    }

  }
}
.action-cell {
  min-width: 40px;
}