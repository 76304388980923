.bids-info-wrapper {
  background-color: $secondary_one;
  padding: 1rem;
  width: 100%;
  font-family: Roboto-light;
  strong {
    //font-family: Roboto-medium;
  }
  td {
    .no-data-to-display {
      text-align: center;
      font-size: 11px;
    }
  }
  .header-bids {
    display: flex;
    justify-content: space-between;
    .form-wrap {
      flex: 2;
      .bootstrap-class {
        > div {
          min-height: auto;
          div[class*='indicatorContainer'] {
            padding: 5px;
          }
        }
      }
      form {
        display: flex;
        flex-wrap: wrap;
        .form-left {
          flex: 2;
          margin-right: 15px;
        }
        .comment-form {
          flex: 1;
          textarea {
            min-height: 32px;
            height: 18px;
            line-height: 1;
          }
        }
        .footer-form {
          width: 100%;
        }
      }
    }
    > div {
      //padding: 5px;
    }
    .left-part {
      flex-basis: 700px;
      padding-right: 10px;
      .responsible-person {
        font-family: Roboto-medium;
        .departament {
          font-size: $normal_text_size;
          color: $inactive;
          margin-left: 5px;
        }
      }
      .supplier-title-link {
        font-size: $title_size;
        text-decoration: none;
        color: $accent;
      }
      .offer-wrapper {
        margin: 0 0 0.5rem 0;
        p {
          //display: inline-block;
          margin: 0;
        }
        .responsible-buyer-label {
          margin-right: 5px;
          color: $accent;
        }

        .offers-left-center {
          display: flex;
          justify-content: space-between;

          .central-part {
            max-width: 400px;
            flex: 2;
            display: flex;
            justify-content: flex-end;
            p {
              margin-left: 10px;
              flex-basis: calc(100% - 35px);
            }
            svg {
              width: $action_icon_size;
              height: $action_icon_size;
              path {
                fill: $accent;
              }
            }
          }

          .offers-statuses {
            display: flex;

            .offers-titles {
              flex: 0;
              margin-right: 10px;
              p {
                white-space: nowrap;
                padding: 4px 0;
              }
              p.resp {
                padding: 0;
                margin-top: 4px;
              }
            }
            .offers-values {
              p {
                padding: 4px 0;
              }
            }
          }
        }
      }
      .order-number.placement-date.finance-status {
        display: flex;
        //justify-content: space-between;
        margin-bottom: 0.5rem;
        > span {
          margin-left: 10px;
        }
        p {
          margin: 0;
        }
      }
    }

    .right-part {
      padding-left: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      .with-icon {
        display: inline-flex;
        font-size: $normal_text_size;
        &.complete {
          color: green;
          &:hover {
            color: $secondary_one;
            svg {
              path {
                fill: $secondary_one;
              }
            }
          }
          svg {
            path {
              fill: green;
            }
          }
        }
        &:last-of-type {
          margin-left: 5px;
        }
        &:hover {
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          margin-right: 5px;
          //width: $action_icon_size;
          //height: $action_icon_size;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
    }
  }

  // TABLE
  .wrapper-bids-table {
    .article-title {
      color: $inactive;
      margin-top: 10px;
    }
    .wrap-actions {
      width: 32px;
    }
    .table-responsive {
      overflow-y: visible;
      overflow-x: auto;
      .tool-bar {
        width: 20px;
      }
    }
    table {
      margin-bottom: 0;
      font-size: $accompanying_text_size;
      th {
        border-top: none;
        color: $inactive;
        border-bottom: 2px solid $inactive;
        //font-size: $subtitle_size;
        font-size: $normal_text_size;
        font-weight: 400;
        &:first-of-type {
          min-width: 95px;
        }
        &:nth-of-type(2) {
          min-width: 75px;
        }
        &:nth-of-type(3) {
          width: 75px;
        }
        &:nth-of-type(4) {
          min-width: 90px;
        }
        &:nth-of-type(5) {
          min-width: 90px;
        }
        &:nth-of-type(6) {
          min-width: 90px;
        }
        &:nth-of-type(7) {
          min-width: 90px;
        }
        &:nth-of-type(8) {
          min-width: 65px;
        }
        &:nth-of-type(9) {
          min-width: 115px;
        }
        &:nth-of-type(10) {
          min-width: 35px;
        }
        &:nth-of-type(11) {
          min-width: 35px;
        }
        &:nth-of-type(12) {
          min-width: 35px;
        }
        &:nth-of-type(13) {
          min-width: 35px;
        }
        &:nth-of-type(14) {
          min-width: 60px;
        }
        &:nth-of-type(15) {
          min-width: 60px;
        }
        &:nth-of-type(16) {
          min-width: 90px;
        }
        &:nth-of-type(17) {
          min-width: 70px;
        }
        &:nth-of-type(18) {
          min-width: 80px;
        }
        &:nth-of-type(19) {
          min-width: 100px;
        }
        &:nth-of-type(20) {
          min-width: 70px;
        }
        &:nth-of-type(21) {
          min-width: 60px;
        }
      }
      tr {
        height: 34px;
        td {
          vertical-align: middle;
          font-size: 11px;
          color: rgb(97, 97, 97);
        }
        td {
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            .bootstrap-class {
              min-width: 70px;
            }
          }
          &:nth-of-type(8) {
            .form-control {
              width: 100%;
            }
            .bootstrap-class {
              width: 60px;
            }
          }
        }
      }
      .icon-button {
        width: 20px;
        height: 20px;
      }
      .form-control {
        font-size: $accompanying_text_size;
        padding: 4px;
        height: auto;
        //max-width: 50px;
        max-width: 100%;
      }
      .row-quantity {
        .form-control {
          width: 35px;
          margin-right: 5px;
        }
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .divider-row {
      td {
        //padding-bottom: 1rem;
        padding-bottom: 0.3rem;
      }
      .button-with-border {
        font-size: $normal_text_size;
        text-transform: none;
        display: inline-flex;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .edited-bid-row {
      .bid-select-wrap {
        padding: 2px 0;
        ::placeholder {
          color: #B0BEC5;
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: #B0BEC5;
        }

        ::-ms-input-placeholder {
          color: #B0BEC5;
        }
        &.article-wrap {
          width: 120px;
        }
        &.product-name {
          width: 130px;
        }
      }
      input[name='pricePerUnit'] {
        min-width: 45px;
      }
      input[name='quantity'] {
        min-width: 35px;
        margin-right: 5px;
      }
      input[name='packages'] {
        min-width: 35px;
      }
      input[name='grossWeight'] {
        min-width: 40px;
      }
      input[name='netWeight'] {
        min-width: 40px;
      }
      input[name='totalPrice'] {
        min-width: 60px;
      }
      input[disabled] {
        border: 0;
        color: #616161;
        -webkit-text-fill-color: #616161;
      }
      .bootstrap-class {
        > div {
          min-height: auto;
        }
        div[class*='indicatorContainer'] {
          padding: 1px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .received-row {
      .arrival-date-span {
        display: block;
        color: $accent;
        user-select: none;
      }
      .wrapper-calendar-reveived {
        display: flex !important;
        align-items: center;
        padding: 5px;
        border-radius: $border-radius;
        border: 1px solid transparent;
        .circle-close {
          cursor: pointer;
          width: 16px;
          height: 16px;
          visibility: hidden;
          path {
            fill: $inactive;
          }
        }
        &:hover {
          border: 1px solid $inactive;
          .circle-close {
            visibility: visible;
          }
        }
        .react-bootstrap-daterangepicker-container {
          display: flex !important;
          align-items: center;
          cursor: pointer;

          user-select: none;

          svg:not(.circle-close) {
            width: 26px;
            height: 26px;
            margin-right: 5px;
            border: 1px dashed $inactive;
            padding: 5px;
            border-radius: 50%;
            path {
              fill: $inactive;
            }
          }

          .form-control {
            user-select: none;
            cursor: pointer;
            border: none;
            width: auto;
            background-color: transparent;
            box-shadow: none;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .total-cell-total-row {
      // display: flex;
      align-items: center;
      .form-control {
        margin-right: 5px;
      }
    }
    .total-row-with-products,
    .received-row,
    .result-row,
    .edited-bid-row {
      .nowrap-container {
        position: relative;
        .error-select-bootstrap-style {
          position: absolute;
          bottom: -13px;
          left: 0;
        }
      }
      .action-row-button {
        background-color: transparent;
        border: none;
        padding: 0;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
      table {
        margin: 0;
      }
      td[colspan='6'] {
        padding-left: 0;
        border-top: none;
      }
      td {
        vertical-align: middle;
      }
      table {
        width: auto;
      }
    }
    .result-row {
      .status {
        svg {
          width: $action_icon_size;
          height: $action_icon_size;
          path {
            fill: red;
          }
        }
      }
    }
    .total-row-with-products,
    .result-row {
      td {
        border-top: 2px solid $inactive;
      }
    }
    .product-button,
    .button-with-border {
      &.product-button {
        //width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        text-transform: capitalize;
        font-size: $accompanying_text_size;
        + svg {
          width: $action_icon_size;
          height: $action_icon_size;
          margin-bottom: 12px;
          margin-left: 5px;
          path {
            fill: $inactive;
          }
        }
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
        }
      }
      &.left-icon {
        svg {
          margin-right: 5px;
        }
      }
      background-color: white;
      color: #616161;
      display: inline-flex;
      align-items: center;
      max-width: 150px;
      justify-content: space-between;
      border: 1px solid $inactive;
      border-radius: $border-radius;
      font-size: $normal_text_size;
      padding: 0.15rem 0.5rem;
      outline: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        background-color: $primary;
        color: $secondary_one;
        svg {
          path {
            fill: $secondary_one;
          }
        }
      }
      svg {
        width: $action_icon_size;
        height: $action_icon_size;
        path {
          fill: $inactive;
        }
      }
    }
    table {
      margin-top: 15px;
      font-size: $accompanying_text_size;
      th {
        border-top: none;
      }
    }

    .total-fix {
      width: 100px;
      max-width: 100px;
    }
  }
  .total-received-table-wrapper {
    display: flex;
    table {
      font-size: $accompanying_text_size;
      .total-row {
        td {
          border-top: 2px solid $primary;
        }
      }
      .received-row {
        td {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}

.quantity-wrap {
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
    & + input {
      width: calc(100% - 100px);
    }
  }
  .bootstrap-class {
    width: 100px;
    > div {
      min-height: 32px;
    }
    div[class*='indicatorContainer'] {
      padding: 4px;
    }
  }
}

.confirm-main-modal {
  &.modal-body {
    padding: 0;
  }
  h6 {
    padding: 10px;
  }
  .actions {
    border-top: 1px solid $inactive;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    button {
      margin-left: 10px;
    }
  }
}

.custom-bids-th {
  text-align: right;
}

.title {
  font-size: $subtitle_size;
  color: $inactive;
  margin-bottom: 10px !important;
}

.resp__wr {
  display: flex;
  align-items: center;
  margin-left: -7px;
  & > p {
    margin-right: 2px;
  }

  .styled_span {
    font-family: 'Roboto-light';
    font-size: 12px;
    margin-right: 3px;
    font-weight: 400;
    color: $primary;
  }
}

.caution-modal-content {
  > p {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 5px;
      color: red;
      height: 18px !important;
    }
    .caution {
      margin-top: 3px;
    }
  }
}

.caution {
  color: red;
}
