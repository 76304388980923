.table-cell-th{
  display: inline-flex;
  justify-content: space-between;
  .filter-buttons{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }
}
.filter-wrap{
  //margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  svg{
    width: 20px;
    height: 20px;
  }
}