@import "variable";

.no-wrap-text{
  white-space: nowrap;
}

.full-container-form-header{
  font-size: 12px;
  //border-bottom: 1px solid $inactive;
  //color: #B0BEC5;
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding-bottom: 5px;

  svg{
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path{
      fill: $inactive;
    }
  }
}

.btn__with-icon{
  svg{
    width: 16px;
    height: 16px;
    path{
      fill: $inactive
    }
  }
}

.error-label{
  font-size: 10px !important;
  color: #dc3545;
}