.rowForm{
  height: 100vh;
  align-items: center;
  justify-content: center;
  img{
    height: 22%;
    width: 22%;
    margin: 0 auto;
    padding: 2.5rem 0 0.5rem;
  }
  .card-body {
    padding: 16px;
    h4{
      margin-top: 1.25rem;
    }
  }
  .mb-5 {
    margin-bottom: 0rem !important;
  }
}
div.without-padding{
  padding: 0;
  &.form-group {
    margin-bottom: 10px;
  }
}

#login-form{
  color: $primary;
}
.forgot-password{
  color: $accent;
  font-size: $normal_text_size;
}