.wrapper-schema-uploading-excel{
  height: 100%;
  overflow: auto;
  .title-schema{
    display: flex;
    margin: 15px 0 30px 0;
    svg{
      width: 20px;
      height: 20px;
      margin-right: 15px;
      margin-top: 2px;
      path{
        fill: red;
      }
    }
    .center-h{
      text-align: left;
      color: $primary;
      font-size: $title_size;
      //color: $accent;
      strong{
        font-family: Roboto-medium;
      }

    }
  }

  .row-columns{
    display: flex;
  }
  .form-group{
    display: flex;
    margin-right: 10px;
    //justify-content: center;
    .form-label{
      width: 60px;
      margin-right: 10px;
      align-self: center;
    }
    .form-control:not(*[type=file]){
      max-width: 60px;
    }
    .input-group{
      max-width: 480px;
    }
    &.footer-form{
      margin-top: 30px;
      justify-content: flex-end;
      max-width: 550px;
    }
  }
}