@import 'tableReceived/style';
.inbound-wrapper {
  background-color: $secondary_one;
  padding: 1rem;
  //.jus{
  //  width: 24px;
  //  height: 24px;
  //  display: inline-flex;
  //  align-items: center;
  //  justify-content: center;
  //  border-radius: 50%;
  //  padding: 3px;
  //  background-color: #fff59d;
  //  color: #e1bee7;
  //}
  .deadline-value{
    font-size: $accompanying_text_size;
    color: $inactive;
    svg{
      margin-right: 5px;
    }
    margin-right: 10px;
  }

  .deadline-value {
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: $inactive;
      }
    }
  }
  
  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  td{
    .no-data-to-display{
      margin-bottom: 15px;
      text-align: center;
    }
    .inbound-cell-value {
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .no-data-to-display{
    margin-bottom: 15px;
  }
  .inbound-cell-value{
    height: 20px;
    line-height: 20px;
    span{
      color: $inactive;
      font-size: 11px;
    }
  }
  .inbound-show-cell{
    padding: 0;
    font-size: $normal_text_size;
    font-weight: 400;
    >div{
      cursor: pointer;
      padding: .3rem;
      border: 1px solid transparent;
      border-radius: 5px;
      &:hover{
        border: 1px solid $accent;
      }
      &.disabled-cell {
        &:hover {
          border: 1px solid transparent;
          cursor: default;
        }
      }
    }

  }
  .inbound-total-location{
    display: inline-block;
    margin-top: 1px;
  }
  .lock-dividing {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    path {
      fill: $inactive;
    }
  }
  .title-inbound {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .name-block {
      p {
        margin: 0;
      }
      .title {
        font-size: $subtitle_size;
        color: $inactive;
      }
    }
    .actions-block.disabled {
      .custom-select-wrapper{
        //width: 170px;
        div[class$="-placeholder"] {
          color: #B0BEC5;
        }
      }
    }
    .actions-block {
      display: flex;
      //align-items: flex-start;
      align-items: center;
      .custom-select-wrapper{
        //width: 170px;
        div[class$="-placeholder"] {
          color: #ff9800;
          white-space: nowrap;
        }
        input {
          color: transparent !important;
        }
        .bootstrap-class > div[class*=control] div[class*=singleValue]{
          white-space: nowrap;
        }
        .bootstrap-class > div[class*=control]{
          min-height: 28px;
          max-height: 28px;
        }
        .empty{
          width: 170px;
        }
        .deep{
          width: 65px;
        }
        .custom{
          width: 78px;
        }
        .totals{
          width: 67px;
        }
      }
      .quality-check{
        margin-left: 5px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        label{
          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
      .with-icon {
        white-space: nowrap;
        &.inbound-btn{
          cursor: pointer;
          &[disabled]{
            color: $inactive;
            border: 1px solid transparent;
            &:hover,
            &:focus,
            &:active{
              cursor: initial;
              background-color: transparent!important;
              color: $inactive!important;
              svg{
                path{
                  fill: $inactive;
                }
              }
            }
            svg{
              path{
                fill: $inactive;
              }
            }
          }
          &:disabled{
            color: $inactive;
            svg{
              path{
                fill: $inactive;
              }
            }
          }
          svg{
            path{
              fill: $accent;
            }
          }
        }
        font-size: $normal_text_size;
        &.complete {
          color: green;
          &:hover {
            color: $secondary_one;
            svg {
              path {
                fill: $secondary_one;
              }
            }
          }
          svg {
            path {
              fill: green;
            }
          }
        }
          margin-left: 15px;
        &:hover {
          svg {
            path {
              fill: $secondary_one;
            }
          }
        }
        svg {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          path {
            fill: $inactive;
          }
        }
      }
    }
  }
  .product-button {
    //width: 100%;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    text-transform: capitalize;
    font-size: $accompanying_text_size;
    + svg {
      width: 16px;
      height: 16px;
      align-self: center;
      //margin-bottom: 12px;
      margin-left: 5px;
      path {
        fill: $inactive;
      }
    }
    svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
    background-color: white;
    color: #616161;
    max-width: 150px;
    justify-content: space-between;
    border: 1px solid $inactive;
    border-radius: $border-radius;
    outline: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &:hover {
      background-color: $primary;
      color: $secondary_one;
      svg {
        path {
          fill: $secondary_one;
        }
      }
    }
    svg {
      path {
        fill: $inactive;
      }
    }
  }
  .inbound-table-wrapper {
    padding: 1px;
    table {
      font-size: $accompanying_text_size;
      th {
        border-top: none;
        color: $inactive;
        border-bottom: 2px solid $inactive;
        //font-size: $subtitle_size;
        //text-align: center;
        font-size: $normal_text_size;
        font-weight: 400;
        &.inbound-show-cell{
          color: $accent;
          &:hover{
            //background-color: $secondary_two;
          }
        }
      }
      td {
        //text-align: center;
        color: $primary;
        font-size: 11px;
      }
    }
    &.table-for-custom{
      table{
        th:not(:last-child), td:not(:last-child){
          width: 41px;
        }
        //th{
        //  border-bottom: none;
        //}
        //td{
        //  border-top: none;
        //}
        .inbound-cell-value{
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.margin-left-center {
  margin: auto;
  padding-left: 10px;
  background-color: transparent;
}
