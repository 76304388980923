.purchase-order-toolbar {
  color: $primary;
  display: flex;
  .part-toolbar{
    display: flex;
    align-items: center;
    align-self: center;
    flex: 1;
    .with-icon{
      svg{
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path{
          fill: $inactive;
        }
      }
    }
  }
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      div[class*=paginationToolbar]{
        min-height: 0;
        height: auto;
      }
      button{
        padding: 4px;
      }
    }
  }
  &.opened-purchase-toolbar{
    //flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    .total-info-toolbar-po{
      align-self: flex-start;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 3px;
      strong {
        span {
          font-size: 14px;
        }
      }
      .total-dropdown__wrapper{
        margin-left: 0;
      }
      >*{
        margin-bottom: 10px;
      }
    }
    .part-toolbar{
      margin-bottom: 10px;
      align-self: flex-start;
    }
    .pagination-toolbar{
      margin-top: 5px;
      width: 100%;
      .MuiSelect-select{
        padding-left: 0;
      }
    }
    .filters-list-wrapper{
      >.with-icon{
        max-width: 160px;

        span{
          max-width: calc(100% - 24px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .wrapper-column-list{
    display: inline-flex;
    position: relative;
    margin-left: 10px;
    &.open{
      .column-list{
        display: block;
      }
    }
    .column-button{
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      &:focus{
        outline: none;
      }
      svg{
        width: $action_icon_size;
        height: $action_icon_size;
        path{
          fill: $inactive;
        }
      }
    }
    .column-list{
      display: none;
      list-style-type: none;
      padding: 0;
      margin: 0;
      background-color: $secondary_one;
      border-radius: $border-radius;
      border: 1px solid $inactive;
      position: absolute;
      left: 0;
      z-index: 1000;
      top: 100%;
      width: 155px;
      max-height: 350px;
      overflow-y: auto;
      li{
        cursor: pointer;
        padding: 5px 10px;
        transition: all .15s;
        &:hover{
          background-color: #f2f2f2;
        }
        input[type=checkbox]{
          margin-right: 10px;
        }
      }
    }
  }
  .total-info-toolbar-po {
    align-self: center;
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > strong {
      padding-left: 28px;
    }
    strong{
      white-space: nowrap;

      span{
        font-family: Roboto-Regular;
        color: rgba(0, 0, 0, 0.847);
        font-size: 14px;
      }
    }
    >strong{
      span{
        color: $inactive;
        margin-right: 5px;
      }
    }
    .separator-avg-total{
      margin: 0 15px;
    }
    .total-dropdown__wrapper{
      display: flex;
      position: relative;
      margin-left: 10px;
      >button{
        display: inline-flex;
        align-items: center;
        color: #616161;
        border: 1px solid transparent;
        background-color: transparent;
        box-shadow: none;
        border-radius: $border-radius;
        padding: 5px 10px 5px 5px;
        font-weight: 700;
        span{
          margin-right: 5px;
          color: $inactive;
          &.sigma{
            font-size: 14px;
          }
          &.value{
            color: $primary;
            white-space: nowrap;
          }
        }
        svg{
          margin-right: 5px;
          width: 16px;
          height: 16px;
          &.icon-value{
            margin-right: 5px;
          }
          path{
            fill: $inactive;
          }
        }
        &:hover{
          border-color: $border_hover;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
        strong{
          display: inline-flex;
          align-items: center;
          font-family: Roboto-Regular;
          b{
            display: inline-flex;
            align-items: center;
          }

        }
        &:focus{
          outline: none;
        }
      }
      ul{
        min-width: 100%;
        position: absolute;
        z-index: 50;
        top: calc(100% + 8px);
        left: 2px;
        margin: 0;
        padding: 4px 0;
        list-style-type: none;
        border-radius: 4px;
        background-color: $secondary_one;
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
        li{
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 8px 12px;
          line-height: 1;
          font-size: $normal_text_size;
          &.active {
            background-color: #2684FF;
            color: $secondary_one;
            .value {
              color: $secondary_one;
            }
            svg {
              path{
                fill: $secondary_one;
              }
            }
            &:hover {
              background-color: #2684FF;
            }
            strong {
              span {
                color: $secondary_one
              }
            }
          }
          span{
            margin-right: 0px;
            color: $inactive;
            &.sigma{
              font-size: 14px;
            }
            //&.with-left-margin{
            //  margin-left: 5px;
            //}
            &.value{
              color: $primary;
              white-space: nowrap;
            }
          }
          svg{
            width: 16px;
            height: 16px;
            &.icon-value{
              margin-right: 5px;
            }
            path{
              fill: $inactive;
            }
          }
          strong{
            display: inline-flex;
            align-items: center;
            b{
              display: inline-flex;
              align-items: center;
            }
            span{
              margin-right: 5px;
            }
            //margin-right: 5px;
          }
          &:hover{
            background-color: rgb(222,235,255);

          }
        }
      }
    }
  }
  .filters-list-wrapper {
    position: relative;
    margin-left: 10px;
    color: $primary;
    .filter-button {
      background-color: transparent;
      box-shadow: none;
      border: 1px solid $inactive;
      border-radius: $border-radius;
      transition: all .15s;
      padding: 0.25rem 0.5rem;
      display: inline-flex;
      color: $primary;
      svg{
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path{
          fill: $inactive;
        }
      }
      &:hover {
        background-color: $primary;
        color: $secondary_one;
      }
      &:focus {
        outline: none;
      }
    }
    .filters-list {
      overflow: hidden;
      list-style-type: none;
      background-color: $secondary_one;
      min-width: 100%;
      width: 200px;
      visibility: hidden;
      position: absolute;
      left: -97px;
      top: 25px;
      padding: 0;
      margin: 0;
      border-radius: $border-radius;
      border: 1px solid $inactive;
      z-index: 100;
      &.open {
        visibility: visible;
      }
      .deletable-li{
        border-top: 1px solid $inactive;
      }
      li {
        width: 100%;
        display: inline-flex;
        padding: 0.5rem 0.5rem 0.5rem 24px;
        white-space: pre-wrap;
        cursor: pointer;
        justify-content: space-between;
        position: relative;
        .actions-filter{
          float: right;
          width: 36px;
          .filter-button-action{
            padding: 0;
            margin: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;
            &:focus{
              outline: none;
            }
          }
        }
        svg{
          width: 16px;
          height: 16px;
          &.selected-filter-icon{
            position: absolute;
            top: 8px;
            left: 5px;
            path{
              fill: $accent;
            }
          }
          path{
            fill: $inactive;
          }
        }

        &:hover {
          background-color: $primary;
          color: $secondary_one;
        }

        &.add-new-filter{
          padding-left: 4px;
          justify-content: flex-start;
          border-top: 1px solid $inactive;
          &:hover{
            svg{
              path{
                fill: $secondary_one;
              }
            }
          }
          svg{
            margin-right: 5px;
            width: 16px;
            height: 16px;
            path{
              fill: $primary;
            }
          }
        }
      }
    }
  }
}

.po-filter-modal {
  max-width: 600px;
  .smart-filter-po-form {
    .form-group {
      display: flex;
      flex-direction: column;
    }
    .form-label {
      margin-bottom: 8px;
    }
    .footer-form-custom {
      margin-top: 16px;
    }
    .footer-form {
      flex-direction: row;
    }
    .form-group-error {
      display: block;
    }
    .list-conditions {
      background-color: #f2f2f2;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .condition-row {
        display: flex;
        .field-condition-row {
          flex: 1;
          margin-right: 10px;
        }
        .criteria-condition-row {
          flex: 1;
          margin-right: 10px;
        }
        .value-condition-row {
          flex: 1;
          margin-right: 10px;
          .form-group{
            display: flex;
            flex-direction: column;
          }
          // .form-control{
          //   height: calc(1.5em + 1rem + 2px);
          // }
          .wrapper-calendar-asana {
            //width: 100%;
            display: inline-flex;
            max-width: 190px;
            height: 30px;
            .react-bootstrap-daterangepicker-container{
              display: flex!important;
              svg{
                width: 20px;
                height: 20px;
                padding: 3px;
              }
              .form-control{
                height: 1.5rem;

              }
            }
          }
        }
        .remove-condition-row {
          align-self: flex-end;
          width: 20px;
          height: 20px;
          margin-bottom: 14px;
          button {
            padding: 0;
            border: none;
            background-color: transparent;
            box-shadow: none;
            margin: 0;
            display: inline-flex;
            align-items: center;
            &:focus {
              outline: none;
            }
          }
          svg {
            width: $action_icon_size;
            height: $action_icon_size;
            path {
              fill: $inactive;
            }
          }
        }
      }
      .add-condition-button {
        background-color: transparent;
        box-shadow: none;
        border: none;
        padding: 0;
        margin: 0;
        color: $inactive;
        width: 100px;
        display: inline-flex;
        align-items: center;
        transition: all .15s;
        &:hover {
          color: $accent;
          svg {
            path {
              fill: $accent;
            }
          }
        }
        &:focus {
          outline: none;
        }
        svg {
          transition: all .15s;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          path {
            fill: $inactive;
          }
        }
      }
    }
  }
}