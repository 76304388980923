.smart-filter-products-modal{
  max-width: 50%;
  font-size: $normal_text_size;
  font-family: Roboto-light;
  .smart-filter-wrapper{
    .conditions-wrapper{
      .condition-row{
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .condition-row-cell{
          margin-right: 10px;
          width: calc(100%/4);
          display: flex;
          align-items: center;
          align-self: flex-start;
          .form-group{
            margin-bottom: 0;
          }
          >*{
            width: 100%;
          }
          &.actions-cell{
            align-self: center;
          }
          .remove-condition-row-button{
            background-color: transparent;
            border: none;
            &:focus{
              outline: none;
            }
            svg{
              width: $action_icon_size;
              height: $action_icon_size;
              path{
                fill: $inactive;
              }
            }
          }
          .form-control{
            font-size: $normal_text_size;
            height: 28px;
          }
          .bootstrap-class{
            >div{
              min-height: 0;
            }
            div[class*=indicatorContainer]{
              padding: 3px;
            }
          }
        }
      }
    }
    .actions-smart-filter{
      margin-top: 10px;
      text-align: right;
      button:first-of-type{
        margin-right: 10px;
      }
    }
  }
}