.variables-block{
  display: flex;
  //justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  button{
    margin: 10px;
  }
}

.card-body{
  .bootstrap-class{
    div[class*=menu]{
      z-index: 100;
    }
  }
}

.dynamic-fields{
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbar-notification{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .actions-notification-toolbar{
    .add-button-notification{
      margin-right: 10px;
      svg{
        width: 16px;
        height: 16px;
        path{
          fill: $inactive;
        }
      }
    }
  }
  .pagination-toolbar{
    td{
      border-bottom: none;
      padding: 0!important;
      .MuiTablePagination-toolbar{
        min-height: 0;
        height: auto;
      }
      button{
        &:focus{
          outline: none;
        }
        padding: 4px;
      }
    }
  }
}

.sun-editor .se-btn-primary{
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  display: inline-flex;
  align-items: center;
  color: #616161;
  text-transform: capitalize;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid #B0BEC5;
  background-color: transparent;
  &:hover{
    background-color: $secondary_two;
    border: 1px solid $inactive;
  }
}
.sun-editor {
  .se-tooltip {
    .se-tooltip-inner {
      .se-tooltip-text {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  .se-wrapper{
    .se-wrapper-inner.se-wrapper-wysiwyg{
      padding: 45px 15px 15px 15px;
    }
  }

  //.se-controller:hover{
  //  z-index: 5;
  //}
}